import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebaseConfig';
import { collection, query, where, getDocs, updateDoc, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../../styles/AdminBannedUser.css';

const AdminBannedUser = () => {
  const [user, loading] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [bannedUsers, setBannedUsers] = useState([]);
  const [newBannedUserId, setNewBannedUserId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.admin) {
          setIsAdmin(true);
          fetchBannedUsers();
        } else {
          setIsAdmin(false);
          navigate('/'); // 管理者でない場合はリダイレクト
        }
      }
    };

    if (!loading) {
      checkAdmin();
    }
  }, [user, loading, navigate]);

  const fetchBannedUsers = async () => {
    setLoadingData(true);
    const q = query(collection(db, 'banned_users'));
    const querySnapshot = await getDocs(q);
    const usersData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    setBannedUsers(usersData);
    setLoadingData(false);
  };

  const handleBanUser = async () => {
    if (newBannedUserId.trim() === '') return;

    try {
      await updateDoc(doc(db, 'users', newBannedUserId), { banned: true });
      await setDoc(doc(db, 'banned_users', newBannedUserId), { uid: newBannedUserId });
      setNewBannedUserId('');
      fetchBannedUsers(); // 更新後に再読み込み
    } catch (error) {
      console.error('Error banning user:', error);
    }
  };

  const handleUnbanUser = async (userId) => {
    try {
      await updateDoc(doc(db, 'users', userId), { banned: false });
      await deleteDoc(doc(db, 'banned_users', userId));
      fetchBannedUsers(); // 更新後に再読み込み
    } catch (error) {
      console.error('Error unbanning user:', error);
    }
  };

  if (loading || loadingData) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <div>Access denied. You do not have permission to view this page.</div>;
  }

  return (
    <div className="admin-banned-user-container">
      <h2>Banned Users</h2>
      <div className="ban-user-form">
        <input
          type="text"
          value={newBannedUserId}
          onChange={(e) => setNewBannedUserId(e.target.value)}
          placeholder="Enter user ID to ban"
        />
        <button onClick={handleBanUser}>Ban User</button>
      </div>
      {bannedUsers.length === 0 ? (
        <div>No banned users.</div>
      ) : (
        <ul>
          {bannedUsers.map((user) => (
            <li key={user.id} className="banned-user-item">
              <p>User ID: {user.uid}</p>
              <button onClick={() => handleUnbanUser(user.id)}>Unban User</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AdminBannedUser;
