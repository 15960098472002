import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth, storage } from '../../firebaseConfig';
import { collection, query, where, getDocs, updateDoc, doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, deleteObject } from 'firebase/storage';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../../styles/AdminAgeVerification.css';

const AdminDashboard = () => {
  const [user, loading] = useAuthState(auth);
  const [ageVerificationDocs, setAgeVerificationDocs] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [rejectReason, setRejectReason] = useState('');  // 拒否理由を選択
  const [customMessage, setCustomMessage] = useState(''); // 任意のメッセージ
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.admin) {
          setIsAdmin(true);
          fetchAgeVerificationDocs();
        } else {
          setIsAdmin(false);
          navigate('/'); // 管理者でない場合はリダイレクト
        }
      }
    };

    if (!loading) {
      checkAdmin();
    }
  }, [user, loading, navigate]);

  const fetchAgeVerificationDocs = async () => {
    setLoadingData(true);
    const q = query(collection(db, 'age_verification'), where('status', '==', 'checking'));
    const querySnapshot = await getDocs(q);
    const docsData = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        const fileRef = ref(storage, `age_verification/${data.uid}/${data.fileName}`);
        const url = await getDownloadURL(fileRef);
        return { id: docSnapshot.id, ...data, url };
      })
    );
    setAgeVerificationDocs(docsData);
    setLoadingData(false);
  };

  const handleApprove = async (docId, uid, fileName) => {
    try {
      const docRef = doc(db, 'age_verification', docId);
      const fileRef = ref(storage, `age_verification/${uid}/${fileName}`);

      // 元のage_verificationコレクションのstatusをcheckedに更新
      await updateDoc(docRef, { status: 'checked' });

      // age_verification_checkedコレクションにドキュメントを追加
      await setDoc(doc(db, 'age_verification_checked', docId), {
        uid: uid,
        createdAt: new Date()
      });

      // 古いファイルを削除
      await deleteObject(fileRef);

      fetchAgeVerificationDocs(); // 更新後に再読み込み
    } catch (error) {
      console.error('Error approving document:', error);
    }
  };

  const handleReject = async (docId) => {
    if (!rejectReason) {
      alert('拒否理由を選択してください');
      return;
    }
    
    try {
      const docRef = doc(db, 'age_verification', docId);
      
      await updateDoc(docRef, { 
        status: 'rejected',
        rejectReason: rejectReason, // 拒否理由を保存
        customMessage: customMessage // 任意のメッセージを保存
      });
      
      setRejectReason(''); // 拒否理由をリセット
      setCustomMessage(''); // メッセージをリセット
      fetchAgeVerificationDocs(); // 更新後に再読み込み
    } catch (error) {
      console.error('Error rejecting document:', error);
    }
  };

  if (loading || loadingData) {
    return <div className="loading">Loading...</div>;
  }

  if (!isAdmin) {
    return <div className="access-denied">Access denied. You do not have permission to view this page.</div>;
  }

  return (
    <div className="admin-dashboard-container">
      <h2 className="dashboard-title">Age Verification Requests</h2>
      {ageVerificationDocs.length === 0 ? (
        <div className="no-requests">No pending age verification requests.</div>
      ) : (
        <div className="verification-list">
          {ageVerificationDocs.map((doc) => (
            <div key={doc.id} className="verification-item">
              <div className="verification-info">
                <p><strong>User ID:</strong> {doc.uid}</p>
                <p><strong>File Name:</strong> {doc.fileName}</p>
                <p><strong>Uploaded At:</strong> {new Date(doc.timestamp.toMillis()).toLocaleString()}</p>
              </div>
              <img src={doc.url} alt="Verification Document" className="verification-image" />
              <div className="verification-actions">
                <button className="approve-button" onClick={() => handleApprove(doc.id, doc.uid, doc.fileName)}>Approve</button>
                <div className="reject-section">
                  <select
                    className="reject-reason-select"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                  >
                    <option value="">拒否理由を選択</option>
                    <option value="不鮮明な画像">不鮮明な画像</option>
                    <option value="不適切な証明書">不適切な証明書</option>
                    <option value="その他">その他</option>
                  </select>
                  <textarea
                    className="custom-message-textarea"
                    value={customMessage}
                    onChange={(e) => setCustomMessage(e.target.value)}
                    placeholder="任意のメッセージを入力"
                  />
                  <button className="reject-button" onClick={() => handleReject(doc.id)}>Reject</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
