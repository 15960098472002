import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { auth } from '../firebaseConfig';  // Firebaseのauthをインポート

// Stripeの公開可能キーを使用してStripeのインスタンスをロード
const stripePromise = loadStripe('pk_live_51J2BcKAq7sm3T386aoWY4c2mRoxcLn0ySoCmLkstoNl0BTopyGKfrsywBFS1XRsPv6dte2tBqZX8ZWPqr9oX8bH200PlX1Mdmj');

const CheckoutButton = () => {
  const handleClick = async (event) => {
    const stripe = await stripePromise;

    const user = auth.currentUser;
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    const productName = 'Sample Product';  // 商品名
    const amount = 100;  // 480円をセン単位（48000）に変換

    const userId = user.uid;

    try {
      // Firebase Cloud Functionsのエンドポイントにリクエストを送信
      const response = await fetch('https://asia-northeast2-macth-app.cloudfunctions.net/createStripeCheckoutSession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productName, amount, userId }),  // 商品名、金額、ユーザーIDを送信
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const session = await response.json();  // セッションIDを含むレスポンスを取得

      // StripeのCheckoutセッションにリダイレクト
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  return (
    <button role="link" onClick={handleClick}>
      Checkout
    </button>
  );
};

export default CheckoutButton;
