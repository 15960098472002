import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig'; // Firebase Firestoreのインポート
import { collection, doc, getDoc, getDocs, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom'; 
import MenuBar from '../components/MenuBar'; 
import '../styles/InviteGroup.css'; 

const InviteGroup = () => {
  const [preParticipants, setPreParticipants] = useState([]); // グループに入りたい人のUIDを格納する状態
  const [invitedGroups, setInvitedGroups] = useState([]); // 招待されたグループを格納する状態
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const user = auth.currentUser;

  // ユーザー情報を格納する状態を追加
  const [participantInfo, setParticipantInfo] = useState({});

  useEffect(() => {
    const fetchOrganizedGroups = async () => {
      if (!user) {
        alert('ログインしてください');
        return;
      }

      setLoading(true);
      try {
        // 自分がオーガナイザーのグループを取得
        const groupsCollection = collection(db, 'groups');
        const querySnapshot = await getDocs(groupsCollection);
        const organizedGroups = [];
        const invitedGroupList = [];

        querySnapshot.forEach((docSnapshot) => {
          const groupData = docSnapshot.data();
          
          // 自分がオーガナイザーのグループを取得し、pre-participantsがあるグループを表示
          if (groupData.organizer === user.uid && groupData['pre-participants'] && groupData['pre-participants'].length > 0) {
            organizedGroups.push({
              groupId: docSnapshot.id,
              groupName: groupData.name,
              preParticipants: groupData['pre-participants'],
            });

            // 各 pre-participant の情報を取得
            groupData['pre-participants'].forEach(async (memberId) => {
              const memberDocRef = doc(db, 'members', memberId);
              const memberDoc = await getDoc(memberDocRef);
              if (memberDoc.exists()) {
                setParticipantInfo((prev) => ({
                  ...prev,
                  [memberId]: memberDoc.data(),
                }));
              }
            });
          }

          // 自分が招待されたグループを表示する
          if (groupData.invitedMembers && groupData.invitedMembers.includes(user.uid)) {
            invitedGroupList.push({
              groupId: docSnapshot.id,
              groupName: groupData.name,
              imageUrl: groupData.imageUrl,
              genre: groupData.genre,
              type: groupData.type,
            });
          }
        });

        setPreParticipants(organizedGroups);
        setInvitedGroups(invitedGroupList);
      } catch (error) {
        console.error('グループ情報の取得エラー:', error);
      }
      setLoading(false);
    };

    fetchOrganizedGroups();
  }, [user]);

  const handleApproveRequest = async (groupId, memberId) => {
    try {
      // グループのparticipantsにリクエストしたユーザーを追加
      await updateDoc(doc(db, 'groups', groupId), {
        participants: arrayUnion(memberId),
        'pre-participants': arrayRemove(memberId), // pre-participantsから削除
      });

      alert('ユーザーを承認しました');
      setPreParticipants((prevGroups) =>
        prevGroups.map((group) =>
          group.groupId === groupId
            ? { ...group, preParticipants: group.preParticipants.filter((id) => id !== memberId) }
            : group
        )
      );
    } catch (error) {
      console.error('承認エラー:', error);
      alert('ユーザーの承認に失敗しました');
    }
  };

  const handleRejectRequest = async (groupId, memberId) => {
    try {
      // グループのpre-participantsから削除し、rejectedMembersに追加
      await updateDoc(doc(db, 'groups', groupId), {
        'pre-participants': arrayRemove(memberId),
        rejectedMembers: arrayUnion(memberId), // 拒否されたメンバーをrejectedMembersに追加
      });

      alert('リクエストを拒否しました');
      setPreParticipants((prevGroups) =>
        prevGroups.map((group) =>
          group.groupId === groupId
            ? { ...group, preParticipants: group.preParticipants.filter((id) => id !== memberId) }
            : group
        )
      );
    } catch (error) {
      console.error('拒否エラー:', error);
      alert('リクエストの拒否に失敗しました');
    }
  };

  // グループに加入する処理
  const handleJoinGroup = async (groupId) => {
    try {
      // グループのparticipantsに現在のユーザーを追加し、invitedMembersから削除
      await updateDoc(doc(db, 'groups', groupId), {
        participants: arrayUnion(user.uid),
        invitedMembers: arrayRemove(user.uid), // 招待されたメンバーから削除
      });

      alert('グループに加入しました');
      setInvitedGroups((prevGroups) =>
        prevGroups.filter((group) => group.groupId !== groupId)
      );
    } catch (error) {
      console.error('グループ参加エラー:', error);
      alert('グループに参加できませんでした');
    }
  };

  // グループ参加を拒否する処理
  const handleDenyGroup = async (groupId) => {
    try {
      // invitedMembersから削除し、denyInvitedGroupに移動
      await updateDoc(doc(db, 'groups', groupId), {
        invitedMembers: arrayRemove(user.uid),
        denyInvitedGroup: arrayUnion(user.uid), // 拒否されたメンバーをdenyInvitedGroupに追加
      });

      alert('グループへの参加を拒否しました');
      setInvitedGroups((prevGroups) =>
        prevGroups.filter((group) => group.groupId !== groupId)
      );
    } catch (error) {
      console.error('グループ参加拒否エラー:', error);
      alert('グループ参加の拒否に失敗しました');
    }
  };

  if (loading) {
    return <p>読み込み中...</p>;
  }

  return (
    <div className="group-page-custom-container">
      <div className="settings-header">
        <p className="settings-title">グループ</p>
      </div>
      <div className="top-menu">
        <Link to="/your-group" className="top-menu-link">所属中</Link>
        <Link to="/group" className="top-menu-link">新しく探す</Link>
        <Link to="/invite-group" className="top-menu-link">招待</Link>
      </div>

{/* 承認待ちのpre-participantsを表示 */}
<div className="your-group-grid">
  {preParticipants.length > 0 ? (
    <>
      <h3>承認待ちのリクエスト</h3> {/* タイトルをここに移動 */}
      {preParticipants.map(({ groupId, groupName, preParticipants }) => (
        <div key={groupId}>
          <h4>・グループ「{groupName}」の参加リクエスト</h4>
          <ul>
            {preParticipants.map((memberId) => (
              <li key={memberId} className="participant-card">
                {participantInfo[memberId] ? (
                  <div className="participant-info">
                    <div className="participant-details">
                      <img 
                        src={participantInfo[memberId].photoURL || '/images/default.png'} 
                        alt={participantInfo[memberId].name} 
                        className="participant-photo" 
                      />
                      <p>{participantInfo[memberId].name} <span className='participant-location'><br />{participantInfo[memberId].location}・{participantInfo[memberId].major}</span></p>
                    </div>
                    <div className="participant-actions">
                      <button className="btn-approve" onClick={() => handleApproveRequest(groupId, memberId)}>承認</button>
                      <button className="btn-reject" onClick={() => handleRejectRequest(groupId, memberId)}>拒否</button>
                    </div>
                  </div>
                ) : (
                  <p>読み込み中...</p>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  ) : (
    <p></p>
  )}
</div>


      {/* 招待されたグループを表示 */}
<div className="your-group-grid">
  {invitedGroups.length > 0 ? (
    <>
      <h4>・招待されたグループ</h4> {/* タイトルをここに移動 */}
      {invitedGroups.map(({ groupId, groupName, imageUrl, genre, type }) => (
        <li key={groupId} className="participant-card">
          <div className="participant-info">
            <div className="participant-details">
              <img src={imageUrl || '/images/default.png'} alt={groupName} className="participant-photo" />
              <p>
                {groupName}
                <br />
                <span className='participant-location'>{genre}・{type === 'public' ? '公開' : 'プライベート'}</span>
              </p>
            </div>
            <div className="participant-actions">
              <button onClick={() => handleJoinGroup(groupId)} className="btn-approve">加入</button>
              <button onClick={() => handleDenyGroup(groupId)} className="btn-reject">加入しない</button>
            </div>
          </div>
        </li>
      ))}
    </>
  ) : (
    <p></p>
  )}
</div>

      
      <MenuBar />
    </div>
  );
};

export default InviteGroup;
