import React from 'react';

const LegalNotice = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '400px', margin: '0 auto', textAlign: 'left'}}>
      <img src="/images/tokuteisyoutorihiki.png"  style={{Width: '500px',maxWidth: '500px', margin: '0 auto', textAlign: 'left'}}></img>
      
    </div>
  );
};

export default LegalNotice;
