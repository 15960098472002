import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, getDocs, updateDoc, doc, arrayUnion } from 'firebase/firestore';
import '../styles/GroupPage.css'; 
import MenuBar from '../components/MenuBar';
import { Link } from 'react-router-dom'; 
import '../styles/YourGroup.css'; 
import { FaPlusCircle } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";

const GroupPage = () => {
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(() => auth.currentUser); 
  const [searchName, setSearchName] = useState(''); 
  const [searchGenre, setSearchGenre] = useState(''); 
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null); // モーダル表示用の選択されたグループ
    // モーダル表示状態を管理する
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      const fetchGroups = async () => {
        const groupCollection = collection(db, 'groups');
        const groupSnapshot = await getDocs(groupCollection);
        const groupData = groupSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        // createdAtでソートし、新しいものから5件取得、かつ"輪読・ゼミ"を除外
        const filteredNewGroups = groupData
          .filter(group => group.genre !== '輪読・ゼミ') // "輪読・ゼミ"以外のグループ
          .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()) // createdAtでソート
          .slice(0, 5); // 上位5件を抽出
        
        setGroups(groupData);
        setFilteredGroups(filteredNewGroups); 
        setLoading(false);
      };
    
      fetchGroups();
    }, []);
    

  const handleSearch = () => {
    const lowerSearchName = searchName.toLowerCase(); 
    const lowerSearchGenre = searchGenre.toLowerCase();
    
    const filtered = groups.filter(group => {
      const groupName = group.name ? group.name.toLowerCase() : ''; 
      const groupGenre = group.genre ? group.genre.toLowerCase() : ''; 
  
      const matchesName = groupName.includes(lowerSearchName);
      const matchesGenre = groupGenre.includes(lowerSearchGenre);
      return matchesName && matchesGenre;
    });
  
    setFilteredGroups(filtered);
    setIsSearchActive(true); 
  };

  const handleJoinGroup = async (groupId, groupType) => {
    try {
      const groupRef = doc(db, 'groups', groupId);

      if (groupType === 'public') {
        await updateDoc(groupRef, {
          participants: arrayUnion(user.uid)
        });
        alert('グループに参加しました！');
      } else {
        await updateDoc(groupRef, {
          'pre-participants': arrayUnion(user.uid)
        });
        alert('参加リクエストを送信しました。');
      }
    } catch (error) {
      console.error('Error joining group:', error);
      alert('グループに参加できませんでした');
    }
  };

  const isParticipant = (group) => {
    return (group.participants || []).includes(user.uid);
  };
  
  const isPendingApproval = (group) => {
    return (group['pre-participants'] || []).includes(user.uid);
  };
  

  const isOrganizer = (group) => {
    return group.organizer && group.organizer === user.uid;
  };

  const handleGroupClick = (group) => {
    setSelectedGroup(group); // モーダルに選択されたグループの詳細を表示
  };

  const handleCloseModal = () => {
    setSelectedGroup(null); // モーダルを閉じる
  };

  

  return (
    <div className="group-page-custom-container">
      <div className="settings-header">
        <p className="settings-title">グループ</p>
      </div>

      {/* 上部メニュー */}
      <div className="top-menu">
        <Link to="/your-group" className="top-menu-link">所属中</Link>
        <Link to="/group" className="top-menu-link">新しく探す</Link>
        <Link to="/invite-group" className="top-menu-link">招待</Link>
      </div>

      {/* Search form */}
      <div className="search-form">
        <input
          type="text"
          placeholder="グループ名で検索"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          className="search-input"
        />
        <select
          value={searchGenre}
          onChange={(e) => setSearchGenre(e.target.value)}
          className="search-select"
        >
          <option value="">All</option>
          <option value="学問">学問</option>
          <option value="趣味">趣味</option>
          <option value="輪読・ゼミ">輪読・ゼミ</option>
          <option value="協力">コラボレーション</option>
          <option value="進路">進路</option>
          <option value="学会">学会</option>
          <option value="恋愛">恋愛</option>
          <option value="その他">その他</option>
        </select>
        <button onClick={handleSearch} className="search-button">検索</button>
      </div>

      {/* 新着グループ (検索がアクティブでない場合のみ表示) */}
      {!isSearchActive && (
        <>
          <br/><br/>
          <h3>輪読・ゼミで登録された本</h3><br/>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="group-card-container">
              {groups
                .filter(group => group.genre === '輪読・ゼミ') // genreが「輪読・ゼミ」のグループのみフィルタリング
                .map(group => (
                  <div key={group.id} className="group-card">
                    <img
                      src={group.imageUrl || '/images/default-group.png'}
                      alt={group.name}
                      className="rindoku-card-image"
                      onClick={() => handleGroupClick(group)} // 画像クリックでモーダル表示
                    />
                    <div className="group-card-content">
                      <h3 className="group-card-title">{group.name}</h3>
                      {isParticipant(group) || isOrganizer(group) ? (
                        <p className="group-card-button already-jioined">参加済み</p>
                      ) : isPendingApproval(group) ? (
                        <p className="group-card-button already-jioined">承認待ち</p>
                      ) : (
                        <button onClick={() => handleJoinGroup(group.id, group.type)} className="group-card-button yet-join">参加する</button>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}

          <br/><br/>
          <h3>新着</h3><br/>
          {loading ? (
            <p>Loading...</p>
          ) : (
<div className="responsive-group-grid">
  {filteredGroups
    .sort((a, b) => (b.participants ? b.participants.length : 0) - (a.participants ? a.participants.length : 0))  // undefined 回避
    .map(group => (
      <div key={group.id} className="responsive-group-card">
        <img
          src={group.imageUrl || '/images/default-group.png'}
          alt={group.name}
          className="responsive-group-card-image"
          onClick={() => handleGroupClick(group)} // 画像クリックでモーダル表示
        />
        <div className="responsive-group-card-content">
          <h3 className="responsive-group-card-title">{group.name}</h3>
          <p className="responsive-group-card-genre">ジャンル: {group.genre}</p>
          <p className="responsive-group-card-genre">タイプ: {group.type === 'public' ? 'パブリック' : 'プライベート'}</p>
          <p className="responsive-group-card-participants">参加者: {(group.participants || []).length}</p>

          {isParticipant(group) || isOrganizer(group) ? (
            <p className="group-card-button already-jioined">参加済み</p>
          ) : isPendingApproval(group) ? (
            <p className="group-card-button already-jioined">承認待ち</p>
          ) : (
            <button onClick={() => handleJoinGroup(group.id, group.type)} className="group-card-button yet-join">参加する</button>
          )}
        </div>
      </div>
  ))}
</div>


          )}
        </>
      )}

      {/* すべてのグループ or 検索結果 */}
      <br/><br/>
      <h3>{isSearchActive ? '検索結果' : 'すべてのグループ'}</h3><br/>
      {loading ? (
        <p>Loading...</p>
      ) : (
<div className="responsive-group-grid">
  {filteredGroups
    .sort((a, b) => (b.participants ? b.participants.length : 0) - (a.participants ? a.participants.length : 0))  // undefined 回避
    .map(group => (
      <div key={group.id} className="responsive-group-card">
        <img
          src={group.imageUrl || '/images/default-group.png'}
          alt={group.name}
          className="responsive-group-card-image"
          onClick={() => handleGroupClick(group)} // 画像クリックでモーダル表示
        />
        <div className="responsive-group-card-content">
          <h3 className="responsive-group-card-title">{group.name}</h3>
          <p className="responsive-group-card-genre">ジャンル: {group.genre}</p>
          <p className="responsive-group-card-genre">タイプ: {group.type === 'public' ? 'パブリック' : 'プライベート'}</p>
          <p className="responsive-group-card-participants">参加者: {(group.participants || []).length}</p>

          {isParticipant(group) || isOrganizer(group) ? (
            <p className="group-card-button already-jioined">参加済み</p>
          ) : isPendingApproval(group) ? (
            <p className="group-card-button already-jioined">承認待ち</p>
          ) : (
            <button onClick={() => handleJoinGroup(group.id, group.type)} className="group-card-button yet-join">参加する</button>
          )}
        </div>
      </div>
  ))}
</div>

      )}

<div className="other-member-profile-actions">      
        {/* モーダル表示トリガーボタン */}
        <button className="other-member-profile-button" onClick={() => setShowModal(true)}>
          <FaPlus size={25} />
        </button>
      </div>

            {/* モーダル表示 */}
            {showModal && (
        <div className="modal-background" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>どちらを作成しますか？</h2>
            <div>
              <Link to="/create-group" className="group-create-modal-button">
                通常のグループ
              </Link>

            <Link to="/rindoku-group" className="group-create-modal-button">
                輪読に関するグループ
              </Link>
            </div>
            <button className="modal-close-button" onClick={() => setShowModal(false)}>×</button>
          </div>
        </div>
      )}

{/* モーダルウィンドウ */}
{selectedGroup && (
  <div className="group-modal-overlay">
    <div className="group-modal-content">
      <button className="group-modal-close" onClick={handleCloseModal}>×</button>
      <div className="group-modal-header">
        <img
          src={selectedGroup.imageUrl || '/images/default-group.png'}
          alt={selectedGroup.name}
          className="group-modal-group-image"
        />
        <h2 className="group-modal-group-title">{selectedGroup.name}</h2>
      </div>
      <div className="group-modal-group-details">
        <p><strong>ジャンル:</strong> {selectedGroup.genre}</p>
        <p><strong>説明:</strong> {selectedGroup.description || '説明はありません。'}</p>
        <p><strong>参加者:</strong> {(selectedGroup.participants || []).length}</p> {/* ここを修正 */}
        <p><strong>グループタイプ:</strong> {selectedGroup.type === 'public' ? 'パブリック' : 'プライベート'}</p>
      </div>
    </div>
  </div>
)}


      <MenuBar />
    </div>
  );
};

export default GroupPage;
