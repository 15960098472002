import React, { useState, useRef } from 'react';
import { FaTimes, FaImage, FaFilePdf } from 'react-icons/fa';
import MathQuill, { addStyles as addMathquillStyles } from 'react-mathquill';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // リッチテキストエディタ用のスタイル
import 'katex/dist/katex.min.css'; // LaTeXの表示用
import '../styles/ThreadModal.css'; // CSSファイルをインポート

addMathquillStyles(); // MathQuillのスタイルを適用

const ThreadModal = ({ show, onClose, threadText, onChange, onSubmit, errorMessage }) => {
  const [inputLatex, setInputLatex] = useState('');
  const mathFieldRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showMathField, setShowMathField] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleLatexChange = (mathField) => {
    setInputLatex(mathField.latex());
  };

  const handleInsertLatex = () => {
    const latex = `$${inputLatex}$`;
    const newText = threadText + latex;
    onChange({ target: { value: newText } });
  };

  const addSymbol = (symbol, event) => {
    event.preventDefault();

    if (mathFieldRef.current) {
      mathFieldRef.current.focus();
      mathFieldRef.current.write(symbol);
      setInputLatex(mathFieldRef.current.latex());
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(threadText, inputLatex, selectedFile);
    setInputLatex('');
    setSelectedFile(null);
  };

  if (!show) return null;

  return (
    <div className="modal-background" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <FaTimes className="close-modal" onClick={onClose} />
        <h3>新しいスレッドを投稿する</h3>

        {/* リッチテキストエディタ */}
        <ReactQuill
          value={threadText}
          onChange={(content) => onChange({ target: { value: content } })}
          modules={{
            toolbar: [
              [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ['bold', 'italic', 'underline', 'strike'], // 太字、斜体、下線、取り消し線
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'], // クリアフォーマット
            ],
          }}
          placeholder="スレッドの内容を入力してください"
        />


        {showMathField && (
          <>
            <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'left' }}>
            <h4>数式入力フォーム<br></br><span  style={{ fontSize: '10px'}} >※上のフォームに直接latex形式を入力することも可能です。</span>
              </h4>
              <MathQuill
                latex={inputLatex}
                onChange={handleLatexChange}
                mathquillDidMount={(mathField) => { mathFieldRef.current = mathField; }}
                style={{ width: '80%', padding: '10px', border: '1px solid #ccc' }}
              />
            </div>


            <div style={{ marginBottom: '20px', textAlign: 'left'  }}>
  {/* 数学記号 */}
  <button onClick={(event) => addSymbol('\\int ', event)}>∫</button>
  <button onClick={(event) => addSymbol('\\sum ', event)}>Σ</button>
  <button onClick={(event) => addSymbol('\\frac{}{} ', event)}>{`\\frac{a}{b}`}</button>
  <button onClick={(event) => addSymbol('\\sqrt{} ', event)}>√</button>
  <button onClick={(event) => addSymbol('\\lim ', event)}>lim</button>
  <button onClick={(event) => addSymbol('\\infty ', event)}>∞</button>
  <button onClick={(event) => addSymbol('\\log ', event)}>log</button>
  <button onClick={(event) => addSymbol('\\ln ', event)}>ln</button>
  <button onClick={(event) => addSymbol('\\partial ', event)}>∂</button>
  <button onClick={(event) => addSymbol('\\times ', event)}>×</button>
  <button onClick={(event) => addSymbol('\\div ', event)}>÷</button>
  <br></br> <br></br>
  {/* ギリシャ文字 */}
 {/* ギリシャ文字 */}
<button onClick={(event) => addSymbol('\\alpha ', event)}>α</button>
<button onClick={(event) => addSymbol('\\beta ', event)}>β</button>
<button onClick={(event) => addSymbol('\\gamma ', event)}>γ</button>
<button onClick={(event) => addSymbol('\\delta ', event)}>δ</button>
<button onClick={(event) => addSymbol('\\epsilon ', event)}>ε</button>
<button onClick={(event) => addSymbol('\\zeta ', event)}>ζ</button>
<button onClick={(event) => addSymbol('\\eta ', event)}>η</button>
<button onClick={(event) => addSymbol('\\theta ', event)}>θ</button>
<button onClick={(event) => addSymbol('\\iota ', event)}>ι</button>
<button onClick={(event) => addSymbol('\\kappa ', event)}>κ</button>
<button onClick={(event) => addSymbol('\\lambda ', event)}>λ</button>
<button onClick={(event) => addSymbol('\\mu ', event)}>μ</button>
<button onClick={(event) => addSymbol('\\nu ', event)}>ν</button>
<button onClick={(event) => addSymbol('\\xi ', event)}>ξ</button>
<button onClick={(event) => addSymbol('\\omicron ', event)}>ο</button>
<button onClick={(event) => addSymbol('\\pi ', event)}>π</button>
<button onClick={(event) => addSymbol('\\rho ', event)}>ρ</button>
<button onClick={(event) => addSymbol('\\sigma ', event)}>σ</button>
<button onClick={(event) => addSymbol('\\tau ', event)}>τ</button>
<button onClick={(event) => addSymbol('\\upsilon ', event)}>υ</button>
<button onClick={(event) => addSymbol('\\phi ', event)}>φ</button>
<button onClick={(event) => addSymbol('\\chi ', event)}>χ</button>
<button onClick={(event) => addSymbol('\\psi ', event)}>ψ</button>
<button onClick={(event) => addSymbol('\\omega ', event)}>ω</button>
<br></br> <br></br>
{/* 大文字ギリシャ文字 */}
<button onClick={(event) => addSymbol('\\Alpha ', event)}>Α</button>
<button onClick={(event) => addSymbol('\\Beta ', event)}>Β</button>
<button onClick={(event) => addSymbol('\\Gamma ', event)}>Γ</button>
<button onClick={(event) => addSymbol('\\Delta ', event)}>Δ</button>
<button onClick={(event) => addSymbol('\\Epsilon ', event)}>Ε</button>
<button onClick={(event) => addSymbol('\\Zeta ', event)}>Ζ</button>
<button onClick={(event) => addSymbol('\\Eta ', event)}>Η</button>
<button onClick={(event) => addSymbol('\\Theta ', event)}>Θ</button>
<button onClick={(event) => addSymbol('\\Iota ', event)}>Ι</button>
<button onClick={(event) => addSymbol('\\Kappa ', event)}>Κ</button>
<button onClick={(event) => addSymbol('\\Lambda ', event)}>Λ</button>
<button onClick={(event) => addSymbol('\\Mu ', event)}>Μ</button>
<button onClick={(event) => addSymbol('\\Nu ', event)}>Ν</button>
<button onClick={(event) => addSymbol('\\Xi ', event)}>Ξ</button>
<button onClick={(event) => addSymbol('\\Omicron ', event)}>Ο</button>
<button onClick={(event) => addSymbol('\\Pi ', event)}>Π</button>
<button onClick={(event) => addSymbol('\\Rho ', event)}>Ρ</button>
<button onClick={(event) => addSymbol('\\Sigma ', event)}>Σ</button>
<button onClick={(event) => addSymbol('\\Tau ', event)}>Τ</button>
<button onClick={(event) => addSymbol('\\Upsilon ', event)}>Υ</button>
<button onClick={(event) => addSymbol('\\Phi ', event)}>Φ</button>
<button onClick={(event) => addSymbol('\\Chi ', event)}>Χ</button>
<button onClick={(event) => addSymbol('\\Psi ', event)}>Ψ</button>
<button onClick={(event) => addSymbol('\\Omega ', event)}>Ω</button>


</div>


        <button onClick={handleInsertLatex} className="thread-submit-button" style={{ marginBottom: '10px' }}>
          数式を挿入
        </button><br></br><br></br>
          </>
        )}<br></br>
<div className="button-file-container">
  {/* 数式入力フィールド */}
  <button onClick={() => setShowMathField(!showMathField)} className="thread-math-assist-button">
    {showMathField ? '数式入力を隠す' : '数式入力アシスト'}
  </button>

  {/* ファイルアップロードアイコン */}
  <div className="file-upload-icons">
    <label>
      <FaImage size={18} />
      <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleFileChange} />
    </label>

    <label>
      <FaFilePdf size={18} />
      <input type="file" accept=".pdf" style={{ display: 'none' }} onChange={handleFileChange} />
    </label>
  </div>

  {/* 投稿ボタン */}
  <button className="thread-submit-button" onClick={handleFormSubmit}>
    投稿
  </button>
</div>


        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default ThreadModal;
