import React, { useEffect, useState } from 'react';
import { db, auth } from '../../firebaseConfig';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import '../../styles/ChatNotDisplay.css';

const ChatNotDisplay = () => {
  const [user] = useAuthState(auth);
  const [hiddenChats, setHiddenChats] = useState([]);
  const [participants, setParticipants] = useState({});

  useEffect(() => {
    const fetchHiddenChats = async () => {
      if (user) {
        try {
          const chatQuery = query(collection(db, 'chats'), where('participants', 'array-contains', user.uid));
          const chatSnapshot = await getDocs(chatQuery);

          const hiddenChatDocs = chatSnapshot.docs.filter(chatDoc => chatDoc.data().notdisplay && chatDoc.data().notdisplay.includes(user.uid));
          const hiddenChatData = hiddenChatDocs.map(chatDoc => ({ id: chatDoc.id, ...chatDoc.data() }));

          setHiddenChats(hiddenChatData);

          const participantIds = new Set();
          hiddenChatData.forEach(chat => {
            chat.participants.forEach(participant => {
              if (participant !== user.uid) {
                participantIds.add(participant);
              }
            });
          });

          const participantsData = {};
          await Promise.all(Array.from(participantIds).map(async (id) => {
            const docRef = doc(db, 'members', id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              participantsData[id] = docSnap.data();
            }
          }));

          setParticipants(participantsData);
        } catch (error) {
          console.error('Error fetching hidden chats:', error);
        }
      }
    };

    fetchHiddenChats();
  }, [user]);

  const handleUnhideChat = async (chatId) => {
    if (user) {
      try {
        const chatRef = doc(db, 'chats', chatId);
        const chatDoc = await getDoc(chatRef);
        if (chatDoc.exists()) {
          const notdisplay = Array.isArray(chatDoc.data().notdisplay) ? chatDoc.data().notdisplay : [];
          const updatedNotdisplay = notdisplay.filter(uid => uid !== user.uid);
          await updateDoc(chatRef, { notdisplay: updatedNotdisplay });

          setHiddenChats(hiddenChats.filter(chat => chat.id !== chatId));
        }
      } catch (error) {
        console.error('Error unhiding chat:', error);
      }
    }
  };

  return (
    <div className="chat-not-display-container">
      <div className="header">
        <Link to="/settings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">チャット非表示一覧</p>
      </div>
      <ul className="hidden-chats-list">
        {hiddenChats.map(chat => {
          const participant = chat.participants.find(p => p !== user.uid);
          const participantData = participants[participant];
          return (
            <li key={chat.id} className="hidden-chat-item">
              <Link to={`/chat/${chat.id}`} className="hidden-chat-link">
                <img src={participantData?.photoURL || '/images/default.png'} alt="Profile" className="hidden-chat-avatar" />
                <div className="hidden-chat-info">
                  <h3 className="hidden-chat-name">{participantData?.name || 'Unknown'}</h3>
                </div>
              </Link>
              <button onClick={() => handleUnhideChat(chat.id)} className="unhide-chat-button">再表示する</button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ChatNotDisplay;
