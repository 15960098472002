import React, { useState, useRef } from 'react';
import 'katex/dist/katex.min.css';
import MathQuill, { addStyles as addMathquillStyles } from 'react-mathquill';

addMathquillStyles(); // MathQuillのスタイルを適用

const MathInputPage = () => {
  const [inputLatex, setInputLatex] = useState(''); // 初期値を設定
  const mathFieldRef = useRef(null); // useRefでMathQuillのフィールドを参照する変数を作成

  // MathFieldを管理する
  const handleChange = (mathField) => {
    setInputLatex(mathField.latex()); // MathQuillのLaTeXを取得
  };

  // ボタンで記号を挿入する
  const addSymbol = (symbol, event) => {
    event.preventDefault(); // ボタンのデフォルト挙動を防ぐ
    console.log('Inserting symbol:', symbol);
    
    if (mathFieldRef.current) {
      mathFieldRef.current.focus(); // フォーカスを戻して
      mathFieldRef.current.write(symbol); // ボタン押下で記号を挿入
      setInputLatex(mathFieldRef.current.latex()); // 現在のLaTeXを更新
    } else {
      console.log('mathFieldRef is null');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>数式入力ページ</h1>
      
      {/* MathQuill入力フォーム */}
      <MathQuill 
        latex={inputLatex} // 現在の数式を表示
        onChange={handleChange} // 数式が変更された時に呼ばれる
        mathquillDidMount={(mathField) => { mathFieldRef.current = mathField; }} // MathFieldの参照を保持
        style={{ width: '80%', padding: '10px', marginBottom: '20px' }}
      />
      
      {/* 数式挿入用のボタン群 */}
      <div style={{ marginBottom: '20px' }}>
        {/* 基本記号 */}
        <button onClick={(event) => addSymbol('\\int ', event)}>∫ 積分</button>
        <button onClick={(event) => addSymbol('\\sum ', event)}>Σ シグマ</button>
        <button onClick={(event) => addSymbol('\\frac{}{} ', event)}>{`\\frac{a}{b}`} 分数</button>
        <button onClick={(event) => addSymbol('\\sqrt{} ', event)}>√ 平方根</button>
        <button onClick={(event) => addSymbol('\\lim ', event)}>lim 極限</button>
        <button onClick={(event) => addSymbol('\\infty ', event)}>∞ 無限</button>
        <button onClick={(event) => addSymbol('\\log ', event)}>log 対数</button>
        <button onClick={(event) => addSymbol('\\ln ', event)}>ln 自然対数</button>
        <button onClick={(event) => addSymbol('\\partial ', event)}>∂ 微分</button>
        <button onClick={(event) => addSymbol('\\times ', event)}>× 掛け算</button>
        <button onClick={(event) => addSymbol('\\div ', event)}>÷ 割り算</button>
      </div>

      <div style={{ marginBottom: '20px' }}>
        {/* ギリシャ文字 */}
        <button onClick={(event) => addSymbol('\\alpha ', event)}>α</button>
        <button onClick={(event) => addSymbol('\\beta ', event)}>β</button>
        <button onClick={(event) => addSymbol('\\gamma ', event)}>γ</button>
        <button onClick={(event) => addSymbol('\\delta ', event)}>δ</button>
        <button onClick={(event) => addSymbol('\\epsilon ', event)}>ε</button>
        <button onClick={(event) => addSymbol('\\zeta ', event)}>ζ</button>
        <button onClick={(event) => addSymbol('\\eta ', event)}>η</button>
        <button onClick={(event) => addSymbol('\\theta ', event)}>θ</button>
        <button onClick={(event) => addSymbol('\\iota ', event)}>ι</button>
        <button onClick={(event) => addSymbol('\\kappa ', event)}>κ</button>
        <button onClick={(event) => addSymbol('\\lambda ', event)}>λ</button>
        <button onClick={(event) => addSymbol('\\mu ', event)}>μ</button>
        <button onClick={(event) => addSymbol('\\nu ', event)}>ν</button>
        <button onClick={(event) => addSymbol('\\xi ', event)}>ξ</button>
        <button onClick={(event) => addSymbol('\\pi ', event)}>π</button>
        <button onClick={(event) => addSymbol('\\rho ', event)}>ρ</button>
        <button onClick={(event) => addSymbol('\\sigma ', event)}>σ</button>
        <button onClick={(event) => addSymbol('\\tau ', event)}>τ</button>
        <button onClick={(event) => addSymbol('\\upsilon ', event)}>υ</button>
        <button onClick={(event) => addSymbol('\\phi ', event)}>φ</button>
        <button onClick={(event) => addSymbol('\\chi ', event)}>χ</button>
        <button onClick={(event) => addSymbol('\\psi ', event)}>ψ</button>
        <button onClick={(event) => addSymbol('\\omega ', event)}>ω</button>
      </div>

      <div style={{ marginBottom: '20px' }}>
        {/* 三角関数 */}
        <button onClick={(event) => addSymbol('\\sin ', event)}>sin</button>
        <button onClick={(event) => addSymbol('\\cos ', event)}>cos</button>
        <button onClick={(event) => addSymbol('\\tan ', event)}>tan</button>
        <button onClick={(event) => addSymbol('\\cot ', event)}>cot</button>
        <button onClick={(event) => addSymbol('\\sec ', event)}>sec</button>
        <button onClick={(event) => addSymbol('\\csc ', event)}>csc</button>
      </div>

      {/* 入力されたLaTeX数式を表示 */}
      <div style={{ marginTop: '20px' }}>
        <h3>現在の数式（LaTeX形式）:</h3>
        <p>{inputLatex}</p>
      </div>
    </div>
  );
};

export default MathInputPage;
