import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import '../styles/PasswordResetEmail.css';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();

    setMessage('');
    setError('');

    if (!email) {
      setError('メールアドレスを入力してください。');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('パスワードリセットのメールを送信しました。メールを確認してください。');
    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-email':
          setError('無効なメールアドレスです。');
          break;
        case 'auth/user-not-found':
          setError('このメールアドレスは登録されていません。');
          break;
        default:
          setError('パスワードリセット中にエラーが発生しました。');
      }
    }
  };

  return (
    <div className="reset-password-container">
                  <header className="header-top">
        <div className="logo">
        <Link to="/"><img src="/images/default1.png" alt="Logo" /></Link>
          院生マッチ
        </div>
      </header>
      <h2 className="reset-password-title">パスワードリセット</h2>
      <p className="instruction">登録されたメールアドレスを入力してください。リセットリンクをお送りします。</p>
      {message && <p className="message success">{message}</p>}
      {error && <p className="message error">{error}</p>}
      <form onSubmit={handleResetPassword} className="reset-password-form">
        <input
          type="email"
          placeholder="メールアドレス"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="input"
        />
        <button type="submit" className="button">送信</button>
      </form>
      <p className="back-to-login">
        <Link to="/login" className="link">ログイン画面に戻る</Link>
      </p>
    </div>
  );
};

export default ResetPassword;
