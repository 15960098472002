export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // 画像のCORS対応
    image.src = url;
  });

export const getCroppedImg = async (imageSrc, crop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxWidth = 1000; // 最大幅
  const maxHeight = 1000; // 最大高さ

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleY;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  // 圧縮して画像をBlobに変換
  const file = await new Promise((resolve) =>
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg', 0.7) // 0.7の品質でJPEGに圧縮
  );

  // 画像が1MB以下になるように調整
  let quality = 0.7;
  while (file.size > 1024 * 1024 && quality > 0.1) {
    quality -= 0.1;
    const newFile = await new Promise((resolve) =>
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', quality)
    );
    if (newFile.size <= 1024 * 1024) {
      return newFile;
    }
  }

  return file;
};
