import React, { useState, useEffect } from 'react';
import { auth } from '../../firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { sendEmailVerification, updateEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import '../../styles/SettingMail.css';

const SettingMail = () => {
  const [user, loading, error] = useAuthState(auth);
  const [newEmail, setNewEmail] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [updateError, setUpdateError] = useState('');

  useEffect(() => {
    if (user) {
      const maskedEmail = user.email.replace(/(.{5})(.*)(?=@)/, "$1*****");
      setCurrentEmail(maskedEmail);
    }
  }, [user]);

  const handleEmailChange = async () => {
    if (user && newEmail) {
      try {
        await updateEmail(user, newEmail);
        await sendEmailVerification(user);
        setSuccess(true);
        setUpdateError('');
      } catch (error) {
        setUpdateError('メールアドレスの変更に失敗しました: ' + error.message);
        setSuccess(false);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (user && user.providerData[0].providerId !== 'password') {
    return (
      <div className="setting-mail-container">
            <div className="header">
      <Link to="/accountsettings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">メールアドレス変更</p>
      </div>
        <div className="error-message">
          メール認証でログインしたユーザーのみメールアドレスの変更が可能です。
        </div>
      </div>
    );
  }

  return (
    <div className="setting-mail-container">
            <div className="header">
      <Link to="/accountsettings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">メールアドレス変更</p>
      </div>
      <div className="current-email">
        現在のメールアドレス: {currentEmail}
      </div>
      <div className="form-group">
        <label htmlFor="new-email">新しいメールアドレス:</label>
        <input
          type="email"
          id="new-email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          className="form-control"
        />
      </div>
      <button onClick={handleEmailChange} className="change-email-button">
        確認メール送信
      </button>
      {success && <div className="success-message">確認メールを送信しました。メールを確認してください。</div>}
      {updateError && <div className="error-message">{updateError}</div>}
    </div>
  );
};

export default SettingMail;
