import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getStorage } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';  // 追加

const firebaseConfig = {
    apiKey: "AIzaSyCniQlrkj-bG1iO3_p45GCZgtpx-XwY7KA",
    authDomain: "macth-app.firebaseapp.com",
    projectId: "macth-app",
    storageBucket: "macth-app.appspot.com",
    messagingSenderId: "1049338814763",
    appId: "1:1049338814763:web:f305a26a2cc14fced8656e"
};

const app = initializeApp(firebaseConfig);  // Firebaseアプリを初期化
const auth = getAuth(app);
const db = getFirestore(app);
const messaging = getMessaging(app);
const storage = getStorage(app);
const functions = getFunctions(app);  // functions を追加
const provider = new GoogleAuthProvider();

// エミュレーターを使用する場合
if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functions, 'localhost', 5001);
}

// 必要なモジュールをエクスポート
export { app, auth, db, messaging, getToken, onMessage, provider, storage, functions };  // functions をエクスポート
