import React, { useEffect, useState, useRef } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, getDoc, addDoc, collection, query, where, getDocs, updateDoc, setDoc, deleteField } from 'firebase/firestore';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import MenuBar from '../components/MenuBar';
import { BiSolidLike } from "react-icons/bi";
import { IoChatbubbles } from "react-icons/io5";
import { FiMoreHorizontal } from 'react-icons/fi';
import { IoArrowRedoSharp } from "react-icons/io5";
import { useSwipeable } from 'react-swipeable';
import '../styles/OtherMemberProfile.css';
import '../styles/BookSearchPage.css';

const OtherMemberProfile = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const members = state?.members || [];
  const [profile, setProfile] = useState(null);
  const [user, loading, error] = useAuthState(auth);
  const [alreadyLiked, setAlreadyLiked] = useState(false);
  const [receivedLike, setReceivedLike] = useState(false);
  const [approved, setApproved] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [rejectedByUser, setRejectedByUser] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isLikeModalOpen, setIsLikeModalOpen] = useState(false); // モーダルの表示状態
  const [likeMessage, setLikeMessage] = useState(''); // メッセージ入力用のステート
  const [favorites, setFavorites] = useState([]); // お気に入りの状態管理
  const [userPosts, setUserPosts] = useState([]); // 自分の投稿を管理
  const [activeTab, setActiveTab] = useState('profile'); // プロフィールと投稿の切り替え用
  const navigate = useNavigate();
  const optionsRef = useRef(null);

  const resetStates = () => {
    setAlreadyLiked(false);
    setReceivedLike(false);
    setApproved(false);
    setChatId(null);
    setRejectedByUser(false);
  };

  const fetchProfile = async (memberId) => {
    const docRef = doc(db, 'members', memberId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setProfile(docSnap.data());
    }

    // 自分の投稿を取得する
    const postsQuery = query(
      collection(db, 'posts'),
      where('userId', '==', memberId) // 自分の投稿だけを取得
    );
    const postsSnapshot = await getDocs(postsQuery);
    const postsData = postsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setUserPosts(postsData); // 投稿データを保存
  };

  // 自分の投稿をレンダリングする関数
  const renderUserPosts = () => {
    if (userPosts.length === 0) {
      return ;
    }

    return userPosts.map((post) => (
      <div key={post.id} className="user-post">
        <div className="post-header">
          <p className="post-user-name">{post.userName}</p>
          <p className="post-date">
  {new Date(post.createdAt.seconds * 1000).toLocaleDateString('ja-JP', {
    month: '2-digit',
    day: '2-digit'
  })}{' '}
  {new Date(post.createdAt.seconds * 1000).toLocaleTimeString('ja-JP', {
    hour: '2-digit',
    minute: '2-digit'
  })}
</p>

        </div>
        <div className="post-content">
          <p className="post-text">{post.text}</p>
          {post.image && <img src={post.image} alt="Post image" className="post-image" />}
        </div>
      </div>
    ));
    
  };

  const fetchFavoritesFromFirestore = async (memberId) => {
    try {
      const q = query(collection(db, 'favorites'), where('participants', 'array-contains', memberId));
      const querySnapshot = await getDocs(q);
      const favoriteItems = querySnapshot.docs.map((docSnapshot) => {
        const data = docSnapshot.data();
        return {
          docid: docSnapshot.id,
          ...data,
        };
      });
      setFavorites(favoriteItems);
    } catch (error) {
      console.error('Error fetching favorites:', error);
    }
  };

  const calculateAge = (birthday) => {
    if (!birthday) return '--';
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const checkIfLiked = async (memberId) => {
    if (user) {
      const sentQuery = query(
        collection(db, 'likes'),
        where('from', '==', user.uid),
        where('to', '==', memberId)
      );
      const sentQuerySnapshot = await getDocs(sentQuery);
      if (!sentQuerySnapshot.empty) {
        const sentLikeDoc = sentQuerySnapshot.docs[0];
        if (sentLikeDoc.data().status === 'approved') {
          setApproved(true);
          const chatQuery = query(
            collection(db, 'chats'),
            where('participants', 'array-contains', user.uid)
          );
          const chatSnapshot = await getDocs(chatQuery);
          const chat = chatSnapshot.docs.find(doc => doc.data().participants.includes(memberId));
          if (chat) {
            setChatId(chat.id);
          }
        } else {
          setAlreadyLiked(true);
        }
      }

      const receivedQuery = query(
        collection(db, 'likes'),
        where('from', '==', memberId),
        where('to', '==', user.uid),
        where('status', '==', 'pending')
      );
      const receivedQuerySnapshot = await getDocs(receivedQuery);
      setReceivedLike(!receivedQuerySnapshot.empty);

      const approvedQuery = query(
        collection(db, 'likes'),
        where('from', '==', memberId),
        where('to', '==', user.uid),
        where('status', '==', 'approved')
      );
      const approvedQuerySnapshot = await getDocs(approvedQuery);
      if (!approvedQuerySnapshot.empty) {
        setApproved(true);
        const chatQuery = query(
          collection(db, 'chats'),
          where('participants', 'array-contains', user.uid)
        );
        const chatSnapshot = await getDocs(chatQuery);
        const chat = chatSnapshot.docs.find(doc => doc.data().participants.includes(memberId));
        if (chat) {
          setChatId(chat.id);
        }
      }
    }
  };

  const checkIfUserRejected = async (memberId) => {
    if (user) {
      const rejectedQuery = query(
        collection(db, 'likes'),
        where('from', '==', memberId),
        where('to', '==', user.uid),
        where('status', '==', 'rejected')
      );
      const rejectedQuerySnapshot = await getDocs(rejectedQuery);
      return !rejectedQuerySnapshot.empty;
    }
    return false;
  };

  const checkIfChatting = async (memberId) => {
    if (user) {
      const chatQuery = query(
        collection(db, 'chats'),
        where('participants', 'array-contains', user.uid)
      );
      const chatSnapshot = await getDocs(chatQuery);
      const chat = chatSnapshot.docs.find(doc => doc.data().participants.includes(memberId));
      if (chat) {
        setChatId(chat.id);
      }
    }
  };

  const checkIfHidden = async () => {
    if (user) {
      const memberNotDisplayRef = doc(db, 'member_not_display', id);
      const docSnap = await getDoc(memberNotDisplayRef);
      if (docSnap.exists() && docSnap.data()[user.uid]) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
    }
  };

  useEffect(() => {
    if (id) {
      resetStates();
      fetchProfile(id);
      checkIfHidden();
      fetchFavoritesFromFirestore(id); // プロフィール取得後にお気に入りを取得
    }
  }, [id]);

  useEffect(() => {
    if (id && user) {
      checkIfLiked(id);
      checkIfChatting(id);
      checkIfUserRejected(id).then(setRejectedByUser);
    }
  }, [id, user]);

  // いいねの処理
  const handleLike = async (withMessage = false) => {
    if (user) {
      const isRejected = await checkIfUserRejected(id);
      if (isRejected) {
        alert('このユーザーにはこれ以上いいねを送ることができません。');
        return;
      }

      const likeData = {
        from: user.uid,
        to: id,
        status: 'pending',
        createdAt: new Date(),
        check: false,
      };

      if (withMessage && likeMessage.trim() !== '') {
        likeData.message = likeMessage.trim();
      }

      try {
        await addDoc(collection(db, 'likes'), likeData);
        alert('いいねを送信しました');
        setAlreadyLiked(true);
        setIsLikeModalOpen(false); // モーダルを閉じる
      } catch (error) {
        console.error('Error sending like:', error.message);
      }
    } else {
      console.error('No user is authenticated');
    }
  };

  // モーダルを開く
  const openLikeModal = () => {
    setIsLikeModalOpen(true);
  };

  // モーダルを閉じる
  const closeLikeModal = () => {
    setIsLikeModalOpen(false);
  };

  const handleApprove = async () => {
    if (user) {
      try {
        const receivedQuery = query(
          collection(db, 'likes'),
          where('from', '==', id),
          where('to', '==', user.uid),
          where('status', '==', 'pending')
        );
        const receivedQuerySnapshot = await getDocs(receivedQuery);

        if (!receivedQuerySnapshot.empty) {
          const likeDoc = receivedQuerySnapshot.docs[0];
          await updateDoc(likeDoc.ref, {
            status: 'approved'
          });
          alert('マッチングしました！');
          setApproved(true);

          // チャットの作成
          const chatDocRef = await addDoc(collection(db, 'chats'), {
            participants: [user.uid, id],
            createdAt: new Date()
          });
          setChatId(chatDocRef.id);
        }
      } catch (error) {
        console.error('Error approving like:', error.message);
      }
    }
  };

  const handleReject = async () => {
    if (user) {
      try {
        const receivedQuery = query(
          collection(db, 'likes'),
          where('from', '==', id),
          where('to', '==', user.uid),
          where('status', '==', 'pending')
        );
        const receivedQuerySnapshot = await getDocs(receivedQuery);

        if (!receivedQuerySnapshot.empty) {
          const likeDoc = receivedQuerySnapshot.docs[0];
          await updateDoc(likeDoc.ref, {
            status: 'rejected'
          });
          alert('Like rejected.');
          setReceivedLike(false);
          setRejectedByUser(true);
        }
      } catch (error) {
        console.error('Error rejecting like:', error.message);
      }
    }
  };

  const openModal = (url) => {
    setModalImage(url);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const handlePreviousProfile = () => {
    if (members && members.length > 0) {
      const currentIndex = members.findIndex(member => member.id === id);
      if (currentIndex > 0) {
        navigate(`/other_member_profile/${members[currentIndex - 1].id}`, { state: { members } });
      }
    }
  };

  const handleNextProfile = () => {
    if (members && members.length > 0) {
      const currentIndex = members.findIndex(member => member.id === id);
      if (currentIndex < members.length - 1) {
        navigate(`/other_member_profile/${members[currentIndex + 1].id}`, { state: { members } });
      }
    }
  };

  const handleOptionsToggle = () => {
    setIsOptionsVisible(!isOptionsVisible);
  };

  const handleOptionSelect = async (option) => {
    if (option === 'hide' && user) {
      try {
        const memberNotDisplayRef = collection(db, 'member_not_display');
        const docRef = doc(memberNotDisplayRef, id);
        await setDoc(docRef, {
          [user.uid]: true
        }, { merge: true });
        alert('このユーザーに対し自分のプロフィールを非表示にしました。');
        setIsHidden(true);
      } catch (error) {
        console.error('Error hiding profile:', error.message);
      }
    } else if (option === 'unhide' && user) {
      try {
        const memberNotDisplayRef = collection(db, 'member_not_display');
        const docRef = doc(memberNotDisplayRef, id);
        await updateDoc(docRef, {
          [user.uid]: deleteField()
        });
        alert('このユーザーに対し自分のプロフィールの非表示を取り消しました。');
        setIsHidden(false);
      } catch (error) {
        console.error('Error unhiding profile:', error.message);
      }
    } else if (option === 'report') {
      navigate(`/report/${id}`);
    }
    setIsOptionsVisible(false);
  };

  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setIsOptionsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleNextProfile();
    },
    onSwipedRight: () => {
      handlePreviousProfile();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const renderFavorites = () => {
    return (
      <div className="custom-results-list">
        <div className="custom-results-list-content-a">
          {favorites.map((favorite) => (
            <div key={favorite.docid} className="custom-result-item-a">
              {favorite.Poster && (
                <img
                  src={favorite.Poster}
                  alt={favorite.Title || favorite.volumeInfo?.title || favorite.trackName || favorite.title}
                />
              )}
              {!favorite.Poster && favorite.volumeInfo?.imageLinks?.thumbnail && (
                <img
                  src={favorite.volumeInfo.imageLinks.thumbnail}
                  alt={favorite.Title || favorite.volumeInfo?.title || favorite.trackName || favorite.title}
                />
              )}
              {!favorite.Poster && !favorite.volumeInfo?.imageLinks?.thumbnail && favorite.artworkUrl100 && (
                <img
                  src={favorite.artworkUrl100}
                  alt={favorite.Title || favorite.volumeInfo?.title || favorite.trackName || favorite.title}
                />
              )}
              <h3>{favorite.Title || favorite.volumeInfo?.title || favorite.trackName || favorite.title}</h3>
              <p>{favorite.volumeInfo?.authors?.join(', ') || favorite.artistName || favorite.Director}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!profile) return <div>Loading profile...</div>;

  return (
    <div className="other-member-profile-container" {...handlers}>
      {members.length > 1 && (
        <>
          <button onClick={handlePreviousProfile} className="arrow left-arrow">&#9664;</button>
          <button onClick={handleNextProfile} className="arrow right-arrow">&#9654;</button>
        </>
      )}
      <div className="other-member-profile-header">
        <div className="options-container" ref={optionsRef}>
          <FiMoreHorizontal className="options-icon" onClick={handleOptionsToggle} />
          {isOptionsVisible && (
            <div className="options-menu">
              {isHidden ? (
                <div onClick={() => handleOptionSelect('unhide')}>・このユーザーに対し自分のプロフィールの非表示を取り消す</div>
              ) : (
                <div onClick={() => handleOptionSelect('hide')}>・このユーザーに対し自分のプロフィールを非表示</div>
              )}
              <div onClick={() => handleOptionSelect('report')}>・通報</div>
            </div>
          )}
        </div>
        <img
          src={profile.photoURL || '/images/default.png'}
          alt="Profile"
          className="other-member-profile-image"
          onClick={() => openModal(profile.photoURL || '/images/default.png')}
        />
        <div className="other-member-sub-images">
          {[profile.sub_photoURL_1, profile.sub_photoURL_2, profile.sub_photoURL_3].map((url, index) => (
            <img
              key={index}
              src={url || '/images/default.png'}
              alt={`Sub ${index + 1}`}
              className="other-member-sub-image"
              onClick={() => openModal(url || '/images/default.png')}
            />
          ))}
        </div>
        <h2 className="other-member-profile-name">{profile.name || '--'}</h2>
        {profile.hashtags && profile.hashtags.length > 0 && (
          <div className="other-member-profile-hashtags">
            {profile.hashtags.map((tag, index) => (
              <span key={index} className="other-member-profile-hashtag">#{tag}</span>
            ))}
          </div>
        )}
        <div className="profile-tabs">
  <button
    className={activeTab === 'profile' ? 'active-tab' : ''}
    onClick={() => setActiveTab('profile')}
  >
    プロフィール
  </button>
  <button
    className={activeTab === 'favorites' ? 'active-tab' : ''}
    onClick={() => setActiveTab('favorites')}
  >
    お気に入り作品
  </button>
  <button
    className={activeTab === 'posts' ? 'active-tab' : ''}
    onClick={() => setActiveTab('posts')}
  >
    投稿
  </button>
</div>


      </div>
<div className="profile-content">
  {activeTab === 'profile' && (
  <div className="other-member-profile-bio-s">
    <p className="other-member-profile-bio"><strong></strong>{profile.bio || '--'}</p>
    <div className="other-member-profile-details">
      {profile.birthday && (
        <p><strong>年齢:</strong> {calculateAge(profile.birthday)}歳</p>
      )}
      {profile.gender && (
        <p><strong>性別:</strong> {profile.gender}</p>
      )}
      {profile.sexualOrientation && (
        <p><strong>性的指向:</strong> {profile.sexualOrientation}</p>
      )}
      {profile.location && (
        <p><strong>居住地:</strong> {profile.location}</p>
      )}
      {profile.major && (
        <p><strong>専攻:</strong> {profile.major}</p>
      )}
      {profile.grade && (
        <p><strong>学年:</strong> {profile.grade}</p>
      )}
      {profile.schoolName && (
        <p><strong>所属大学:</strong> {profile.schoolName}</p>
      )}
      {profile.affiliation && (
        <p><strong>所属学会名:</strong> {profile.affiliation}</p>
      )}
      {profile.height && (
        <p><strong>身長:</strong> {profile.height}cm</p>
      )}
    </div></div>
  )}
    {activeTab === 'favorites' && (
    <div className="user-posts-section">
      <div>
        {renderFavorites()}
      </div>
      <div className="custom-credit">
          <p>
            Data provided by{' '}
            <a href="https://books.google.com" target="_blank" rel="noopener noreferrer">
              Google Books API
            </a>
            ,{' '}
            <a href="https://www.apple.com/itunes/" target="_blank" rel="noopener noreferrer">
              iTunes API
            </a>
            , or{' '}
            <a href="https://www.omdbapi.com/" target="_blank" rel="noopener noreferrer">
              OMDb API
            </a>
            .
          </p>
        </div>
    </div>
  )}
    {activeTab === 'posts' && (
    <div className="user-posts-section">
      {renderUserPosts()}
    </div>
  )}
</div>


      <div className="other-member-profile-actions">
        {approved || chatId ? (
          <Link to={`/chat/${chatId}`} className="other-member-profile-button"><IoChatbubbles /></Link>
        ) : receivedLike ? (
          <div className="iine-button-container">
            <button className="other-member-profile-button other-approve" onClick={handleApprove}><BiSolidLike /></button>
            <button className="other-member-profile-button other-reject" onClick={handleReject}><IoArrowRedoSharp /></button>
          </div>
        ) : rejectedByUser ? (
          <div className="other-member-profile-button other-rejected"><IoArrowRedoSharp /></div>
        ) : (
          <button 
            className={`other-member-profile-button ${alreadyLiked ? 'liked' : ''}`} 
            onClick={openLikeModal} 
            disabled={alreadyLiked}
          >
            {alreadyLiked ? <BiSolidLike /> : <><BiSolidLike /></>}
          </button>
        )}
      </div>

      {isLikeModalOpen && (
        <div className="like-modal-overlay">
          <div className="like-modal">
            <h3>メッセージを添えていいねを送りますか？<br />※個人情報の入力は禁止です。</h3>
            <textarea
              value={likeMessage}
              onChange={(e) => setLikeMessage(e.target.value)}
              placeholder="メッセージを入力してください(100文字以内)"
              className="like-modal-textarea"
            />
            <div className="like-modal-buttons">
              <button onClick={() => handleLike(true)} className="like-modal-button like-modal-button-primary">メッセージ付きで送信</button>
              <button onClick={() => handleLike(false)} className="like-modal-button like-modal-button-secondary">そのまま送信</button>
            </div>
            <button className="like-modal-close" onClick={closeLikeModal}>×</button>
          </div>
        </div>
      )}

      {modalImage && (
        <div className="modal" onClick={closeModal}>
          <span className="close" onClick={closeModal}>&times;</span>
          <img className="modal-content" src={modalImage} alt="Modal" />
        </div>
      )}
      <MenuBar />
    </div>
  );
};

export default OtherMemberProfile;
