import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, query, getDocs, orderBy, doc as firestoreDoc, setDoc, Timestamp, where, limit  } from 'firebase/firestore';

import { useAuthState } from 'react-firebase-hooks/auth';
import axios from 'axios';
import '../styles/BookSearchPage.css';
import MenuBar from '../components/MenuBar';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FaBook } from 'react-icons/fa';
import { IoMdMusicalNote } from 'react-icons/io';
import { MdMovieCreation } from 'react-icons/md';
import { IoIosStar } from "react-icons/io";
import { Link } from 'react-router-dom';

const SearchPage = () => {
  const [user] = useAuthState(auth);
  const [queryText, setQueryText] = useState('');
  const [results, setResults] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [everyoneFavorites, setEveryoneFavorites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searched, setSearched] = useState(false);
  const [searchType, setSearchType] = useState('books');
  const [currentTrack, setCurrentTrack] = useState(null);
  const [filterByOwnFavorites, setFilterByOwnFavorites] = useState(false);
  const [showOwnFavoritesText, setShowOwnFavoritesText] = useState(false);

  const searchCache = {};

  const GOOGLE_BOOKS_API_KEY = 'AIzaSyAvyBo9w15ekMNQcs5S_ksjFw2bXSenjE0';
  const OMDB_API_KEY = 'db87e4f7';

  useEffect(() => {
    fetchFavoritesFromFirestore();
  }, [user]);

  const fetchFavoritesFromFirestore = async () => {
    if (!user) return;
    setLoading(true);
    setError(null);
    try {
      // favorite_categoryが'book'のデータのみを取得し、最大30件までに制限
      const q = query(
        collection(db, 'favorites'),
        where('favorite_category', '==', 'movie'), // favorite_category が 'book' のみ
        orderBy('createdAt', 'desc'),
        limit(150) // 最大30件まで
      );
  
      const querySnapshot = await getDocs(q);
      const favoriteItems = querySnapshot.docs
        .map((docSnapshot) => {
          const data = docSnapshot.data();
          return {
            docid: docSnapshot.id,
            ...data,
          };
        })
        .filter((item) => item.participants && item.participants.length > 0); // 参加者がいるものだけフィルタリング
  
      setFavorites(favoriteItems); // フィルタリングされたデータを state にセット
    } catch (error) {
      setError('Error fetching favorites');
      console.error('Error fetching favorites:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const fetchEveryoneFavorites = async () => {
    setLoading(true);
    setError(null);
    try {
      const q = query(collection(db, 'favorites'));
      const querySnapshot = await getDocs(q);
      const favoriteItems = await Promise.all(
        querySnapshot.docs
          .map(async (docSnapshot) => {
            const data = docSnapshot.data();
            return {
              id: docSnapshot.id,
              ...data,
            };
          })
          .filter((item) => item.participants && item.participants.length > 0)
      );
      setEveryoneFavorites(favoriteItems);
    } catch (error) {
      setError("Error fetching everyone's favorites");
      console.error("Error fetching everyone's favorites:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = debounce(async (queryText) => {
    if (searchCache[queryText]) {
      console.log('Using cached results for:', queryText);
      setResults(searchCache[queryText]);
      setSearched(true);
      setLoading(false);
      return;
    }

    try {
      let response;
      if (searchType === 'books') {
        response = await axios.get(`https://www.googleapis.com/books/v1/volumes?q=${queryText}&key=${GOOGLE_BOOKS_API_KEY}`);
        console.log("Books API Response: ", response.data);
        searchCache[queryText] = response.data.items;
        setResults(response.data.items);
      } else if (searchType === 'music') {
        response = await axios.get(`https://itunes.apple.com/search?term=${queryText}&entity=musicTrack&limit=12`);
        console.log("Music API Response: ", response.data);
        searchCache[queryText] = response.data.results;
        setResults(response.data.results);
      } else if (searchType === 'movies') {
        response = await axios.get(`https://www.omdbapi.com/?s=${queryText}&apikey=${OMDB_API_KEY}`);
        console.log("Movies API Response: ", response.data);
        const detailedMovies = await Promise.all(
          response.data.Search.map(async (movie) => {
            const movieDetails = await axios.get(`https://www.omdbapi.com/?i=${movie.imdbID}&apikey=${OMDB_API_KEY}`);
            return movieDetails.data;
          })
        );
        searchCache[queryText] = detailedMovies;
        setResults(detailedMovies);
      }
      setSearched(true);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setError('Too many requests. Please try again later.');
      } else {
        setError('Error fetching data');
      }
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, 1000);

  const handleSearch = () => {
    setLoading(true);
    setError(null);
    setSearched(false);
    debouncedSearch(queryText);
  };

const handleAddFavorite = async (item) => {
  if (!user) {
    alert('Please log in to save favorites');
    return;
  }

  try {
    const favoriteRef = firestoreDoc(collection(db, 'favorites'));

    const favoriteData = {
      ...item,
      favorite_category: searchType === 'music' ? 'music' : searchType === 'movies' ? 'movie' : 'book', // 書籍の場合は 'book'
      book_category: searchType === 'books' ? (item.volumeInfo?.categories?.[0] || 'unknown') : null,  // 書籍カテゴリーをbook_categoryに保存
      participants: [user.uid],
      createdAt: Timestamp.now(),
    };

    await setDoc(favoriteRef, favoriteData);

    alert('お気に入りに追加されました');
    fetchFavoritesFromFirestore();
  } catch (error) {
    setError('Error adding to favorites: ' + error.message);
    console.error('Error adding to favorites:', error);
  }
};


  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setResults([]); 
  };

  const handleStopMusic = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0; 
    }
  };

  let audio;

  const handlePlayMusic = (previewUrl) => {
    if (audio) {
      audio.pause();
    }
    audio = new Audio(previewUrl);
    audio.play();
  };

  const truncateText = (text, maxLength) => {
    if (!text || typeof text !== 'string') {
      return ''; 
    }

    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const navigate = useNavigate();

  const handleItemClick = (id) => {
    navigate(`/content/${id}`);
  };

  const handleFilterFavorites = () => {
    setFilterByOwnFavorites(!filterByOwnFavorites);
    setShowOwnFavoritesText(!showOwnFavoritesText);
    setResults([]); 
    setSearched(false); 
  };

  const filteredFavorites = filterByOwnFavorites
    ? favorites.filter((favorite) => favorite.participants && favorite.participants.includes(user.uid))
    : favorites;

  return (
    <div className="custom-search-container">
            <div className="settings-header">
        <p className="settings-title">　学術書・作品を登録</p>
      </div>
      <div className="custom-search-bar">
        <select
          value={searchType}
          onChange={handleSearchTypeChange}
          className="custom-search-select"
        >
          <option value="books">書籍</option>
          <option value="music">音楽</option>
          <option value="movies">映画</option>
        </select>
        <input
          type="text"
          value={queryText}
          onChange={(e) => setQueryText(e.target.value)}
          placeholder={`Search for ${searchType}`}
          className="custom-search-input"
        />
        <button
          onClick={handleSearch}
          disabled={loading}
          className="custom-search-button"
        >
          検索
        </button>
      </div>

      {/* 検索結果が表示されていない場合 */}
      {!searched && !queryText && (
        <div className="custom-credit">
          <p>
            Data provided by{' '}
            <a href="https://books.google.com" target="_blank" rel="noopener noreferrer">
              Google Books API
            </a>
            ,{' '}
            <a href="https://www.apple.com/itunes/" target="_blank" rel="noopener noreferrer">
              iTunes API
            </a>
            , or{' '}
            <a href="https://www.omdbapi.com/" target="_blank" rel="noopener noreferrer">
              OMDb API
            </a>
            .
          </p>
        </div>
      )}

      {/* 検索結果が表示されている場合 */}
      {searched && (
        <div className="custom-credit">
          <p>
            Data provided by{' '}
            {searchType === 'books' ? (
              <a
                href="https://books.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Books API
              </a>
            ) : searchType === 'music' ? (
              <a
                href="https://www.apple.com/itunes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                iTunes API
              </a>
            ) : (
              <a
                href="https://www.omdbapi.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                OMDb API
              </a>
            )}
            .
          </p>
        </div>
      )}

      {error && <div className="custom-error">{error}</div>}
      {loading && <div className="custom-loading">Loading...</div>}

      {searched && results && results.length > 0 && (
        <div className="custom-results-list">
          
      <div className="filter-buttons">
        <button><FaBook /> 本</button>
        <button><IoMdMusicalNote /> 音楽</button>
        <button><MdMovieCreation /> 映画</button>
      </div>
          <div className="custom-results-list-content">
            {results.map((item, index) => {
              const hasValidPoster = item.Poster && item.Poster !== 'N/A';
              const hasThumbnail = item.volumeInfo?.imageLinks?.thumbnail;
              const hasArtwork = item.artworkUrl100;

              const hasImage = hasValidPoster || hasThumbnail || hasArtwork;

              if (!hasImage) {
                return null;
              }

              return (
                <div
                  key={item.imdbID || item.id || item.trackId}
                  className="custom-result-item"
                >
                  {hasValidPoster && (
                    <img
                      src={item.Poster}
                      alt={item.Title || item.volumeInfo?.title || item.trackName || item.title}
                    />
                  )}
                  {hasThumbnail && !hasValidPoster && (
                    <img
                      src={item.volumeInfo.imageLinks.thumbnail}
                      alt={item.Title || item.volumeInfo?.title || item.trackName || item.title}
                    />
                  )}
                  {hasArtwork && !hasValidPoster && !hasThumbnail && (
                    <img
                      src={item.artworkUrl100}
                      alt={item.Title || item.volumeInfo?.title || item.trackName || item.title}
                    />
                  )}
                  <h3>{truncateText(item.Title || item.volumeInfo?.title || item.trackName || item.title, 19)}</h3>
                  <p>
                    {truncateText(
                      item.Director ||
                      item.volumeInfo?.authors?.join(', ') ||
                      item.artistName ||
                      item.artistDisplayName,
                      25
                    )}
                  </p>
                  {searchType === 'music' && item.previewUrl && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <button
                        className="custom-play-music-button"
                        onClick={() => handlePlayMusic(item.previewUrl)}
                      >
                        ▶︎
                      </button>
                      <button
                        className="custom-stop-music-button"
                        onClick={handleStopMusic}
                      >
                        ||
                      </button>
                    </div>
                  )}
                  <button
                    className="custom-write-review-button"
                    onClick={() => handleAddFavorite(item)}
                  >
                    お気に入りに追加
                  </button>
                </div>
              );
            }).filter(item => item !== null)}
          </div>
        </div>
      )}

      {!searched && filteredFavorites && filteredFavorites.length > 0 && (
        <div className="custom-results-list">
          <h4>{showOwnFavoritesText ? "　自分のお気に入り" : "　皆のお気に入り"}</h4>

          <div className="filter-buttons">
      {/* 本ページへのリンク */}
      <Link to="/booksearch">
        <button><FaBook /> 本</button>
      </Link>

      {/* 音楽ページへのリンク */}
      <Link to="/musicsearch">
        <button><IoMdMusicalNote /> 音楽</button>
      </Link>

      {/* 映画ページへのリンク */}
      <Link to="/moviesearch">
        <button><MdMovieCreation /> 映画</button>
      </Link>
    </div>
          <div className="custom-results-list-content">
            {filteredFavorites.map((favorite) => (
              <div
                key={favorite.id}
                className="custom-result-item"
                onClick={() => handleItemClick(favorite.docid)}
              >
                {(favorite.Poster && favorite.Poster !== 'N/A') && (
                  <img
                    src={favorite.Poster}
                    alt={favorite.Title || favorite.volumeInfo?.title || favorite.trackName || favorite.title}
                  />
                )}
                {(!favorite.Poster || favorite.Poster === 'N/A') && favorite.volumeInfo?.imageLinks?.thumbnail && (
                  <img
                    src={favorite.volumeInfo.imageLinks.thumbnail}
                    alt={favorite.Title || favorite.volumeInfo?.title || favorite.trackName || favorite.title}
                  />
                )}
                {(!favorite.Poster || favorite.Poster === 'N/A') && !favorite.volumeInfo?.imageLinks?.thumbnail && favorite.artworkUrl100 && (
                  <img
                    src={favorite.artworkUrl100}
                    alt={favorite.Title || favorite.volumeInfo?.title || favorite.trackName || favorite.title}
                  />
                )}
                <h3>{truncateText(favorite.Title || favorite.volumeInfo?.title || favorite.trackName || favorite.title, 19)}</h3>
                <p>{truncateText(favorite.volumeInfo?.authors?.join(', ') || favorite.artistName || favorite.Director, 25)}</p>
                {favorite.previewUrl && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button
                      className="custom-play-music-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePlayMusic(favorite.previewUrl);
                      }}
                    >
                      ▶︎
                    </button>
                    <button
                      className="custom-stop-music-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleStopMusic();
                      }}
                    >
                      ||
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

<div className="my-favotites-works">
      <Link to="/mybooks">
        <button className="my-favotites-works-button other-approve">
          <IoIosStar />
        </button>
      </Link>
    </div>
      <MenuBar />
    </div>
  );
};

export default SearchPage;
