import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth } from '../../firebaseConfig';
import { collection, addDoc, doc, setDoc, deleteDoc, onSnapshot, getDocs, query, getDoc } from 'firebase/firestore';
import '../../styles/CallComponent.css';
import { FaPhone } from "react-icons/fa";
import { FaPhoneSlash } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";

const CallComponent = () => {
  const { chatId } = useParams();
  const [searchParams] = useSearchParams();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const pcRef = useRef(null);
  const localAudioRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const [localStream, setLocalStream] = useState(null);
  const [callStarted, setCallStarted] = useState(false);
  const [isCaller, setIsCaller] = useState(false);
  const [iceCandidates, setIceCandidates] = useState([]);
  const [incomingOffer, setIncomingOffer] = useState(null);
  const [recipientInfo, setRecipientInfo] = useState({ name: '', photoURL: '' });
  const [isSelfVerified, setIsSelfVerified] = useState(false);
  const [verificationChecked, setVerificationChecked] = useState(false);
  const isAnswered = searchParams.get('answered') === 'true';

  const checkAgeVerification = async (uid) => {
    const ageVerificationDoc = await getDoc(doc(db, 'age_verification_checked', uid));
    return ageVerificationDoc.exists();
  };

  const initWebRTC = async () => {
    try {
      const servers = {
        iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
      };

      pcRef.current = new RTCPeerConnection(servers);

      pcRef.current.onicecandidate = (event) => {
        if (event.candidate) {
          const candidate = event.candidate.toJSON();
          addDoc(collection(db, `chats/${chatId}/iceCandidates`), candidate);
        }
      };

      pcRef.current.ontrack = (event) => {
        if (remoteAudioRef.current) {
          remoteAudioRef.current.srcObject = event.streams[0];
        }
      };

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => {
        pcRef.current.addTrack(track, stream);
      });
      localAudioRef.current.srcObject = stream;
      setLocalStream(stream);

    } catch (error) {
      console.error('Error initializing WebRTC:', error);
    }
  };

  const handleAnswer = async (offer) => {
    try {
      await pcRef.current.setRemoteDescription(new RTCSessionDescription(offer));
      const answer = await pcRef.current.createAnswer();
      await pcRef.current.setLocalDescription(answer);
      await setDoc(doc(db, `chats/${chatId}/answers/remoteAnswer`), { ...answer, uid: user.uid });
      setCallStarted(true);

      iceCandidates.forEach(async (candidate) => {
        try {
          await pcRef.current.addIceCandidate(candidate);
        } catch (e) {
          console.error('Error adding buffered ICE candidate:', e);
        }
      });
      setIceCandidates([]);
    } catch (error) {
      console.error('Error handling answer:', error);
    }
  };

  const resetCall = async () => {
    if (pcRef.current) {
      pcRef.current.close();
      pcRef.current = null;
    }

    if (localStream) {
      localStream.getTracks().forEach(track => {
        track.stop();
      });
      setLocalStream(null);
      console.log('Local audio tracks stopped and stream removed');
    }

    if (remoteAudioRef.current && remoteAudioRef.current.srcObject) {
      remoteAudioRef.current.srcObject.getTracks().forEach(track => {
        track.stop();
      });
      remoteAudioRef.current.srcObject = null;
      console.log('Remote audio tracks stopped and stream removed');
    }

    setCallStarted(false);
    setIsCaller(false);
    setIncomingOffer(null);

    try {
      await deleteDoc(doc(db, `chats/${chatId}/offers/remoteOffer`));
      await deleteDoc(doc(db, `chats/${chatId}/answers/remoteAnswer`));
      console.log('Firestore offers and answers deleted');

      const iceCandidatesQuery = query(collection(db, `chats/${chatId}/iceCandidates`));
      const querySnapshot = await getDocs(iceCandidatesQuery);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
      console.log('Firestore ICE candidates deleted');
    } catch (error) {
      console.error('Error deleting Firestore documents:', error);
    }

    console.log('Call reset and mic turned off');

    await initWebRTC();
  };

  const fetchRecipientInfo = async () => {
    try {
      const chatDoc = await getDoc(doc(db, 'chats', chatId));
      if (chatDoc.exists()) {
        const participants = chatDoc.data().participants;
        const recipientUid = participants.find((participant) => participant !== user.uid);
        if (recipientUid) {
          const recipientDoc = await getDoc(doc(db, 'members', recipientUid));
          if (recipientDoc.exists()) {
            setRecipientInfo(recipientDoc.data());
          }
        }
      }
    } catch (error) {
      console.error('Error fetching recipient info:', error);
    }
  };

  useEffect(() => {
    if (!chatId || !user) {
      console.error('chatId or user is missing');
      return;
    }

    const checkVerificationAndFetchInfo = async () => {
      const selfVerified = await checkAgeVerification(user.uid);
      setIsSelfVerified(selfVerified);
      setVerificationChecked(true);

      if (selfVerified) {
        fetchRecipientInfo();
        initWebRTC();
      }
    };

    checkVerificationAndFetchInfo();

    const unsubscribeOffer = onSnapshot(doc(db, `chats/${chatId}/offers/remoteOffer`), async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const offer = docSnapshot.data();
        if (offer.uid !== user.uid) {
          console.log('Received offer:', offer);
          setIncomingOffer(offer);
        }
      } else {
        console.log('Offer document deleted, allowing startCall again');
        setCallStarted(false);
      }
    });

    const unsubscribeAnswer = onSnapshot(doc(db, `chats/${chatId}/answers/remoteAnswer`), async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const answer = docSnapshot.data();
        if (answer.uid !== user.uid) {
          console.log('Received answer:', answer);
          try {
            await pcRef.current.setRemoteDescription(new RTCSessionDescription(answer));
            setCallStarted(true);

            iceCandidates.forEach(async (candidate) => {
              try {
                await pcRef.current.addIceCandidate(candidate);
              } catch (e) {
                console.error('Error adding buffered ICE candidate:', e);
              }
            });
            setIceCandidates([]);
          } catch (error) {
            console.error('Error handling answer:', error);
          }
        }
      }
    });

    const unsubscribeIceCandidates = onSnapshot(collection(db, `chats/${chatId}/iceCandidates`), (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === 'added') {
          const candidate = new RTCIceCandidate(change.doc.data());
          if (pcRef.current && pcRef.current.remoteDescription) {
            try {
              await pcRef.current.addIceCandidate(candidate);
            } catch (e) {
              console.error('Error adding received ICE candidate:', e);
            }
          } else {
            setIceCandidates((prev) => [...prev, candidate]);
          }
        }
      });
    });

    const handleBeforeUnload = async (event) => {
      event.preventDefault();
      await resetCall();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      resetCall();
      unsubscribeOffer();
      unsubscribeAnswer();
      unsubscribeIceCandidates();
    };
  }, [chatId, user]);

  const startCall = async () => {
    if (!pcRef.current) return;

    console.log('Start Call - Self Verified:', isSelfVerified); // デバッグ情報

    if (!isSelfVerified) {
      console.error('User has not completed age verification');
      return;
    }

    setIsCaller(true);

    const offer = await pcRef.current.createOffer();
    await pcRef.current.setLocalDescription(offer);
    await setDoc(doc(db, `chats/${chatId}/offers/remoteOffer`), { ...offer, uid: user.uid });

    console.log('Offer sent:', offer);
    setCallStarted(true);
  };

  const answerCall = async () => {
    if (!isSelfVerified) {
      console.error('User has not completed age verification');
      return;
    }

    if (incomingOffer) {
      await handleAnswer(incomingOffer);
      setIncomingOffer(null);
    }
  };

  if (!verificationChecked) {
    return <div>Loading...</div>;
  }

  if (!isSelfVerified) {
    return <div>User has not completed age verification.</div>;
  }

  return (
    <div className="call-page">
      <div className="recipient-info">
        {/* photoURL が空の場合にはデフォルトの画像を表示 */}
        <img 
          src={recipientInfo.photoURL || '/images/default.png'} 
          alt={recipientInfo.name} 
          className="recipient-avatar" 
        />
        <h5>※通話するにはユーザー２人が<br></br>このページにいる必要があります。</h5>
        <span>{recipientInfo.name}</span>
      </div>
      <audio ref={localAudioRef} autoPlay muted />
      <audio ref={remoteAudioRef} autoPlay />
      <div className="audio-controls">
        <button onClick={startCall} disabled={callStarted}><FaPhone /></button>
        <button onClick={resetCall} className="reset-button"><FaPhoneSlash /></button>
        {incomingOffer && <button onClick={answerCall} className="answer-button"><FaPhoneVolume /></button>}
      </div>
    </div>
  );
  
};

export default CallComponent;
