import { useEffect } from 'react';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCniQlrkj-bG1iO3_p45GCZgtpx-XwY7KA",
  authDomain: "macth-app.firebaseapp.com",
  projectId: "macth-app",
  storageBucket: "macth-app.appspot.com",
  messagingSenderId: "1049338814763",
  appId: "1:1049338814763:web:f305a26a2cc14fced8656e"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const SaveToken = () => {
  useEffect(() => {
    const messaging = getMessaging(app);
    const auth = getAuth();

    auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const tokenDocRef = doc(db, "tokens", user.uid);
          const tokenDocSnap = await getDoc(tokenDocRef);

          if (tokenDocSnap.exists()) {
            const tokenData = tokenDocSnap.data();

            if (!('approve' in tokenData)) {
              // approveフィールドが存在しない場合、trueを追加
              await setDoc(tokenDocRef, { approve: true }, { merge: true });
            }

            if (tokenData.approve === false) {
              // approveがfalseならtokenをnullにする
              await setDoc(tokenDocRef, { token: null }, { merge: true });
            } else if (tokenData.approve === true) {
              // approveがtrueならtokenを取得して保存
              const currentToken = await getToken(messaging, { vapidKey: 'BPYhD9_2Vs01kJVna_pWg3TIqkxrAOkblgw5s9ybsgOXqfnQdDkefnsqGiODnmEnwve86O00q02a-QaLz116YRQ' });
              if (currentToken) {
                await setDoc(tokenDocRef, { token: currentToken }, { merge: true });
              }
            }
          } else {
            // ドキュメントが存在しない場合、新しく作成
            const currentToken = await getToken(messaging, { vapidKey: 'BPYhD9_2Vs01kJVna_pWg3TIqkxrAOkblgw5s9ybsgOXqfnQdDkefnsqGiODnmEnwve86O00q02a-QaLz116YRQ' });
            if (currentToken) {
              await setDoc(tokenDocRef, { uid: user.uid, approve: true, token: currentToken });
            }
          }
        } catch (error) {
          console.error('Error fetching or saving token:', error.message);
        }

        onMessage(messaging, (payload) => {
          // プッシュ通知の処理
        });
      }
    });
  }, []);

  return null;
};

export default SaveToken;
