import React, { useEffect, useState, useRef } from 'react';
import { db, auth, storage } from '../firebaseConfig';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, addDoc, query, orderBy, onSnapshot, updateDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../styles/Chat.css';
import { FiMoreHorizontal } from 'react-icons/fi';
import { FaPaperclip, FaPaperPlane } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import CallPopup from '../components/CallPopup';
import imageCompression from 'browser-image-compression';

const Chat = () => {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [chat, setChat] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [participants, setParticipants] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [editingMessage, setEditingMessage] = useState(null);
  const [expandedImageUrl, setExpandedImageUrl] = useState(null);
  const [headerMenuOpen, setHeaderMenuOpen] = useState(false);
  const [isAgeVerified, setIsAgeVerified] = useState(false);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const [incomingCall, setIncomingCall] = useState(null); // 通話着信を管理する状態

  const MAX_MESSAGE_LENGTH = 500;  // メッセージの最大文字数

  const sanitizeInput = (input) => {
    const div = document.createElement('div');
    div.textContent = input;
    return div.innerHTML;
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const initializeChat = async () => {
      if (chatId && user) {
        try {
          await checkAgeVerification();
          await fetchChat();
          const unsubscribeMessages = await fetchMessages();
          const unsubscribeOffer = onSnapshot(doc(db, `chats/${chatId}/offers/remoteOffer`), async (docSnapshot) => {
            const offer = docSnapshot.data();
            if (docSnapshot.exists() && offer.uid !== user.uid) {
              setIncomingCall({ callerName: participants[offer.uid]?.name || 'Unknown', offer });
            }
          });

          return () => {
            if (unsubscribeMessages) {
              unsubscribeMessages();
            }
            if (unsubscribeOffer) {
              unsubscribeOffer();
            }
          };
        } catch (error) {
          console.error('Error initializing chat:', error.message);
        }
      }
    };

    initializeChat();
  }, [chatId, user]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const checkAgeVerification = async () => {
    const ageVerificationRef = doc(db, 'age_verification_checked', user.uid);
    try {
      const ageVerificationSnap = await getDoc(ageVerificationRef);
      setIsAgeVerified(ageVerificationSnap.exists());
    } catch (error) {
      console.error('Error checking age verification:', error.message);
    }
  };

  const fetchChat = async () => {
    try {
      const docRef = doc(db, 'chats', chatId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setChat(docSnap.data());
        const participantsData = {};
        await Promise.all(docSnap.data().participants.map(async (participantId) => {
          const participantRef = doc(db, 'members', participantId);
          const participantSnap = await getDoc(participantRef);
          if (participantSnap.exists()) {
            participantsData[participantId] = participantSnap.data();
          }
        }));
        setParticipants(participantsData);
      } else {
        console.error('Chat not found');
      }
    } catch (error) {
      console.error('Error fetching chat:', error.message);
    }
  };

  const fetchMessages = async () => {
    try {
      const q = query(collection(db, 'chats', chatId, 'messages'), orderBy('createdAt', 'asc'));
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const messageDocs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setMessages(messageDocs);

        // 未読メッセージを既読に更新
        await Promise.all(messageDocs.map(async (message) => {
          if (message.sender !== user.uid && !message.read) {
            const messageRef = doc(db, 'chats', chatId, 'messages', message.id);
            await updateDoc(messageRef, { read: true });
          }
        }));

        // 初回レンダリング時に最下部にスクロール
        scrollToBottom();
      });
      return unsubscribe;
    } catch (error) {
      console.error('Error fetching messages:', error.message);
      return null;
    }
  };

  const handleAnswerCall = async () => {
    if (incomingCall && incomingCall.offer) {
      try {
        const pc = new RTCPeerConnection();
        await pc.setRemoteDescription(new RTCSessionDescription(incomingCall.offer));
        const answer = await pc.createAnswer();
        await pc.setLocalDescription(answer);
        await setDoc(doc(db, `chats/${chatId}/answers/remoteAnswer`), { ...answer, uid: user.uid });
        setIncomingCall(null);
        navigate(`/call/${chatId}?answered=true`);
      } catch (error) {
        console.error('Error answering call:', error.message);
      }
    }
  };

  const handleRejectCall = () => {
    setIncomingCall(null);
  };

  const sendMessage = async () => {
    if (!isAgeVerified) return; // 年齢確認が済んでいない場合はメッセージを送れない
    if ((newMessage.trim() === '' && !selectedImage) || !user) return;

    if (newMessage.length > MAX_MESSAGE_LENGTH) {
      console.error('Message too long');
      return;
    }

    const sanitizedMessage = sanitizeInput(newMessage);

    let imageUrl = null;
    if (selectedImage) {
      const storageRef = ref(storage, `chat_images/${chatId}/${Date.now()}_${selectedImage.name}`);
      try {
        await uploadBytes(storageRef, selectedImage);
        imageUrl = await getDownloadURL(storageRef);
      } catch (error) {
        console.error('Error uploading image:', error.message);
      }
    }

    if (editingMessage) {
      try {
        const messageRef = doc(db, 'chats', chatId, 'messages', editingMessage.id);
        await updateDoc(messageRef, {
          text: sanitizedMessage,
          imageUrl: imageUrl || editingMessage.imageUrl,
          editedAt: new Date(),
        });
        setEditingMessage(null);
      } catch (error) {
        console.error('Error editing message:', error.message);
      }
    } else {
      try {
        await addDoc(collection(db, 'chats', chatId, 'messages'), {
          text: sanitizedMessage,
          imageUrl,
          sender: user.uid,
          createdAt: new Date(),
          read: false,
          replyTo: null  // 返信機能を削除
        });
      } catch (error) {
        console.error('Error sending message:', error.message);
      }
    }

    setNewMessage('');
    setSelectedImage(null);
  };

  const handleImageChange = async (e) => {
    if (!isAgeVerified) return; // 年齢確認が済んでいない場合は画像を送れない
    if (e.target.files[0]) {
      const file = e.target.files[0];
  
      // まずプレビュー用に選択された画像を設定
      setSelectedImage(file);
  
      // 画像を圧縮
      const options = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
  
      try {
        const compressedFile = await imageCompression(file, options);
        setSelectedImage(compressedFile);  // 圧縮された画像を再度設定
      } catch (error) {
        console.error('Error compressing image:', error.message);
      }
    }
  };
  

  const toggleMenu = (messageId) => {
    setOpenMenuId(openMenuId === messageId ? null : messageId);
  };

  const handleEditMessage = (message) => {
    setNewMessage(message.text);
    setEditingMessage(message);
    setOpenMenuId(null);
  };

  const handleDeleteMessage = async (message) => {
    try {
      if (message.imageUrl) {
        const imageRef = ref(storage, message.imageUrl);
        await deleteObject(imageRef);
      }
      const messageRef = doc(db, 'chats', chatId, 'messages', message.id);
      await deleteDoc(messageRef);
      setOpenMenuId(null);
    } catch (error) {
      console.error('Error deleting message:', error.message);
    }
  };

  const handleTextareaChange = (e) => {
    if (e.target.value.length <= MAX_MESSAGE_LENGTH) {
      setNewMessage(e.target.value);
    }
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleImageClick = (imageUrl) => {
    setExpandedImageUrl(imageUrl);
  };

  const handleCloseExpandedImage = () => {
    setExpandedImageUrl(null);
  };

  const handleViewProfile = (participantId) => {
    navigate(`/other_member_profile/${participantId}`);
  };

  const handleHideChat = async () => {
    try {
      const chatRef = doc(db, 'chats', chatId);
      await updateDoc(chatRef, { notdisplay: user.uid });
      navigate('/inbox');
    } catch (error) {
      console.error('Error hiding chat:', error.message);
    }
  };

  if (!chat || !user) return <div>Loading chat...</div>;

  return (
    <div className="chat_container">
      <div className="chat_header">
        {chat && chat.participants.filter(participant => participant !== user.uid).map(participant => participants[participant]?.name).join(', ')}
        <div className="header_menu" onClick={() => setHeaderMenuOpen(!headerMenuOpen)}>
        <Link to={`/call/${chatId}`} className="call-link"><FaPhone /></Link> {/* 通話ページへのリンク */}
          <FiMoreHorizontal />
          {headerMenuOpen && (
            <div className="header_menu-dropdown">
              {chat.participants.filter(participant => participant !== user.uid).map(participant => (
                <div key={participant}>
                  <div className="header_menu-item" onClick={() => handleViewProfile(participant)}>相手のプロフィール</div>
                  <div className="header_menu-item" onClick={handleHideChat}>非表示</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="chat_messages">
        {messages.map(message => (
          <div key={message.id} className={`chat_message ${message.sender === user.uid ? 'me' : 'other'}`}>
            {message.sender !== user.uid && (
              <img src={participants[message.sender]?.photoURL || '/images/default.png'} alt="Profile" className="chat_avatar" onClick={() => handleViewProfile(message.sender)} />
            )}
            <div className="chat_message-content">
              {message.text && <span className="chat_message-text" style={{ whiteSpace: 'pre-wrap' }}>{message.text}</span>}<br></br>
              {message.imageUrl && <img src={message.imageUrl} alt="sent" className="chat_message-image" onClick={() => handleImageClick(message.imageUrl)} />}
              {message.editedAt && <span className="chat_message-edited">(edited)</span>}
              <div className="chat_message-options" onClick={() => toggleMenu(message.id)}>
                <FiMoreHorizontal />
                <div className={`chat_message-menu ${openMenuId === message.id ? 'open' : ''}`}>
                  {message.sender === user.uid ? (
                    <>
                      <div className="chat_message-menu-item" onClick={() => handleEditMessage(message)}>編集</div>
                      <div className="chat_message-menu-item" onClick={() => handleDeleteMessage(message)}>取り消し</div>
                    </>
                  ) : null}
                </div>
              </div>
              <span className="chat_message-time">
                {new Date(message.createdAt.seconds * 1000).toLocaleString('ja-JP', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef}></div>
      </div>
      {isAgeVerified && (
        <div className="chat_input-container">
          {selectedImage && (
            <div className="chat_image-preview">
              <img src={URL.createObjectURL(selectedImage)} alt="Preview" />
              <button onClick={() => setSelectedImage(null)}>Remove</button>
            </div>
          )}
          <div className="chat_input-wrapper">
            <textarea
              ref={textareaRef}
              value={newMessage}
              onChange={handleTextareaChange}
              placeholder="新しいメッセージの入力"
              className="chat_input"
              rows={1}
              maxLength={MAX_MESSAGE_LENGTH}
              style={{ resize: 'none', overflowY: 'auto' }}
            />
            <label htmlFor="image-upload" className="chat_image-upload">
              <FaPaperclip />
              <input
                id="image-upload"
                type="file"
                accept="image/jpeg,image/jpg,image/png"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
            </label>
            <button onClick={sendMessage} className="chat_send-button"><FaPaperPlane /></button>
          </div>
        </div>
      )}
      {expandedImageUrl && (
        <div className="expanded_image_container" onClick={handleCloseExpandedImage}>
          <img src={expandedImageUrl} alt="Expanded" className="expanded_image" />
        </div>
      )}
      {incomingCall && (
        <CallPopup
          callerName={incomingCall.callerName}
          onAnswer={handleAnswerCall}
          onReject={handleRejectCall}
        />
      )}
    </div>
  );
};

export default Chat;
