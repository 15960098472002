import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { db, auth } from '../../firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../../styles/NotificationDetail.css';

const NotificationDetail = () => {
  const { id } = useParams();
  const [user] = useAuthState(auth);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        if (user) {
          const docRef = doc(db, `members/${user.uid}/notifications`, id);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setNotification(docSnap.data());
            // マークを既読にする
            await setDoc(docRef, { read: true }, { merge: true });
          } else {
            console.error('No such document!');
          }
        }
      } catch (error) {
        console.error('Error fetching notification:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotification();
  }, [id, user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!notification) {
    return <div>No such notification found.</div>;
  }

  return (
    <div className="notification-detail-container">
      <div className="header">
        <Link to="/settings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">お知らせ</p>
      </div>
      <h2>{notification.title}</h2>
      <span className="notification-date">
        {new Date(notification.createdAt.toDate()).toLocaleDateString('ja-JP', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      </span>
      <p style={{ whiteSpace: 'pre-wrap' }}>{notification.content}</p>
    </div>
  );
};

export default NotificationDetail;
