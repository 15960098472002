import React from 'react';
import ReactDOM from 'react-dom/client'; // ここを変更
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import SaveToken from './components/SaveToken';  // インポートパスが正しいことを確認
import UpdateUseDate from './components/UpdateUseDate';


const root = ReactDOM.createRoot(document.getElementById('root')); // ここを変更
root.render(
  <React.StrictMode>
    <Router>
      <App />
      <SaveToken />
      <UpdateUseDate />
    </Router>
  </React.StrictMode>
);

// 非同期でサービスワーカーを登録する
const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      // 新しいサービスワーカーを登録
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      console.log('Service Worker registered with scope:', registration.scope);
    } catch (error) {
      console.error('Service Worker registration failed:', error);
    }
  }
};

// メインスレッドでブロックを避けるために登録を遅延させる
window.addEventListener('load', () => {
  registerServiceWorker();
});


