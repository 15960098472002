import { useEffect } from 'react';
import { useCall } from './CallContext';
import { db } from '../firebaseConfig';
import { onSnapshot, doc } from 'firebase/firestore';

const CallListener = ({ userId }) => {
  const { incomingCall } = useCall();

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'calls', userId), (doc) => {
      if (doc.exists()) {
        const callData = doc.data();
        if (callData.isCalling) {
          incomingCall({ callerName: callData.callerName, callerId: callData.callerId });
        }
      }
    });

    return () => unsubscribe();
  }, [userId, incomingCall]);

  return null;
};

export default CallListener;
