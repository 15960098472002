import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, addDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import '../styles/IineNotDisplay.css';
import '../styles/Iine.css';
import { BiSolidLike } from "react-icons/bi";
import MenuBar from '../components/MenuBar'; // MenuBarをインポート
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../firebaseConfig'; // Firebaseアプリをインポート

const IineNotDisplay = () => {
  const [user] = useAuthState(auth);
  const [rejectedUsers, setRejectedUsers] = useState([]);

  useEffect(() => {
    const fetchRejectedUsers = async () => {
      if (user) {
        try {
          const functions = getFunctions(app, 'asia-northeast2');
          const calculateAge = httpsCallable(functions, 'calculateAge');

          const likesQuery = query(collection(db, 'likes'), where('to', '==', user.uid), where('status', '==', 'rejected'));
          const likesSnapshot = await getDocs(likesQuery);

          const userIds = likesSnapshot.docs.map(doc => doc.data().from);
          const userDocsPromises = userIds.map(userId => getDoc(doc(db, 'members', userId)));

          const userDocs = await Promise.all(userDocsPromises);

          const usersDataPromises = userDocs.map(async (doc) => {
            const userData = doc.data();
            const ageResult = await calculateAge({ userId: doc.id });
            const age = ageResult.data.age;

            return { id: doc.id, ...userData, age };
          });

          const usersData = await Promise.all(usersDataPromises);
          setRejectedUsers(usersData);
        } catch (error) {
          console.error('Error fetching rejected users:', error);
        }
      }
    };

    fetchRejectedUsers();
  }, [user]);

  const handleApprove = async (rejectedUser) => {
    if (user) {
      try {
        const likeQuery = query(
          collection(db, 'likes'), 
          where('from', '==', rejectedUser.id), 
          where('to', '==', user.uid), 
          where('status', '==', 'rejected')
        );
        const likeSnapshot = await getDocs(likeQuery);
  
        if (!likeSnapshot.empty) {
          const likeDoc = likeSnapshot.docs[0];
          await updateDoc(likeDoc.ref, {
            status: 'approved'
          });
  
          // チャットドキュメントを作成
          await addDoc(collection(db, 'chats'), {
            participants: [user.uid, rejectedUser.id],
            createdAt: new Date()
          });
  
          alert('Like approved.');
          // リストを更新するために再取得
          const updatedRejectedUsers = rejectedUsers.filter(u => u.id !== rejectedUser.id);
          setRejectedUsers(updatedRejectedUsers);
        }
      } catch (error) {
        console.error('Error approving like:', error);
      }
    }
  };

  return (
    <div className="iine-container">
      <div className="settings-header">
        <p className="iine-skip">いいねをスキップしたユーザー</p>
      </div>
      <div className="likes-list">
        {rejectedUsers.map(rejectedUser => (
          <div key={rejectedUser.id} className="like-card">
            <Link to={`/other_member_profile/${rejectedUser.id}`} className="like-card-link">
              <img src={rejectedUser.photoURL || '/images/default.png'} alt="Profile" className="like-card-image" onError={(e) => e.target.src = '/images/default.png'} />
              <div className="like-card-info">
                <h3>{rejectedUser.name || 'Unknown User'}</h3>
                <p>{rejectedUser.age || '--'}歳 {rejectedUser.location || '--'}</p>
              </div>
            </Link>
            <div className="like-card-buttons">
              <button onClick={() => handleApprove(rejectedUser)} className="like-card-button iine-approve"><BiSolidLike /></button>
            </div>
          </div>
        ))}
      </div>
      <MenuBar />
    </div>
  );
};

export default IineNotDisplay;
