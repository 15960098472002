// /pages/LandingPage.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/LandingPage.css'; // CSSファイルをインポート
import { HiAcademicCap } from "react-icons/hi2";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="all-container">
      <div className="header-container">
  <div className="logo">
    <img src="/images/default1.png" alt="Logo" />
    院生マッチ
  </div>
  <button className="button-b" onClick={() => navigate('/login')}>ログイン</button>
</div>
<img src='/images/aaa.png' className='lp-top-img'></img>
    <p className="description-text">
          大学院生（+卒業生）が、恋人やアカデミックな話題を楽しめる友人と出会える機会を提供します
          </p>
          <div className="button-container">
        <button className="button-a" onClick={() => navigate('/signup')}>はじめてみる</button>
        </div>

        <h3 className="price-title"
              style={{
                margin: "50px auto 30px auto"
              }}>特徴</h3>
        <div className="about-container">
      <div className="feature">
      <img src="/images/insei.jpeg" alt="Feature 2" className="feature-icon" />
        <p className="feature-text">大学院生と出会える</p>
      </div>
      <div className="feature">
        <img src="/images/people.jpeg" alt="Feature 2" className="feature-icon" />
        <p className="feature-text">累計登録者数3000人以上</p>
      </div>
      <div className="feature">
        <img src="/images/kenkyu_tag.jpeg" alt="Feature 3" className="feature-icon" />
        <p className="feature-text">ニッチな研究タグを設定可能</p>
      </div>
      <div className="feature">
        <img src="/images/savemoney.jpeg" alt="Feature 4" className="feature-icon" />
        <p className="feature-text">無料<br></br>※将来的に有料化予定</p>
      </div>
      <div className="feature">
        <img src="/images/devices.jpeg" alt="Feature 4" className="feature-icon" />
        <p className="feature-text">ブラウザ上でもスマホアプリとしても使用可</p>
      </div>
      <div className="feature">
        <img src="/images/phone.jpeg" alt="Feature 4" className="feature-icon" />
        <p className="feature-text">リアルタイムチャットや通話機能など必要な機能は全て搭載</p>
      </div>
    </div>

    <div className="recommendation-slider">
      <h3 className="recommendation-title">こんな人におすすめ</h3>
      <div className="slider">
        <div className="slide">
          <img src="/images/couple.jpeg" alt="Case 1" className="slide-image" />
          <h4 className="slide-title">① 恋人や友人が欲しい大学院生</h4>
          <p className="slide-description">
            「とりあえず恋人が欲しい人」から
            「今の内に将来の結婚相手を見つけたい」と考える人までそのキッカケを提供します。
          </p>
        </div>
        <div className="slide">
        <img src="/images/syakaizin.jpeg" alt="Case 1" className="slide-image" />
          <h4 className="slide-title">② ラフにアカデミックな会話をしたい人</h4>
          <p className="slide-description">
          専門分野でのネットワークを広げ、自身の知見を広げたい方に最適です。
          </p>
        </div>
      </div>
      
    </div><br></br><br></br><br></br><br></br><br></br>
          <div className="button-container">
        <button className="button-a" onClick={() => navigate('/signup')}>はじめてみる</button>
        </div>


    <footer className="footer">
    <p className="footer-text">問い合わせ先：<br></br>e-mail：info@insei-match.net</p>   
    <p className="footer-text">インターネット異性紹介事業届出済み：<br></br>受理番号：62210002-000</p>
    <p className="footer-text">電気通信事業届出済み：<br></br>届出番号：E-02-04491</p>
<p className="footer-text">
  <Link to="/terms-of-service">利用規約</Link>
</p>
<p className="footer-text">
  <Link to="/privacy-policy">プライバシーポリシー</Link>
</p>
        <p className="footer-text">&copy; 2024 院生マッチ. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
