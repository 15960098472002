import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth, messaging, getToken } from '../../firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import '../../styles/SettingPush.css';

const SettingPush = () => {
  const [user, loading, error] = useAuthState(auth);
  const [isPushEnabled, setIsPushEnabled] = useState(false);

  useEffect(() => {
    const fetchPushSettings = async () => {
      if (user) {
        const docRef = doc(db, 'push_settings', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsPushEnabled(docSnap.data().enabled);
        }
      }
    };

    if (user) {
      fetchPushSettings();
    }
  }, [user]);

  const handleToggle = async () => {
    const newState = !isPushEnabled;
    setIsPushEnabled(newState);

    if (user) {
      const docRef = doc(db, 'push_settings', user.uid);
      await setDoc(docRef, { enabled: newState });

      if (newState) {
        await enablePushNotifications();
      } else {
        await disablePushNotifications();
      }
    }
  };

  const handleEnablePush = async () => {
    setIsPushEnabled(true);

    if (user) {
      const docRef = doc(db, 'push_settings', user.uid);
      await setDoc(docRef, { enabled: true });
      await enablePushNotifications();
    }
  };

  const enablePushNotifications = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        if (user) {
          await setDoc(doc(db, 'tokens', user.uid), { approve: true }, { merge: true });
        }
      }
    } catch (error) {
      console.error('Error enabling push notifications:', error);
    }
  };

  const disablePushNotifications = async () => {
    if (user) {
      await setDoc(doc(db, 'tokens', user.uid), { approve: false }, { merge: true });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="setting-push-container">
      <div className="header">
        <Link to="/accountsettings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">プッシュ通知設定</p>
      </div>
      <div className="toggle-container">
        <br /><br /><br /><br />
        <span className="toggle-label">プッシュ通知</span>
        <label className="switch-a">
          <input type="checkbox" checked={isPushEnabled} onChange={handleToggle} />
          <span className="slider-a round"></span>
        </label>
      </div>
      <p className="description">
        院生マッチを使っていないときでも相手からのメッセージを受け取れるように、プッシュ通知をオンにしましょう。いつでもオフにできます。
      </p>
      <div className="enable-container">
        <h3>プッシュ通知をオンにする</h3>
        <p>これらの通知を受け取るには、プッシュ通知をオンにしてください。この通知は院生マッチを使っていないときでも届きます。また、いつでもオフにできます。</p>
        <button className="enable-button" onClick={handleEnablePush}>オンにする</button>
      </div>
    </div>
  );
};

export default SettingPush;
