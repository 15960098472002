import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { auth, db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import '../../styles/PaidServices.css';

const stripePromise = loadStripe('pk_live_51J2BcKAq7sm3T386aoWY4c2mRoxcLn0ySoCmLkstoNl0BTopyGKfrsywBFS1XRsPv6dte2tBqZX8ZWPqr9oX8bH200PlX1Mdmj');

const PaidServices = () => {
  const [selectedPlan, setSelectedPlan] = useState('');
  const [success, setSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const [isPaidUser, setIsPaidUser] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const payCheckRef = doc(db, 'pay_checked', user.uid);
      const payCheckSnap = await getDoc(payCheckRef);

      if (payCheckSnap.exists()) {
        const payCheckData = payCheckSnap.data();
        const expiration = payCheckData.expiration.toDate();

        // expirationの次の日の0時0分までを計算
        const expirationEndOfDay = new Date(
          expiration.getFullYear(),
          expiration.getMonth(),
          expiration.getDate() + 1, // 翌日の0:00:00
          0, 0, 0
        );

        // 現在の日時がexpirationEndOfDayより前であれば有料プラン
        if (new Date() < expirationEndOfDay) {
          setIsPaidUser(true);
          setExpirationDate(expirationEndOfDay); // 有効期限を保存
        }
      }
    };

    checkSubscriptionStatus();
  }, []);

  const handlePlanSelect = (plan) => setSelectedPlan(plan);

  const handleSubscribe = async () => {
    const stripe = await stripePromise;
    const user = auth.currentUser;

    if (!user) {
      setPaymentError('ユーザーが認証されていません。');
      return;
    }

    const priceId = selectedPlan === 'premium' 
      ? 'price_1PsQadAq7sm3T386275CPqGb'  // 社会人向けプラン 980円
      : 'price_1PsQa6Aq7sm3T386ST6uRf5h';  // 大学院生向けプラン 480円

    try {
      const response = await fetch('https://asia-northeast2-macth-app.cloudfunctions.net/createStripeCheckoutSession', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ priceId, userId: user.uid }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const session = await response.json();
      const result = await stripe.redirectToCheckout({ sessionId: session.id });

      if (result.error) setPaymentError(result.error.message);
      else {
        setSuccess(true);
        setPaymentError('');
      }
    } catch (error) {
      setPaymentError('支払いに失敗しました: ' + error.message);
    }
  };

  return (
    <div className="setting-mail-container">
      <div className="header">
        <Link to="/settings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">有料会員</p>
      </div>

      {isPaidUser ? (
        <p>有料プランに加入中です。{expirationDate && `${expirationDate.getFullYear()}年${expirationDate.getMonth() + 1}月${expirationDate.getDate()}日0:00までメッセージを無制限に送信できます.`}</p>
      ) : (
        <>
          <p>無料会員は３通までしかメッセージを送れませんが、有料会員になれば無制限に送ることができます。</p>
          <section className="plan-selection">
            <h3>プランを選択してください:</h3>
            <div className="plan-options">
              <label>
                <input
                  type="radio"
                  name="plan"
                  value="basic"
                  checked={selectedPlan === 'basic'}
                  onChange={() => handlePlanSelect('basic')}
                />
                大学院生向けプラン - 480円/月
              </label>
              <label>
                <input
                  type="radio"
                  name="plan"
                  value="premium"
                  checked={selectedPlan === 'premium'}
                  onChange={() => handlePlanSelect('premium')}
                />
                社会人向けプラン - 980円/月
              </label>
            </div>
          </section>
          <button onClick={handleSubscribe} className="subscribe-button" disabled={!selectedPlan}>
            購入する
          </button>
          <p>※単月購入です。自動的に購入が更新されることはありません。</p>
          <p>※購入した日から31日間有料会員として資格を保ちます。</p>
          <p>※支払いは外部サービス（Stripe）に移管しており、このサイトでクレジットカードに関する情報を預かることはありません。</p>
          <p>※購入後の返金は承りません</p>
        </>
      )}
      {success && <div className="success-message">サブスクライブが完了しました。</div>}
      {paymentError && <div className="error-message">{paymentError}</div>}
    </div>
  );
};

export default PaidServices;
