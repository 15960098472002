import React from 'react';

const TermsOfService = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto', textAlign: 'left'}}>
      <h1>利用規約</h1>
      <p>この規約（以下「本規約」といいます）は、当サービスの運営者が提供する
            院生マッチに関する全てのサービス（以下「本サービス」といいます）の利用に関する条件を、
            本サービスを利用する利用者（以下「利用者」といいます）と運営者との間で定めるものです。
</p>

<h2>第1条（規約への同意）</h2>
      <p>1. 本サービスをご利用になる前に、本規約を良くお読みください。<br></br>

            2.利用者は、本規約の定めに従って本サービスを利用しなければなりません。<br></br>

            3.利用者は、会員登録をしないかぎり本サービスを利用できません。<br></br>

            4.利用者は会員登録をするにあたり、本規約に同意して頂くことが必要であり、会員登録が完了した時点で、本規約を内容とする本サービス利用契約（以下「本契約」といいます）が運営者との間で締結されます。<br></br>
</p>

      
      <h2>第2条（定義）</h2>
      <p>本規約において、次の用語はそれぞれ、以下の通りとします。<br></br><br></br>

1. 高校生...日本国内外の高等学校に在籍している方を指します。<br></br>
2. 大学生...日本国内外の大学に在籍している学部生を指します。<br></br>
3. 大学院生...日本国内外の大学院に在籍している修士課程および博士課程の学生を指します。<br></br>
4. 社会人...大学または大学院を卒業し、企業や組織で働いている方、または自営業を営んでいる方を指します。<br></br>
5. モバイル端末...当サービスを利用する機能を有する携帯電話端末およびタブレット端末を含む、スマートフォンやタブレット等の携帯型デバイスを指します。<br></br>
6. 知的財産権...著作権、特許権、商標権、意匠権、営業秘密その他の知的財産に関する一切の権利を指します。<br></br>
</p>

      
<h2>第3条（当サービスとは）</h2>
      <p>当サービスは、恋人、友人探しを目的として出会いを求めるの大学院生（18歳以上の者。ただし、18歳以上であっても高校生は対象外とします）や大学院の卒業生
            をメインに交流の機会を提供するサービスです。</p>
      
      <h2>第4条（本サービスの提供、無保証、変更および中止）</h2>
      <p>
      1. 運営者は、本サービスの提供を受けることができる利用者を、会員登録の有無、年齢、その他、 運営者が合理的に必要と判断する条件を満たした利用者に限定することができるものとします。

      2.運営者は、運営者が合理的に必要と判断する場合、 あらかじめ利用者に通知することなく、いつでも、本サービスの全部または一部の内容を変更し、また、その提供を中止することができるものとします。 
      運営者は、本規定に基づき当社が行った措置によって利用者に生じた損害について一切の責任を負いません。

      3.運営者は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、 正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、 権利侵害などを含みますが、これらに限りません）がないことを保証しておりません。

      4.運営者は、本サービスが、全てのパソコン、スマートフォン、タブレット端末等およびOSに対応することを保証しておりません。また、当社は、利用者の環境により本サービスを利用できなかったことに基づき利用者に生じた損害について、一切の責任を負いません。
</p>

<h2>第4条（利用資格）</h2>
      <p>
      1. 当サービスの利用および会員登録は、18歳以上（高校生を除く）の方に限られます。これに違反した場合、会員登録は無効となり、アカウントは削除されます。
      運営者は、無効とする措置により利用者に発生した損害について一切の責任を負わないものとします。<br></br>

      2. メッセージ機能は、18歳以上（高校生を除く）であることを証明した会員のみ利用できます。<br></br>

      3. 当サービスの利用により、以下の事項を表明および保証したものとみなされます:<br></br>
   - 本契約を締結し、本規約および運営者が定める条件に従う権利、権限および能力を有していること。<br></br>
   - 重罪で有罪判決を受けたことがなく、また発覚の有無にかかわらず、性的犯罪を犯していないこと。<br></br>

      4.当社は、利用者が下記の事由に相当する場合は、会員登録の拒否また取り消しを行うことが出来るものとします。
      --本規約に違反するおそれがあると当社が合理的に判断した場合<br></br>
      --当社に提供された登録情報や身分証明書の全部又は一部につき虚偽、誤記又は記載漏れがあった場合<br></br>
      --反社会的勢力等（暴力団、暴力団体、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合<br></br>
      --その他、運営者が登録を適当でないと合理的に判断した場合<br></br>
      <br></br>
</p>
      
      <h2>第5条（禁止事項）</h2>
      <p>
当サービスのご利用に際して、以下の行為を禁止します。これらに違反した場合、運営者は事前通告なしに、
利用者のメッセージやプロフィールの記載内容等を削除し、サービスの利用を一時的に停止、またはアカウントを削除するなどの措置を講じることができます。
また、これにより発生した損害について、運営者は利用者に対して損害賠償を請求する権利を有します。
<br></br><br></br>
1. 虚偽の情報を提供または投稿する行為<br></br>
2. 他の利用者の情報を不正に収集、使用、または公開する行為<br></br>
3. 法令に違反する行為、公序良俗に反する行為<br></br>
4. 他の利用者に対する誹謗中傷、嫌がらせ、または迷惑行為<br></br>
5. 無断で他者の個人情報を収集、公開する行為<br></br>
6. 当サービスを、本来の目的とは異なる目的で利用する行為<br></br>
7. 不正アクセス、システムの破壊、妨害行為<br></br>
8. 商業目的の広告、宣伝、勧誘、その他営業行為<br></br>
9. 知的財産権、プライバシー権、名誉権など他人の権利を侵害する行為<br></br>
10. 自動投稿ツールなどを使用したスパム行為<br></br>
11. 当サービスのページ画面をスクリーンショットで撮影する行為<br></br>
12. その他、運営者が不適切と判断する行為<br></br><br></br>
</p>
      
      <h2>第6条（当サービスの利用の制限）</h2>
      <p>上記第5条（禁止事項）に該当するような行為をする方から当サービスの利用者を守るために、当サービス上で利用者が他の利用者に２４時間以内に送ることができる
        メッセージの数を、当社の判断で適切な数に制限する権利および当サービスに新たに会員登録しようとする者に対して、当該会員登録を拒否する権利を当社は留保します。</p>
      
      <h2>第7条（契約期間および契約の終了）</h2>
      <p>利用者が当サービスの会員である限り、本契約は有効です。会員登録を行い当サービスを利用開始した時点で、利用者は本サービスを即座に開始することを選択したとみなされ、
        クーリングオフは適用されません。利用者は、当サービスのアカウント削除ページを通じて契約終了の通知を送ることで、いつでも、いかなる理由でも会員資格を終了させることができます。
        退会を希望される場合は、退会申請フォームからお手続きください。また、カスタマーサポートへの連絡や本人確認手続きなど、管理者が定める手続きを経て、会員資格を終了させることも可能です。
        利用者が本契約の一つ以上の条項に違反した場合、またはそのおそれがあると私が合理的に判断した場合、運営者はいつでも利用者の会員資格を即座に終了させ、違反に該当するコンテンツを削除し、
        当サービスの利用を終了させることができます。最後に当サービスを利用してから6ヵ月以上が経過した場合、運営者は利用者のアカウントを無効にすることができます。</p>


      <h2>第8条（当サービスの目的外利用等の禁止）</h2>
      <p>利用者が、当サービスやそれを構成するデータを、本来の提供目的を超えて利用し、規約に違反した場合、運営者はその行為を差し止める権利を有し、さらに、その行為によって利用者が得た利益相当額を請求する権利を持ちます。
</p>


      <h2>第9条<br></br>（投稿の削除、サービスの利用停止、アカウント削除について）</h2>
      <p>

利用者が当サービスに投稿した内容および、当サービスを通じて他の利用者に提供した内容については、利用者が単独で責任を負うものとし、運営者は一切責任を負いません。
利用者が当サービスに関連して投稿した内容は、常に正確であり、本契約に違反せず、かつあらゆる点で誰にも損害を与えないものであることを利用者は表明し、保証します。
運営者は、当サービスを適正に運営するために、以下の場合には事前に通知することなく、利用者のメッセージや投稿内容を削除したり、サービスの一部または全部の利用を一時的に停止したり、利用者のアカウントを削除する権利を有します。これにより、本契約も終了します。

<br></br><br></br>アカウント削除等の措置をとる場合:<br></br>
1. 利用者が本規約に違反した場合、またはそのおそれがあると判断した場合<br></br>
2. アカウントが反社会的勢力またはその構成員や関係者によって登録または使用されたと判断した場合<br></br>
3. 同一の利用者が複数のアカウントを作成したことが判明した場合<br></br>
4. その他、利用者との信頼関係が失われ、契約関係の継続が困難であると判断した場合<br></br>
</p>


      <h2>第10条（運営者に対する補償）</h2>
      <p>利用者の行為により生じたクレームや紛争に関連して、運営者が費用を負担することになった場合、利用者は運営者が支払ったすべての費用や賠償金を補償するものとします。</p>
      
      <h2>第11条（利用者のデータおよびコンテンツの取り扱い）</h2>
      <p>当サービスの保守や改良が必要な場合、運営者は、利用者が運営者の管理するサーバーに保存しているデータおよびコンテンツを、サービスの保守や改良に必要な範囲で、利用者の同意なく複製、改良等することができるものとします。
また、利用者が投稿したコンテンツについての著作権は、利用者または当該コンテンツの著作権者に帰属します。ただし、利用者は、当該コンテンツに関して、運営者に対して、日本国内外で無償かつ非独占的に利用（複製、上映、公衆送信、展示、譲渡、翻訳、翻案、出版）する権利を期限なく許諾したものとみなします。なお、利用者は、運営者または運営者がサブライセンスをした第三者に対し、著作者人格権を行使しないものとします。
</p>

      <h2>第12条（サービスの提供の中断）</h2>
      <p>運営者は、以下のいずれかに該当する場合には、利用者に事前に通知することなく、当サービスの全部または一部の提供を中断することができます。
        なお、これらの事由により運営者が行った措置に基づき利用者に損害が生じた場合でも、運営者は一切の責任を負いません。<br></br><br></br>

（１） 当サービスに関わるネットワーク、システムまたはサーバー等の点検または保守作業を行う場合<br></br>
（２） ネットワーク、システムまたはサーバー等が事故により停止した場合<br></br>
（３） 地震、落雷、火災、風水害、停電、天災地変などの不可抗力により当サービスの運営ができなくなった場合<br></br>
（４） その他、運営者がサービスの停止または中断を行う必要があると判断した場合<br></br></p>

      <h2>第13条（免責事項）</h2>
      <p>運営者の債務不履行および不法行為責任は、運営者の故意または重過失によらない場合には免責されるものとします。
      また、運営者は、当サービスを通じて行われた対面での会合に関連して発生したいかなる問題（例: 暴力、詐欺、窃盗、その他の不正行為）についても、一切の責任を負いません。
      また、運営者は、当サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による当サービスの利用が、
      利用者に適用される法令に適合すること、ならびに不具合が生じないことについて、一切保証いたしません。さらに、利用者やその他の個人または団体等が当サービスを通じて投稿する助言、意見、声明その他の情報について、運営者はその正確性や信頼性を表明し、
        保証するものではありません。これらの情報を信頼することについては、利用者の自己責任であることに同意していただきます。オンライン・オフラインを問わず、
        運営者は一切の責任を負いません。当サービスを利用される際は、ご自身で十分注意してください。</p>

      <h2>第14条（利用規約の変更について）</h2>
      <p>
運営者が必要と判断した場合には、利用者に事前に通知することなく、
いつでも本規約を変更することができるものとします。規約変更後に利用者が当サービスを利用した場合、
または運営者が定める事前告知期間内に退会手続きを行わなかった場合、利用者は変更後の規約に同意したものとみなします。</p>


<h2>第15条（通知または連絡）</h2>
      <p>運営者は、必要と判断した場合には、利用者に事前に通知することなく、いつでも本規約を変更することができるものとします。ただし、重要な変更がある場合には、合理的な期間を設けて事前に通知いたします。規約変更後に利用者が当サービスを利用した場合、または運営者が定める事前告知期間内に退会手続きを行わなかった場合、利用者は変更後の規約に同意したものとみなします。
</p>

<h2>第16条（権利義務などの譲渡の禁止）</h2>
<p>利用者は、本規約に基づく全ての契約について、その契約上の地位およびこれにより生じる権利義務の全部または一部を、
    運営者の書面による事前の承諾なく、第三者に譲渡、処分、引き受けさせ、または担保に供することはできません。
    また、運営者が当サービスに係る事業を他者に譲渡した場合、当該事業譲渡に伴い本契約上の地位、
    本規約に基づく権利および義務、並びに利用者の登録事項その他の顧客情報を、当該事業譲渡の譲受人に譲渡することができるものとし、
    利用者は、かかる譲渡について本項において予め同意するものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、
    会社分割その他事業が移転するあらゆる場合を含むものとします。</p>

<h2>第17条（知的財産権ポリシー）</h2>
<p>利用者は、権利者から事前に同意を得なければ、知的財産権などによって保護された情報を、
    いかなる形であっても投稿することはできません。他の利用者の投稿が他人の知的財産権を侵害していると利用者が発見した場合は、
    お問い合わせフォームを通じて以下の情報を提供してください。なお、知的財産権等の侵害以外の本規約違反の申告については、別途定められた手続きを通じて申告してください。<br></br><br></br>

- 知的財産権等の権利者（その代理人として行動することが認められた者を含み、以下「権利者」と総称します）の名前および関連情報<br></br>
- 利用者と権利者との関係についての説明<br></br>
- 知的財産権等を侵害すると利用者が主張する投稿についての説明<br></br>
- 侵害とされる投稿が当サービスのどこに表示されているかについての詳細な説明<br></br>
- 利用者の住所、電話番号、メールアドレス<br></br>
- その投稿が知的財産権等を侵害する理由、およびその理由に基づく利用者の主張<br></br></p>

<h2>第18条（利用者間の紛争）</h2>
<p>利用者は、他の当サービスの利用者との交流に関して、単独で責任を負うものとします。
    運営者は、利用者間で発生した紛争を監視する権利を有しますが、これに関与または監視する義務を負いません。</p>

<h2>第19条（会員情報の取り扱い）</h2>
<p>当サービスをご利用の場合には、院生マッチプライバシーステートメントが適用されます。
    利用者は、このプライバシーステートメントに従って、運営者が利用者の情報を取り扱うことに同意するものとします。
    また、運営者は、利用者が当サービスに提供した情報やデータを、個人を特定できない形で統計的な情報として、
    運営者の裁量で利用および公開することができるものとし、利用者はこれに同意するものとします。</p>


<h2>第20条（秘密保持）</h2>
<p>利用者は、当サービスに関連して運営者が利用者に対して秘密に取り扱うことを求めて開示した非公知の情報について、
    運営者の事前の書面による許可がある場合を除き、これを秘密として取り扱うものとします。
    また、利用者は、当サービスの利用により知り得た他の利用者の個人情報を守秘する義務を負います。</p>

<h2>第21条（利用者からの申出による利用停止）</h2>
<p>利用者が携帯電話端末を紛失・盗難された場合、その他必要がある場合には、運営者に申し出ることで、
    所定の方法により本人確認書類を提出することを条件として、当サービスの利用を停止することができます。
    ただし、運営者は、利用者からの当該申出の有無にかかわらず、当サービスの利用停止が行われなかったこと、
    または利用者のアカウントが第三者に利用されたことにより利用者に生じた損害について、一切の責任を負いません。
    また、利用者がご自身の携帯電話端末を第三者に譲渡、売却した場合も、運営者は一切の責任を負いません。</p>

<h2>第22条（当サービスのご利用上の注意点）</h2>
<p>
    当サービスは、交際相手や友達、または特定の目的を実現するためのパートナーを探すためのサービスですが、不特定多数の方が利用しているため、
    場合によってはサービスが悪用されたり、適切なコミュニケーションが取れない方が利用する可能性もあります。
    また、ユーザー同士が直接会う場合には、暴力、詐欺、その他の危険が伴う可能性があります。
    これらのリスクを十分にご認識いただいた上で、当サービスをご利用いただく際には、他人に対してどのような個人情報を開示するか、
    どのようなやりとりをするかについて、利用者自身が常に慎重にご判断ください。
 </p>

<h2>第23条（本規約の効力について）</h2>
<p>
本規約のいずれかの規定またはその一部が、消費者契約法その他の法令により無効または執行不能と判断された場合でも、
本規約のその他の規定および無効または執行不能と判断された部分以外の部分は、引き続き完全に効力を有するものとします。
また、無効または執行不能と判断された規定や部分についても、当該規定や部分の趣旨に最も近い有効な規定に置き換えられ、
または合理的な解釈を加えて適用されるものとします。</p>

<h2>第24条（準拠法および裁判管轄）</h2>
<p>本利用規約の成立、効力発生、解釈にあたっては、日本法を準拠法とします。また、当サービス（掲載内容や広告を含む）、
    ソフトウェアに起因または関連して運営者と利用者との間で生じた紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
      <p style={{ marginTop: '40px' }}>制定日: 2024年8月15日</p>
    </div>
  );
};

export default TermsOfService;
