import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { db, auth } from '../../firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../../styles/AdminDashboard.css';

const AdminDashboard = () => {
  const [user, loading] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.admin) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
          navigate('/'); // 管理者でない場合はリダイレクト
        }
      }
    };

    if (!loading) {
      checkAdmin();
    }
  }, [user, loading, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <div>Access denied. You do not have permission to view this page.</div>;
  }

  return (
    <div className="admin-dashboard-container">
      <h2>管理画面</h2>
      <ul>
        <li>
          <Link to="/adminnotification">配信</Link>
        </li>
        <li>
          <Link to="/admininquiry">ユーザーからのお問い合わせ</Link>
        </li>
        <li>
          <Link to="/adminageverification">年齢確認</Link>
        </li>
        <li>
          <Link to="/adminsuspenduser">違反ユーザー一覧</Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminDashboard;
