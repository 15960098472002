import React, { useState, useEffect } from 'react';
import { storage, auth, db } from '../firebaseConfig';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../styles/AgeVerification.css';
import '../styles/ProfileEdit.css';
import { Link } from 'react-router-dom';

const AgeVerification = () => {
  const [user] = useAuthState(auth);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [rejectReason, setRejectReason] = useState('');  // 拒否理由
  const [customMessage, setCustomMessage] = useState(''); // 任意メッセージ

  useEffect(() => {
    const checkVerificationStatus = async () => {
      if (user) {
        try {
          const docRef = doc(db, 'age_verification', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setVerificationStatus(data.status);
            if (data.status === 'rejected') {
              setRejectReason(data.rejectReason || '理由が指定されていません');
              setCustomMessage(data.customMessage || '');
            }
          }
        } catch (err) {
          console.error('Verification status fetch failed:', err.message);
        }
      }
    };
    checkVerificationStatus();
  }, [user]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // 許可されているファイル形式
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      const maxSize = 5 * 1024 * 1024; // 5MB以下

      // ファイル形式を確認
      if (!allowedTypes.includes(selectedFile.type)) {
        setError('許可されているファイル形式はJPEG、PNG、GIF、PDFのみです。');
        setFile(null);
        return;
      }

      // ファイルサイズを確認
      if (selectedFile.size > maxSize) {
        setError('ファイルサイズは5MB以下にしてください。');
        setFile(null);
        return;
      }

      setFile(selectedFile);
      setUploadProgress(0);
      setError('');
      setSuccess(false);
    }
  };

  const handleUpload = async () => {
    if (!file || !user) {
      setError('ファイルを選択し、ログインしてください');
      return;
    }

    // ファイル名を乱数化
    const randomFileName = `${new Date().getTime()}-${Math.random().toString(36).substring(2, 15)}-${file.name}`;
    const storageRef = ref(storage, `age_verification/${user.uid}/${randomFileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      }, 
      (error) => {
        console.error('Upload failed:', error.message);  // 内部エラーをコンソールに出力
        setError('アップロード中にエラーが発生しました。もう一度お試しください。');  // 一般的なエラーメッセージを表示
      }, 
      async () => {
        try {
          await setDoc(doc(db, 'age_verification', user.uid), {
            uid: user.uid,
            fileName: randomFileName,
            timestamp: new Date(),
            status: 'checking', // statusをcheckingに設定
          });
          setSuccess(true);
          setVerificationStatus('checking');
        } catch (err) {
          console.error('Failed to save to Firestore:', err.message);  // 内部エラーをコンソールに出力
          setError('サーバーエラーが発生しました。もう一度お試しください。');  // 一般的なエラーメッセージを表示
        }
      }
    );
  };

  return (
    <div className="age-verification-container">
      <div className="header">
        <Link to="/settings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">年齢確認</p>
      </div>
      <div className="note-top">
        <h3>年齢確認がお済みでない方は、他会員とのメッセージ機能をご利用いただけません。</h3><br></br>

        <img src="/images/gakuseisyo.png" alt="Default" />
        <h3>提出前の注意事項</h3><br></br>
        <p>1. 学生証の全体がはっきり映っており、生年月日が鮮明に読み取れる。
          <br></br>※もし、裏面に生年月日が記載された学生証の場合、裏面と表面の合成画像を送信してください。</p>
        <p>2. 画像データを送信した会員様の学生証で、在学中の学生証である。</p>
        <p>3. 現在の年齢が18歳以上である。（高校生不可）</p>
      </div>
      {verificationStatus === 'checking' ? (
        <div className="checking-message">確認中です。しばらくお待ちください。</div>
      ) : verificationStatus === 'checked' ? (
        <div className="checked-message">確認済みです</div>
      ) : verificationStatus === 'rejected' ? (
        <div className="rejected-message">
          <p>年齢確認が拒否されました。</p>
          <p>拒否理由: {rejectReason}</p>
          {customMessage && (
            <p>追加メッセージ: {customMessage}</p>
          )}
          <input type="file" onChange={handleFileChange} />
          {file && <div>選択されたファイル: {file.name}</div>}
          <button onClick={handleUpload}>再アップロード</button>
        </div>
      ) : (
        <>
          <input type="file" onChange={handleFileChange} />
          {file && <div>選択されたファイル: {file.name}</div>}
          <button onClick={handleUpload}>アップロード</button>
        </>
      )}
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">アップロードが成功しました！</div>}
      {uploadProgress > 0 && <div>アップロード進行中: {Math.round(uploadProgress)}%</div>}
      <div className="note-bottom">
        <p>注意事項：</p>
        <ul>
          <li>・もし、学生証をお持ちでない社会人の場合、代わりとなる健康保険証またはパスポートなどの生年月日が記載された身分証明書をご提出下さい。</li>
          <li>・アップロードした画像は第三者に公開されません。確認後すぐにサーバーから削除いたします。</li>
          <li>・確認には数日かかる場合があります。</li>
        </ul>
      </div>
    </div>
  );
};

export default AgeVerification;
