import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/GroupHeader.css';

const GroupHeader = ({ profiles }) => {
  return (
    <div className="group-header-container">
      <div className="group-header-profile-scroll-container">
        {profiles.map(profile => (
          <Link to={`/profile/${profile.id}`} key={profile.id} className="group-header-profile-icon-link">
            <img src={profile.photoURL || '/images/default.png'} alt={profile.name} className="group-header-profile-icon" />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default GroupHeader;
