import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebaseConfig';
import { collection, query, where, getDocs, updateDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../../styles/AdminSuspendUser.css';

const AdminSuspendUser = () => {
  const [user, loading] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [reportedUser, setReportedUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.admin) {
          setIsAdmin(true);
          fetchReports();
        } else {
          setIsAdmin(false);
          navigate('/'); // 管理者でない場合はリダイレクト
        }
      }
    };

    if (!loading) {
      checkAdmin();
    }
  }, [user, loading, navigate]);

  const fetchReports = async () => {
    setLoadingData(true);
    const q = query(collection(db, 'reports'), where('status', '==', 'pending'));
    const querySnapshot = await getDocs(q);
    const reportData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    setReports(reportData);
    setLoadingData(false);
  };

  const fetchReportedUser = async (userId) => {
    const userDoc = await getDoc(doc(db, 'members', userId));
    if (userDoc.exists()) {
      setReportedUser(userDoc.data());
    } else {
      setReportedUser(null);
    }
  };

  const handleSelectReport = (report) => {
    setSelectedReport(report);
    fetchReportedUser(report.reportedUser);
  };

  const handleSuspendUser = async () => {
    if (reportedUser) {
      try {
        // suspended_userコレクションにユーザーIDを追加
        await setDoc(doc(db, 'suspended_user', selectedReport.reportedUser), { userId: selectedReport.reportedUser });

        alert('User has been suspended successfully.');
        setSelectedReport(null);
        setReportedUser(null);
        fetchReports(); // 更新後に再読み込み
      } catch (error) {
        console.error('Error suspending user:', error);
        alert('Error suspending user.');
      }
    }
  };

  if (loading || loadingData) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <div>Access denied. You do not have permission to view this page.</div>;
  }

  return (
    <div className="admin-suspend-user-container">
      <h2>Suspend Users</h2>
      {reports.length === 0 ? (
        <div>No pending reports.</div>
      ) : (
        <ul>
          {reports.map((report) => (
            <li key={report.id} className="report-item" onClick={() => handleSelectReport(report)}>
              <p>{report.details}</p>
              <span className="report-date">
                {new Date(report.createdAt.toMillis()).toLocaleString()}
              </span>
            </li>
          ))}
        </ul>
      )}
      {selectedReport && reportedUser && (
        <div className="report-details">
          <h3>Report Details</h3>
          <p><strong>Report:</strong> {selectedReport.details}</p>
          <div className="reported-user-info">
            <h4>Reported User Information</h4>
            <img src={reportedUser.photoURL || '/images/default.png'} alt="Reported User" className="reported-user-image" />
            <p><strong>Name:</strong> {reportedUser.name}</p>
            <p><strong>Email:</strong> {reportedUser.email}</p>
          </div>
          <button onClick={handleSuspendUser} className="suspend-button">Suspend User</button>
        </div>
      )}
    </div>
  );
};

export default AdminSuspendUser;
