import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, getDoc, collection, addDoc, serverTimestamp, query, onSnapshot, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';
import '../styles/BookContent.css';

const BookContent = () => {
  const { id } = useParams();
  const [user] = useAuthState(auth);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [participantsInfo, setParticipantsInfo] = useState([]); // 追加：participants情報の状態管理
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false); // 追加：お気に入り登録状態の管理

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'favorites', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const contentData = docSnap.data();
          setContent(contentData);

          // 追加：participantsの情報を取得
          const participants = contentData.participants || [];
          const participantsDetails = await Promise.all(
            participants.map(async (uid) => {
              const memberDoc = await getDoc(doc(db, 'members', uid));
              if (memberDoc.exists()) {
                return {
                  uid,
                  ...memberDoc.data(),
                };
              }
              return null;
            })
          );
          setParticipantsInfo(participantsDetails.filter(Boolean));

          // 自分のuidがparticipantsに含まれているか確認
          if (user && participants.includes(user.uid)) {
            setIsFavorite(true);
          }
        } else {
          setError('Content not found');
        }
      } catch (err) {
        setError(`Error fetching content: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [id, user]);

  useEffect(() => {
    const fetchComments = async () => {
      const commentsRef = collection(db, 'favorites', id, 'comments');
      const q = query(commentsRef);

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const commentsData = await Promise.all(
          snapshot.docs.map(async (commentDoc) => {
            const commentData = commentDoc.data();
            const userDocRef = doc(db, 'members', commentData.uid);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.data();
            return {
              ...commentData,
              name: userData?.name || 'Anonymous',
              photoURL: userData?.photoURL || '/images/default.png',
            };
          })
        );
        setComments(commentsData);
      });

      return unsubscribe;
    };

    fetchComments();
  }, [id]);

  const handleCommentSubmit = async () => {
    if (newComment.trim() && user) {
      try {
        const commentsRef = collection(db, 'favorites', id, 'comments');
        await addDoc(commentsRef, {
          uid: user.uid,
          comment: newComment,
          createdAt: serverTimestamp(),
          like_counts: 0,
        });
        setNewComment('');
      } catch (err) {
        console.error('Error adding comment: ', err);
      }
    }
  };

  const handleAddToFavorites = async () => {
    if (user && content) {
      try {
        const docRef = doc(db, 'favorites', id);
        await updateDoc(docRef, {
          participants: arrayUnion(user.uid)
        });
        setIsFavorite(true); // お気に入り登録状態を更新
        alert('お気に入りに追加されました');
      } catch (err) {
        console.error('Error adding to favorites: ', err);
      }
    }
  };

  const handleRemoveFromFavorites = async () => {
    if (user && content) {
      try {
        const docRef = doc(db, 'favorites', id);
        await updateDoc(docRef, {
          participants: arrayRemove(user.uid)
        });
        setIsFavorite(false); // お気に入り登録状態を更新
        alert('お気に入りを解除しました');
      } catch (err) {
        console.error('Error removing from favorites: ', err);
      }
    }
  };

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const renderLoading = () => <div>Loading...</div>;

  const renderError = () => <div>{error}</div>;

  const renderDescription = () => {
    const description = content.Plot || content.volumeInfo?.description || 'No description available.';
    if (description.length > 50) {
      return (
        <p className="book-content-text">
          {isExpanded ? description : `${description.substring(0, 50)}...`}
          {!isExpanded && (
            <span 
              onClick={toggleReadMore} 
              className="read-more-link"
              style={{ color: '#3498db', cursor: 'pointer', marginLeft: '5px' }}
            >
              続きを押す...
            </span>
          )}
        </p>
      );
    }
    return <p className="book-content-text">{description}</p>;
  };

  const getReleaseDate = () => {
    if (content?.Released) return ` ${content.Released}`; // 映画の場合
    if (content?.releaseDate) return `${content.releaseDate}`; // 音楽の場合
    if (content?.volumeInfo?.publishedDate) return ` ${content.volumeInfo.publishedDate}`; // 本の場合
    return null;
  };

  const renderParticipants = () => {
    if (participantsInfo.length > 0) {
      return (
        <div className="participants-info">
          <h3 className="participants-title">お気に入り登録者</h3>
          <div className="participants-list">
            {participantsInfo.map((participant, index) => (
              <Link
                key={index}
                to={`/other_member_profile/${participant.uid}`}
                className="participant-item"
              >
                <img 
                  src={participant.photoURL || '/images/default.png'} 
                  alt={`${participant.name}'s avatar`} 
                  className="participant-avatar" 
                />
                <p className="participant-name">{participant.name}</p>
              </Link>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderContent = () => (
    <div className="book-content-container">
      <div className="header">
        <Link to="/booksearch" className="back-link-cont">←</Link>
        <p className="settings-title-cont">レビュー・議論・メモ</p>
      </div>
      <div className="custom-credit">
        <p>
          Data provided by{' '}
          <a href="https://books.google.com" target="_blank" rel="noopener noreferrer">
            Google Books API
          </a>
          ,{' '}
          <a href="https://www.apple.com/itunes/" target="_blank" rel="noopener noreferrer">
            iTunes API
          </a>
          , or{' '}
          <a href="https://www.omdbapi.com/" target="_blank" rel="noopener noreferrer">
            OMDb API
          </a>
          .
        </p>
      </div>
      <h1 className="book-content-title">{getTitle()}</h1>
      <div className="book-content-wrapper">
        <img
          src={getImage()}
          alt={getTitle()}
          className="book-content-image"
        />
        <div className="book-content-details">
          <h3 className="book-content-subtitle">著者・製作者・監督:</h3>
          <p className="book-content-text">{getAuthorOrArtist()}</p>
          <h3 className="book-content-subtitle">リリース日:</h3>
          {getReleaseDate() && <p className="book-content-text">{getReleaseDate()}</p>}
          <h3 className="book-content-subtitle">内容:</h3>
          {renderDescription()}
          {!isFavorite ? (
            <button onClick={handleAddToFavorites} className="add-to-favorites-button">
              お気に入りに登録
            </button>
          ) : (
            <button onClick={handleRemoveFromFavorites} className="add-to-favorites-button">
              お気に入り解除
            </button>
          )}
        </div>
        {renderParticipants()} {/* 追加：参加者情報を表示 */}
        {renderComments()}
        {renderCommentInput()}
      </div>
    </div>
  );

  const renderComments = () => (
    <div className="book-comments-section">
      <h3 className="book-comments-title">レビュー、感想</h3>
      <ul className="book-comments-list">
        {comments.map((comment, index) => (
          <li key={index} className="book-comment">
            <img 
              src={comment.photoURL ? comment.photoURL : '/images/default.png'} 
              alt={`${comment.name}'s avatar`} 
              className="comment-avatar" 
            />
            <div className="comment-details">
              <p className="comment-author">{comment.name}</p>
              <p>{comment.comment}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  const renderCommentInput = () => (
    <div className="book-comment-input">
      <textarea
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder="Add your comment..."
        className="book-comment-textarea"
      />
      <button onClick={handleCommentSubmit} className="book-comment-button">送信</button>
    </div>
  );

  const getTitle = () => (
    content?.Title ||
    content?.volumeInfo?.title ||
    content?.trackName ||
    content?.title
  );

  const getImage = () => (
    content?.Poster ||
    content?.volumeInfo?.imageLinks?.thumbnail ||
    content?.artworkUrl100 ||
    '/images/default.png'
  );

  const getAuthorOrArtist = () => (
    content?.volumeInfo?.authors?.join(', ') ||
    content?.artistName ||
    content?.Director
  );

  if (loading) return renderLoading();
  if (error) return renderError();

  return renderContent();
};

export default BookContent;
