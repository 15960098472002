import React, { createContext, useState, useContext } from 'react';

const CallContext = createContext();

export const useCall = () => {
  return useContext(CallContext);
};

export const CallProvider = ({ children }) => {
  const [callIncoming, setCallIncoming] = useState(false);
  const [callInfo, setCallInfo] = useState(null);

  const incomingCall = (info) => {
    setCallInfo(info);
    setCallIncoming(true);
  };

  const answerCall = () => {
    setCallIncoming(false);
  };

  const rejectCall = () => {
    setCallIncoming(false);
  };

  return (
    <CallContext.Provider value={{ callIncoming, callInfo, incomingCall, answerCall, rejectCall }}>
      {children}
    </CallContext.Provider>
  );
};
