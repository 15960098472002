import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db, auth } from '../firebaseConfig'; // authをインポート
import { collection, getDocs, query, orderBy, addDoc, getDoc, doc } from 'firebase/firestore'; // addDocを使用
import MenuBar from '../components/MenuBar';
import { FaThumbsUp, FaRegComment, FaShare, FaTimes } from 'react-icons/fa';
import ThreadModal from '../components/ThreadModal'; // 新しいコンポーネントをインポート
import '../styles/GroupContent.css'; 
import { Link } from 'react-router-dom';
import GroupHeader from '../components/GroupHeader'; // 新しいコンポーネントをインポート
import { AiOutlineLike } from "react-icons/ai";
import { FaPaperPlane, FaFilePdf, FaImage } from 'react-icons/fa';
import parse from 'html-react-parser'; // リッチテキスト解析用
import { BlockMath } from 'react-katex'; // LaTeX数式用
import 'katex/dist/katex.min.css'; // LaTeXスタイルシート

const GroupContent = () => {
  const { groupId } = useParams();
  const [threads, setThreads] = useState([]);
  const [members, setMembers] = useState([]); // メンバーのための新しい状態
  const [showImageModal, setShowImageModal] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState('');
  const [groupName, setGroupName] = useState('');
  const [groupPhotoURL, setGroupPhotoURL] = useState('');
  const [organizerId, setOrganizerId] = useState(''); // オーガナイザーのID
  const [participantsIds, setParticipantsIds] = useState([]); // 参加者のIDリスト
  const [showThreadModal, setShowThreadModal] = useState(false);

  // メンバー情報を取得するかどうかを管理するフラグ
  const [shouldFetchMembers, setShouldFetchMembers] = useState(true);
  
  // スレッドとコメントの状態管理
  const [activeCommentThreadId, setActiveCommentThreadId] = useState(null);
  const [commentText, setCommentText] = useState(''); // コメントの入力テキスト
  const [threadText, setThreadText] = useState(''); // 新しいスレッドの入力テキスト
  const [errorMessage, setErrorMessage] = useState(''); // エラーメッセージの状態

  // ユーザーのUIDを取得
  const currentUser = auth.currentUser;
  const userId = currentUser ? currentUser.uid : null; // ログインしていない場合にnullになる

  // スレッド投稿用モーダルを開く
  const handleOpenThreadModal = () => {
    setShowThreadModal(true);
  };

  // スレッド投稿用モーダルを閉じる
  const handleCloseThreadModal = () => {
    setShowThreadModal(false);
  };

  // コメントボタンが押されたときの処理
  const handleCommentButtonClick = (threadId) => {
    setActiveCommentThreadId(prevThreadId => prevThreadId === threadId ? null : threadId);
  };

  // コメントの入力変更時の処理
  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  // 新しいスレッドの入力変更時の処理
  const handleThreadChange = (event) => {
    setThreadText(event.target.value);
  };
  
  const formatTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  // LaTeXやリッチテキスト、改行を適切に処理する関数
const formatTextWithLineBreaksAndRichText = (text) => {
  // LaTeX数式を検出して<katex>タグで囲む
  const latexPattern = /\$(.*?)\$/g;
  const formattedText = text.replace(latexPattern, (match, p1) => `<katex>${p1}</katex>`);

  // 改行を適切に処理
  const htmlWithLineBreaks = formattedText.replace(/\n/g, '<br />');

  // html-react-parserでHTMLとLaTeXを解析
  return parse(htmlWithLineBreaks, {
    replace: (domNode) => {
      if (domNode.name === 'katex') {
        return <BlockMath math={domNode.children[0].data} />;
      }
    },
  });
};
  
  // 新しいスレッドの投稿処理
  const handleThreadSubmit = async () => {
    if (!userId) {
      setErrorMessage('ログインしていません。');
      return;
    }

    if (threadText.trim()) {
      const newThread = {
        text: threadText,
        userId, // 現在ログインしているユーザーのUIDを保存
        createdAt: new Date(),
      };

      try {
        // 新しいスレッドを追加
        const threadsCollection = collection(db, 'groups', groupId, 'threads');
        await addDoc(threadsCollection, newThread);

        setThreadText(''); // スレッド入力フィールドをクリア
        handleCloseThreadModal(); // モーダルを閉じる

        // スレッドを再取得して表示を更新
        fetchThreads();
      } catch (error) {
        console.error('Error adding thread:', error);
        setErrorMessage('スレッドの投稿に失敗しました。');
      }
    } else {
      setErrorMessage('スレッドの内容を入力してください。');
    }
  };

  // コメントの投稿処理
  const handleCommentSubmit = async (threadId) => {
    if (!userId) {
      setErrorMessage('ログインしていません。');
      return;
    }

    if (commentText.trim()) {
      const newComment = {
        text: commentText,
        userId, // 現在ログインしているユーザーのUIDを保存
        createdAt: new Date(),
      };

      try {
        // コメントをスレッドに追加
        const commentsCollection = collection(db, 'groups', groupId, 'threads', threadId, 'replies');
        await addDoc(commentsCollection, newComment);

        setCommentText(''); // コメント入力フィールドをクリア
        setActiveCommentThreadId(null); // コメントフォームを閉じる

        // スレッドを再取得して表示を更新
        fetchThreads();
      } catch (error) {
        console.error('Error adding comment:', error);
        setErrorMessage('コメントの送信に失敗しました。');
      }
    } else {
      setErrorMessage('コメントを入力してください。');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchGroupName(); // グループ情報を先に取得

      // メンバー情報はグループ情報が取れてから取得（必要なら）
      if (shouldFetchMembers) {
        fetchMembers(organizerId, participantsIds);
      }

      fetchThreads(); // スレッドも並行して取得
    };

    fetchData();
  }, [groupId, organizerId, participantsIds, shouldFetchMembers]); // organizerId, participantsIds, shouldFetchMembersが更新されたときにもfetchDataを呼び出す

  const fetchGroupName = async () => {
    try {
      const groupDoc = await getDoc(doc(db, 'groups', groupId));
      if (groupDoc.exists()) {
        const { name, imageUrl, organizer, participants } = groupDoc.data();
        setGroupName(name);
        setGroupPhotoURL(imageUrl);
  
        // Organizer と Participants の ID をセット
        setOrganizerId(organizer || '');  // organizerId が空の場合は空文字列を設定
        setParticipantsIds(participants || []); // participantsIds が空の場合は空配列を設定
  
        // ID が設定されたらメンバー情報をフェッチ
        if (organizer || (participants && participants.length > 0)) {
          setShouldFetchMembers(true); // フェッチが必要な状態に変更
        } else {
          setShouldFetchMembers(false); // フェッチをしない
        }
      } else {
        console.warn('Group not found');
        setGroupName('Unknown Group');
        setGroupPhotoURL('');
        setShouldFetchMembers(false); // グループが見つからない場合はフェッチを行わない
      }
    } catch (error) {
      console.error('Error fetching group:', error);
      setShouldFetchMembers(false); // エラーが発生した場合もフェッチを止める
    }
  };
  
  useEffect(() => {
    if (shouldFetchMembers && (organizerId || participantsIds.length > 0)) {
      fetchMembers(organizerId, participantsIds); // フェッチはここでのみ行う
    }
  }, [organizerId, participantsIds, shouldFetchMembers]); // organizerId, participantsIdsが変更されたときにのみフェッチを行う
  
  
  const fetchMembers = async (organizerId, participantsIds) => {
    if (!organizerId && participantsIds.length === 0) {
      console.warn('No organizer or participants to fetch');
      setShouldFetchMembers(false); // フェッチのループを止める
      return;
    }
  
    const userIds = new Set([organizerId, ...participantsIds]);
    let members = [];
  
    for (let userId of userIds) {
      if (userId) {
        try {
          const userDoc = await getDoc(doc(db, 'members', userId));
          if (userDoc.exists()) {
            members.push({
              id: userId,
              ...userDoc.data()
            });
          }
        } catch (error) {
          console.error(`Error fetching user with ID ${userId}:`, error);
        }
      }
    }
  
    setMembers(members);
    setShouldFetchMembers(false); // メンバーを取得した後はフェッチを止める
  };
  

  const fetchThreads = async () => {
    const threadsCollection = collection(db, 'groups', groupId, 'threads');
    const q = query(threadsCollection, orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    let fetchedThreads = [];
    for (let doc of querySnapshot.docs) {
      const threadData = doc.data();
      const userProfile = await fetchUserProfile(threadData.userId);
      const replies = await fetchReplies(doc.id); // 各スレッドのリプライを取得
      fetchedThreads.push({
        id: doc.id,
        ...threadData,
        userId: threadData.userId,
        userName: userProfile.name,
        userPhotoURL: userProfile.photoURL,
        replies, // リプライをスレッドに紐付け
      });
    }
    setThreads(fetchedThreads);
  };

  const fetchReplies = async (threadId) => {
    const repliesCollection = collection(db, 'groups', groupId, 'threads', threadId, 'replies');
    const q = query(repliesCollection, orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    let fetchedReplies = [];
    for (let doc of querySnapshot.docs) {
      const replyData = doc.data();
      const userProfile = await fetchUserProfile(replyData.userId);
      const nestedReplies = await fetchReplies(doc.id); // リプライのリプライを再帰的に取得
      fetchedReplies.push({
        id: doc.id,
        ...replyData,
        userId: replyData.userId,
        userName: userProfile.name,
        userPhotoURL: userProfile.photoURL,
        replies: nestedReplies, // ネストされたリプライを保存
      });
    }
    return fetchedReplies;
  };



  const fetchUserProfile = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, 'members', userId));
      if (userDoc.exists()) {
        const data = userDoc.data();
        return {
          name: data.name || 'Unknown',
          photoURL: data.photoURL || '/images/default.png', // デフォルト画像を設定
        };
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
    return { name: 'Unknown', photoURL: '/images/default-avatar.png' }; // エラーハンドリングとしてデフォルトの名前と画像を返す
  };
  const timeAgo = (date) => {
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    let interval = Math.floor(seconds / 31536000);
  
    if (interval > 1) return `${interval}年前`;
    interval = Math.floor(seconds / 2592000); // 1か月
    if (interval > 1) return `${interval}ヶ月前`;
    interval = Math.floor(seconds / 86400); // 1日
    if (interval > 1) return `${interval}日前`;
    interval = Math.floor(seconds / 3600); // 1時間
    if (interval > 1) return `${interval}時間前`;
    interval = Math.floor(seconds / 60); // 1分
    if (interval > 1) return `${interval}分前`;
    return "たった今"; // それ以外は「たった今」
  };
  

  const handleImageClick = (src) => {
    setModalImageSrc(src);
    setShowImageModal(true);
  };

  // リプライを再帰的に表示する関数
  const renderReplies = (replies) => {
    return replies.map((reply) => (
      <div key={reply.id} className="group-content-reply-item">
        <div className="group-content-reply-header">
          <img src={reply.userPhotoURL} alt={reply.userName} className="reply-user-photo" />
          <div className="group-content-reply-details">
            <p className="group-content-reply-user-name">{reply.userName} 
            <span className="group-content-reply-timestamp">　　{timeAgo(reply.createdAt?.toDate())}</span></p>
        <p className="group-content-reply-text">{formatTextWithLineBreaks(reply.text)}</p>
          </div>

        </div>
        <div className="rgroup-content-eply-actions">
        </div>
        {activeCommentThreadId === reply.id && (
          <div className="group-content_chat-input-container">
            <textarea
              className="group-content_chat-input"
              rows={1}
              maxLength={255}
              placeholder="リプライを入力..."
              value={commentText}
              onChange={handleCommentChange}
            />
            <button 
              className="group-content_chat-send-button"
              onClick={() => handleCommentSubmit(reply.id)} // リプライのリプライを送信
            >
              <FaPaperPlane />
            </button>
          </div>
        )}
        {renderReplies(reply.replies)} {/* 再帰的にリプライを表示 */}
      </div>
    ));
  };

  return (
    <div className="group-content-container">
      <div className="header">
        <Link to="/your-group" className="back-link-cont">←</Link>
        <p className="settings-title-cont">{groupName}</p>
      </div>
      <img src={groupPhotoURL} className='group-content-top-img' alt="Group" />

      <GroupHeader profiles={members} />
      <div className="group-content-top-menu">
        <Link to="/group" className="group-content-top-menu-link">スレッド</Link>
        <Link to="/group" className="group-content-top-menu-link">イベント</Link>
        <Link to="/group" className="group-content-top-menu-link">画像</Link>
        <Link to="/group" className="group-content-top-menu-link">ファイル</Link>
        <Link to="/group" className="group-content-top-menu-link">情報</Link>
      </div>
      
      {/* 新しいスレッドを追加するボタン */}
      <button className="add-thread-button" onClick={handleOpenThreadModal}>
        新しい投稿をする
      </button>

      {/* スレッド投稿用モーダルの呼び出し */}
      <ThreadModal
        show={showThreadModal}
        onClose={handleCloseThreadModal}
        threadText={threadText}
        onChange={handleThreadChange}
        onSubmit={handleThreadSubmit}
        errorMessage={errorMessage}
      />

      <div className="threads-list">
        {threads.map((thread) => (
          <div key={thread.id} className="thread-item">
            <div className="thread-header">
              <img src={thread.userPhotoURL} alt={thread.userName} className="thread-user-photo" />
              <div className="thread-details">
                <p className="thread-user-name">{thread.userName}</p>
                <p className="thread-timestamp">{thread.createdAt?.toDate().toLocaleString()}</p>
              </div>
            </div>
            <p className="thread-text">
            {formatTextWithLineBreaksAndRichText(thread.text)} {/* LaTeXやリッチテキストの処理 */}
          </p>
            {thread.fileURL && (
              <img src={thread.fileURL} alt="Attached File" className="thread-image" onClick={() => handleImageClick(thread.fileURL)} />
            )}
            <div className="thread-actions">
              <button className="like-button"><AiOutlineLike /> いいね</button>
              <button className="comment-button" onClick={() => handleCommentButtonClick(thread.id)}>
                <FaRegComment /> コメントする
              </button>
            </div>
            {/* コメントフォームを該当のスレッドにのみ表示 */}

            {renderReplies(thread.replies)} {/* 各スレッドのリプライを表示 */}            {activeCommentThreadId === thread.id && (
              <div className="group-content_chat-input-container">
                <div className="group-content_chat-input-wrapper">
                  <textarea
                    className="group-content_chat-input"
                    rows={1}
                    maxLength={255}
                    placeholder="コメントを入力..."
                    value={commentText} // 入力値を状態から取得
                    onChange={handleCommentChange} // 入力が変わったときの処理
                  />
                  <label htmlFor="image-upload" className="group-content_chat-image-upload">
                    <input
                      id="image-upload"
                      type="file"
                      accept="image/jpeg,image/jpg,image/png"
                      style={{ display: 'none' }}
                    />
                    <FaFilePdf />
                    <FaImage />
                  </label>
                  <button 
                    className="group-content_chat-send-button"
                    onClick={() => handleCommentSubmit(thread.id)} // コメント送信の処理
                  >
                    <FaPaperPlane />
                  </button>
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>} {/* エラーメッセージを表示 */}
              </div>
            )}
          </div>
        ))}
      </div>

      {showImageModal && (
        <div className="modal-background" onClick={() => setShowImageModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <FaTimes className="close-modal" onClick={() => setShowImageModal(false)} />
            <img src={modalImageSrc} alt="Enlarged content" />
          </div>
        </div>
      )}

      <MenuBar />
    </div>
  );
};

export default GroupContent;
