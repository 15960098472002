import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { db, auth, storage } from '../firebaseConfig';
import { collection, getDocs, updateDoc, deleteDoc, doc as firestoreDoc, getDoc, addDoc, query, orderBy, limit, startAfter } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FaHeart, FaReply, FaImage, FaTrash } from 'react-icons/fa';
import '../styles/TimelinePage.css';
import MenuBar from '../components/MenuBar';
import { FaPenToSquare } from "react-icons/fa6";

// リンクプレビューを取得する関数
const getLinkPreview = async (url) => {
  try {
    const response = await fetch(`https://YOUR_FIREBASE_PROJECT_URL.cloudfunctions.net/fetchLinkPreview?url=${encodeURIComponent(url)}`);
    const data = await response.json();
    console.log('Link preview data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching link preview:', error);
  }
};

const TimelinePage = () => {
  const [user] = useAuthState(auth);
  const [posts, setPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([]); // すべての投稿を保持するステート
  const [filterMode, setFilterMode] = useState('all'); // フィルターモードのステート
  const [newPostText, setNewPostText] = useState('');
  const [newPostImage, setNewPostImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [replyText, setReplyText] = useState({});
  const [replyFormVisible, setReplyFormVisible] = useState({});
  const [nestedReplyText, setNestedReplyText] = useState({});
  const [nestedReplyFormVisible, setNestedReplyFormVisible] = useState({});
  const [lastVisiblePost, setLastVisiblePost] = useState(null); // 最後のドキュメントを追跡
  const [errorMessage, setErrorMessage] = useState(''); // エラーメッセージ用のステート
  const [isFormVisible, setIsFormVisible] = useState(false); // 投稿フォームの表示・非表示を管理するステート
  const [linkPreviews, setLinkPreviews] = useState({}); // リンクプレビューのステート

  // 投稿フォームの表示・非表示を切り替える関数
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  // autoExpand 関数の定義
  const autoExpand = (event) => {
    const textarea = event.target;
    textarea.style.height = 'auto'; // 高さをリセット
    textarea.style.height = `${textarea.scrollHeight}px`; // 内容に合わせて高さを調整
  };

  const observerRef = useRef(); // 無限スクロールのための監視用Ref

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    applyFilter(); // フィルターモードが変更されたときに投稿をフィルタリング
  }, [filterMode, allPosts]);

  const fetchPosts = async () => {
    const q = query(collection(db, 'posts'), orderBy('createdAt', 'desc'), limit(20));
    const querySnapshot = await getDocs(q);

    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]; // 最後の投稿を取得
    setLastVisiblePost(lastVisible); // 最後の投稿を保存

    const fetchedPosts = await processQuerySnapshot(querySnapshot);
    setAllPosts(fetchedPosts);
    setPosts(fetchedPosts);

    // リンクプレビューを取得
    await fetchLinkPreviews(fetchedPosts);
  };

  const processQuerySnapshot = async (querySnapshot) => {
    return await Promise.all(
      querySnapshot.docs.map(async (postDoc) => {
        const postData = postDoc.data();
        const userDoc = await getDoc(firestoreDoc(db, 'members', postData.userId));
        const userData = userDoc.data();

        const repliesQuerySnapshot = await getDocs(collection(postDoc.ref, 'replies'));
        const replies = await Promise.all(repliesQuerySnapshot.docs.map(async (replyDoc) => {
          const replyData = replyDoc.data();
          const replyUserDoc = await getDoc(firestoreDoc(db, 'members', replyData.userId));
          const replyUserData = replyUserDoc.data();

          const nestedRepliesQuerySnapshot = await getDocs(query(collection(replyDoc.ref, 'replies'), orderBy('createdAt', 'asc')));
          const nestedReplies = await Promise.all(nestedRepliesQuerySnapshot.docs.map(async (nestedReplyDoc) => {
            const nestedReplyData = nestedReplyDoc.data();
            const nestedReplyUserDoc = await getDoc(firestoreDoc(db, 'members', nestedReplyData.userId));
            const nestedReplyUserData = nestedReplyUserDoc.data();
            return {
              id: nestedReplyDoc.id,
              ...nestedReplyData,
              userName: nestedReplyUserData?.name || 'Unknown',
              userPhotoURL: nestedReplyUserData?.photoURL || '/images/default-avatar.png',
            };
          }));

          return {
            id: replyDoc.id,
            ...replyData,
            replyUserName: replyUserData?.name || 'Unknown',
            replyUserPhotoURL: replyUserData?.photoURL || '/images/default-avatar.png',
            nestedReplies,
          };
        }));

        return {
          id: postDoc.id,
          ...postData,
          userName: userData?.name || 'Unknown',
          userPhotoURL: userData?.photoURL || '/images/default-avatar.png',
          createdAt: postData.createdAt ? postData.createdAt.toDate() : new Date(),
          replies,
        };
      })
    );
  };

  // リンクプレビューを取得するロジック
  const fetchLinkPreviews = async (posts) => {
    const previews = {};

    await Promise.all(
      posts.map(async (post) => {
        const linkMatch = post.text.match(/https?:\/\/[^\s]+/);
        if (linkMatch) {
          const previewData = await getLinkPreview(linkMatch[0]);
          previews[post.id] = previewData;
        }
      })
    );

    setLinkPreviews(previews);
  };

  const fetchMorePosts = async () => {
    if (!lastVisiblePost) return; // さらに読み込む投稿がない場合は終了

    const nextQuery = query(
      collection(db, 'posts'),
      orderBy('createdAt', 'desc'),
      startAfter(lastVisiblePost),
      limit(20)
    );
    const nextQuerySnapshot = await getDocs(nextQuery);

    const lastVisible = nextQuerySnapshot.docs[nextQuerySnapshot.docs.length - 1];
    setLastVisiblePost(lastVisible); // 次の最後の投稿を設定

    const newPosts = await processQuerySnapshot(nextQuerySnapshot);
    setAllPosts((prevPosts) => [...prevPosts, ...newPosts]); // 既存の投稿に追加
    setPosts((prevPosts) => [...prevPosts, ...newPosts]);

    // 新しい投稿のリンクプレビューを取得
    await fetchLinkPreviews(newPosts);
  };

  const applyFilter = () => {
    if (filterMode === 'all') {
      setPosts(allPosts);
    } else if (filterMode === 'myPosts') {
      setPosts(allPosts.filter(post => post.userId === user.uid));
    } else if (filterMode === 'commentedPosts') {
      setPosts(allPosts.filter(post => post.replies.some(reply => reply.userId === user.uid)));
    }
  };

  // 無限スクロール用のイベントリスナー
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 20 >=
        document.documentElement.offsetHeight
      ) {
        fetchMorePosts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastVisiblePost]);

  const handleLike = async (postId) => {
    const postRef = firestoreDoc(db, 'posts', postId);
    const postDoc = await getDoc(postRef);
    const currentLikes = postDoc.data().likes || [];
    const updatedLikes = currentLikes.includes(user.uid)
      ? currentLikes.filter((uid) => uid !== user.uid)
      : [...currentLikes, user.uid];

    await updateDoc(postRef, { likes: updatedLikes });
    fetchPosts();
  };

  const toggleReplyForm = (postId) => {
    setReplyFormVisible((prevState) => ({
      ...prevState,
      [postId]: !prevState[postId],
    }));
  };

  const toggleNestedReplyForm = (replyId) => {
    setNestedReplyFormVisible((prevState) => ({
      ...prevState,
      [replyId]: !prevState[replyId],
    }));
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    if (newPostText.length > 1000) {
      setErrorMessage('投稿は1000文字までです');
      return;
    }

    setLoading(true);
    setErrorMessage(''); // エラーメッセージをリセット

    let imageUrl = null;
    if (newPostImage) {
      try {
        imageUrl = await handleImageUpload(newPostImage);
      } catch (error) {
        console.error('Error uploading image:', error);
        setLoading(false);
        return;
      }
    }

    const newPost = {
      text: newPostText,
      image: imageUrl,
      likes: [],
      createdAt: new Date(),
      userId: user.uid,
    };

    await addDoc(collection(db, 'posts'), newPost);
    setNewPostText('');
    setNewPostImage(null);
    setImagePreview(null); // 投稿後にプレビューをクリア
    fetchPosts();
    setLoading(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewPostImage(file);
      setImagePreview(URL.createObjectURL(file)); // 画像プレビューのためのURLを作成
    } else {
      setNewPostImage(null);
      setImagePreview(null); // ファイルが選択されていない場合、プレビューをクリア
    }
  };

  const handleImageUpload = async (file) => {
    const storageRef = ref(storage, `timeline/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null,
        (error) => reject(error),
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleReplySubmit = async (postId) => {
    if (!replyText[postId]) return;

    const postRef = firestoreDoc(db, 'posts', postId);
    const newReply = {
      text: replyText[postId],
      userId: user.uid,
      createdAt: new Date(),
    };

    await addDoc(collection(postRef, 'replies'), newReply);
    setReplyText((prevState) => ({ ...prevState, [postId]: '' }));
    setReplyFormVisible((prevState) => ({ ...prevState, [postId]: false }));
    fetchPosts();
  };

  const handleNestedReplySubmit = async (replyId, postId) => {
    if (!nestedReplyText[replyId]) return;

    const postRef = firestoreDoc(db, 'posts', postId);
    const replyRef = firestoreDoc(postRef, 'replies', replyId);
    const newNestedReply = {
      text: nestedReplyText[replyId],
      userId: user.uid,
      createdAt: new Date(),
    };

    await addDoc(collection(replyRef, 'replies'), newNestedReply);
    setNestedReplyText((prevState) => ({ ...prevState, [replyId]: '' }));
    setNestedReplyFormVisible((prevState) => ({ ...prevState, [replyId]: false }));
    fetchPosts();
  };

  const handleReplyChange = (e, postId) => {
    setReplyText((prevState) => ({ ...prevState, [postId]: e.target.value }));
  };

  const handleNestedReplyChange = (e, replyId) => {
    setNestedReplyText((prevState) => ({ ...prevState, [replyId]: e.target.value }));
  };

  const handleDeletePost = async (postId) => {
    try {
      await deleteDoc(firestoreDoc(db, 'posts', postId));
      fetchPosts();
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleDeleteReply = async (postId, replyId) => {
    try {
      const postRef = firestoreDoc(db, 'posts', postId);
      const replyRef = firestoreDoc(postRef, 'replies', replyId);
      await deleteDoc(replyRef);
      fetchPosts();
    } catch (error) {
      console.error('Error deleting reply:', error);
    }
  };

  const handleDeleteNestedReply = async (postId, replyId, nestedReplyId) => {
    try {
      const postRef = firestoreDoc(db, 'posts', postId);
      const replyRef = firestoreDoc(postRef, 'replies', replyId);
      const nestedReplyRef = firestoreDoc(replyRef, 'replies', nestedReplyId);
      await deleteDoc(nestedReplyRef);
      fetchPosts();
    } catch (error) {
      console.error('Error deleting nested reply:', error);
    }
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
  };

  return (
    <div className="timeline-container">
      <div className="settings-header">
        <p className="settings-title">タイムライン</p>
      </div>

    {/* 投稿フォームが表示されるときのモーダルウィンドウ */}
    {isFormVisible && (
      <div className="timeline-modal-background" onClick={toggleFormVisibility}>
      <div className="timeline-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="timeline-modal-close-button" onClick={toggleFormVisibility}>
          &times; {/* 閉じるボタン */}
        </button>
        <form onSubmit={handlePostSubmit} className="post-form">
          {errorMessage && <p className="error-message-k">{errorMessage}</p>}
          <textarea
            className="timeline-auto-expand"
            placeholder="What's on your mind?"
            value={newPostText}
            onChange={(e) => {
              setNewPostText(e.target.value);
              autoExpand(e); // 高さを自動調整
            }}
          />
          <div className="file-input-container">
            <input
              type="file"
              id="file"
              className="custom-file-input"
              accept="image/*"
              onChange={handleImageChange}
            />
            <label htmlFor="file" className="custom-file-label">
              <FaImage size={20} />
            </label>

            {imagePreview && (
              <img src={imagePreview} alt="Preview" className="image-preview" />
            )}

            <button type="submit" disabled={loading} className="post-button">
              Post
            </button>
          </div>
        </form>

        <h7>誹謗中傷・差別的発言・卑猥な言動は削除されます</h7>
      </div>
    </div>
    )}

{/* 投稿ボタン（右下に固定） */}
<div className="other-member-profile-actions">
<button className="other-member-profile-button" onClick={toggleFormVisibility}>
  <FaPenToSquare size={25} />
</button></div>


      {/* フィルターボタン */}
      <div className="filter-buttons">
        <button onClick={() => setFilterMode('all')}>すべて</button>
        <button onClick={() => setFilterMode('myPosts')}>自分の投稿</button>
        <button onClick={() => setFilterMode('commentedPosts')}>コメント</button>
        <button onClick={() => setFilterMode('commentedPosts')}>メモ</button>
      </div>

      <div className="posts-container">
        {posts.map((post) => (
          <div key={post.id} className="post-item">
            <div className="post-header">
              <Link to={`/other_member_profile/${post.userId}`}>
                <img src={post.userPhotoURL} alt={post.userName} className="user-avatar" />
              </Link>
              <div>
                <p className="user-name">{post.userName}</p>
                <p className="post-date">{formatDate(post.createdAt)}</p>
              </div>
            </div>

            <p className="post-text">{post.text}</p>

            {/* リンクプレビュー表示 */}
            {linkPreviews[post.id] && (
              <div className="link-preview">
                <h3>{linkPreviews[post.id].title}</h3>
                <p>{linkPreviews[post.id].description}</p>
                <img src={linkPreviews[post.id].image} alt="Preview" />
              </div>
            )}

            {post.image && <img src={post.image} alt="Post" className="post-image" />}

            <div className="post-actions">
              <button onClick={() => handleLike(post.id)} className="like-button">
                <FaHeart /> {post.likes?.length || 0}
              </button>
              {post.userId !== user.uid && (
                <button onClick={() => toggleReplyForm(post.id)} className="reply-button">
                  <FaReply /> コメント
                </button>
              )}
              {post.userId === user.uid && (
                <button onClick={() => handleDeletePost(post.id)} className="delete-reply-button">
                  <FaTrash />
                </button>
              )}
            </div>

            {replyFormVisible[post.id] && (
              <div className="reply-form">
                <textarea
                  placeholder="Reply to this post..."
                  value={replyText[post.id] || ''}
                  onChange={(e) => handleReplyChange(e, post.id)}
                />
                <button onClick={() => handleReplySubmit(post.id)} className="reply-button">送信</button>
              </div>
            )}

            <div className="replies">
              {post.replies && post.replies.map(reply => (
                <div key={reply.id} className="reply-item">
                  <div className="reply-header">
                    <Link to={`/other_member_profile/${reply.userId}`}>
                      <img src={reply.replyUserPhotoURL} alt={reply.replyUserName} className="user-avatar" />
                    </Link>
                    <div>
                      <p className="reply-user-name">{reply.replyUserName}</p>
                      <p className="reply-date">{formatDate(reply.createdAt.toDate())}</p>
                    </div>
                  </div>
                  <p className="reply-text">{reply.text}</p>

                  <div className="post-actions">
                    {reply.userId === user.uid && (
                      <button onClick={() => handleDeleteReply(post.id, reply.id)} className="delete-reply-button">
                        <FaTrash /> 削除
                      </button>
                    )}
                  </div>

                  <div className="nested-replies">
                    {reply.nestedReplies && reply.nestedReplies.map(nestedReply => (
                      <div key={nestedReply.id} className="nested-reply-item">
                        <div className="reply-header">
                          <Link to={`/other_member_profile/${nestedReply.userId}`}>
                            <img src={nestedReply.userPhotoURL} alt={nestedReply.userName} className="user-avatar" />
                          </Link>
                          <div>
                            <p className="reply-user-name">{nestedReply.userName}</p>
                            <p className="reply-date">{formatDate(nestedReply.createdAt.toDate())}</p>
                          </div>
                        </div>
                        <p className="reply-text">{nestedReply.text}</p>

                        {nestedReply.userId === user.uid && (
                          <button onClick={() => handleDeleteNestedReply(post.id, reply.id, nestedReply.id)} className="delete-reply-button">
                            <FaTrash /> 削除
                          </button>
                        )}
                      </div>
                    ))}

                    {nestedReplyFormVisible[reply.id] && (
                      <div className="reply-form">
                        <textarea
                          placeholder="Reply to this reply..."
                          value={nestedReplyText[reply.id] || ''}
                          onChange={(e) => handleNestedReplyChange(e, reply.id)}
                        />
                        <button onClick={() => handleNestedReplySubmit(reply.id, post.id)} className="reply-button">送信</button>
                      </div>
                    )}
                  </div>

                  <div className="post-actions">
                    <button onClick={() => toggleNestedReplyForm(reply.id)} className="reply-button">
                      <FaReply /> 返信
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <MenuBar />
    </div>
  );
};

export default TimelinePage;
