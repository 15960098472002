import React, { useState } from 'react';
import { db, auth } from '../../firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import '../../styles/SettingInquiry.css';

const SettingInquiry = () => {
  const [user] = useAuthState(auth);
  const [inquiryTitle, setInquiryTitle] = useState('');
  const [inquiryContent, setInquiryContent] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSendInquiry = async () => {
    if (inquiryTitle.trim() === '' || inquiryContent.trim() === '') {
      setErrorMessage('タイトルとお問い合わせ内容を入力してください。');
      return;
    }

    try {
      await addDoc(collection(db, 'inquiries'), {
        userId: user.uid,
        title: inquiryTitle,
        content: inquiryContent,
        createdAt: Timestamp.now(),
        status: 'pending'
      });
      setInquiryTitle('');
      setInquiryContent('');
      setSuccessMessage('お問い合わせを送信しました。');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('お問い合わせの送信中にエラーが発生しました。');
      setSuccessMessage('');
      console.error('Error sending inquiry:', error);
    }
  };

  return (
    <div className="setting-inquiry-container">
      <div className="header">
      <Link to="/settings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">お問い合わせ</p>
      </div>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <input
        type="text"
        value={inquiryTitle}
        onChange={(e) => setInquiryTitle(e.target.value)}
        placeholder="タイトルを入力してください"
        className="profile-input"
      />
      <textarea
        value={inquiryContent}
        onChange={(e) => setInquiryContent(e.target.value)}
        placeholder="お問い合わせ内容を入力してください"
        rows="6"
        className="profile-textarea"
      />
      <button onClick={handleSendInquiry} className="save-button">送信</button>
    </div>
  );
};

export default SettingInquiry;
