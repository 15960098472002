import React from 'react';
import { useCall } from './CallContext';
import '../styles/CallModal.css';

const CallModal = () => {
  const { callIncoming, callInfo, answerCall, rejectCall } = useCall();

  if (!callIncoming) return null;

  return (
    <div className="call-modal">
      <div className="call-modal-content">
        <h2>Incoming Call</h2>
        <p>{callInfo.callerName}さんが通話してきました。</p>
        <button onClick={answerCall}>電話に出る</button>
        <button onClick={rejectCall}>拒否</button>
      </div>
    </div>
  );
};

export default CallModal;
