import React, { useState, useEffect, useRef } from 'react';
import { db, auth } from '../firebaseConfig'; 
import { collection, getDocs, query, where } from 'firebase/firestore'; 
import '../styles/YourGroup.css'; 
import '../styles/GroupCreate.css'; 
import { Link } from 'react-router-dom'; 
import MenuBar from '../components/MenuBar'; 
import { FaXTwitter, FaPlus, FaTwitter, FaLine } from "react-icons/fa6"; 
import { BsTwitterX } from "react-icons/bs";

const YourGroup = () => {
  const [participatingGroups, setParticipatingGroups] = useState([]);
  const [organizingGroups, setOrganizingGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(null); 
  const user = auth.currentUser; 
  const menuRef = useRef(null); 
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchUserGroups = async () => {
      if (!user) return;

      const groupCollection = collection(db, 'groups');

      const participantQuery = query(
        groupCollection,
        where('participants', 'array-contains', user.uid)
      );

      const participantSnapshot = await getDocs(participantQuery);
      const participantGroups = participantSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const organizerQuery = query(
        groupCollection,
        where('organizer', '==', user.uid)
      );

      const organizerSnapshot = await getDocs(organizerQuery);
      const organizerGroups = organizerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setParticipatingGroups(participantGroups);
      setOrganizingGroups(organizerGroups);
      setLoading(false);
    };

    fetchUserGroups();
  }, [user]);

  const toggleMenu = (groupId) => {
    if (menuOpen === groupId) {
      setMenuOpen(null);
    } else {
      setMenuOpen(groupId);
    }
  };

  const handleMenuAction = (action, groupId) => {
    console.log(`${action} for group ${groupId}`);
    setMenuOpen(null); 
  };

  const handleShareToX = (groupId, groupName) => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=Join%20our%20group%20${groupName}&url=https://yourapp.com/group/${groupId}`;
    window.open(twitterShareUrl, '_blank');
    console.log(`Sharing ${groupName} to X`);
  };

  const handleShareToLine = (groupId, groupName) => {
    const lineShareUrl = `https://social-plugins.line.me/lineit/share?url=https://yourapp.com/group/${groupId}`;
    window.open(lineShareUrl, '_blank');
    console.log(`Sharing ${groupName} to LINE`);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(null);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (loading) {
    return <p>読み込み中...</p>;
  }

  return (
    <div className="group-page-custom-container">
      <div className="settings-header">
        <p className="settings-title">グループ</p>
      </div>
      <div className="top-menu">
        <Link to="/your-group" className="top-menu-link">所属中</Link>
        <Link to="/group" className="top-menu-link">新しく探す</Link>
        <Link to="/invite-group" className="top-menu-link">招待</Link>
      </div>

      {participatingGroups.length === 0 ? (
        <p></p>
      ) : (
        <div className="your-group-grid">
          <h3>所属中のグループ</h3><br></br>
          {participatingGroups.map(group => (
            <div key={group.id} className="your-group-card">
              <img src={group.imageUrl || '/images/default-group.png'} alt={group.name} className="your-group-card-image" />
              <div className="your-group-card-content">
              <h3 className="your-group-card-title">{group.name}</h3>
              <BsTwitterX className="group-share-x-icon" onClick={() => handleShareToX(group.id, group.name)} />
                  <FaLine className="group-share-line-icon" onClick={() => handleShareToLine(group.id, group.name)} />

              </div>
 
                {/* "グループを見る"ボタン */}
                <Link to={`/group-content/${group.id}`} className="view-group-button">
                  グループを見る
                </Link>
              <div className="group-menu-icon" onClick={() => toggleMenu(group.id)}>
                ⋮
              </div>
              {menuOpen === group.id && (
                <div className="group-menu" ref={menuRef}>
                  <p onClick={() => handleMenuAction('詳細を見る', group.id)}>詳細を見る</p>
                  <p onClick={() => handleMenuAction('グループを退出', group.id)}>グループを退出</p>
                  <p onClick={() => handleMenuAction('その他の操作', group.id)}>その他の操作</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <br/><br/>

      {organizingGroups.length === 0 ? (
        <p></p>
      ) : (
        <div className="your-group-grid">
          <h3>管理中のグループ</h3><br></br>
          {organizingGroups.map(group => (
            <div 
  key={group.id} 
  className="your-group-card" 
  onClick={() => window.location.href = `/group-content/${group.id}`}
>
  <img 
    src={group.imageUrl || '/images/default-group.png'} 
    alt={group.name} 
    className="your-group-card-image"
    onClick={(e) => e.stopPropagation()} // クリックイベントのバブリングを防ぐ
  />
  
  <div className="your-group-card-content" onClick={(e) => e.stopPropagation()}>
    <h3 className="your-group-card-title">{group.name}</h3>

    <BsTwitterX 
      className="group-share-x-icon" 
      onClick={(e) => {
        e.stopPropagation(); 
        handleShareToX(group.id, group.name);
      }} 
    />
    
    <FaLine 
      className="group-share-line-icon" 
      onClick={(e) => {
        e.stopPropagation(); 
        handleShareToLine(group.id, group.name);
      }} 
    />
  </div>

  {/* "グループを見る"ボタン */}
  <Link 
    to={`/group-content/${group.id}`} 
    className="view-group-button" 
    onClick={(e) => e.stopPropagation()}
  >
    グループを見る
  </Link>

  <div 
    className="group-menu-icon" 
    onClick={(e) => {
      e.stopPropagation(); 
      toggleMenu(group.id);
    }}
  >
    ⋮
  </div>

  {menuOpen === group.id && (
    <div className="group-menu" ref={menuRef}>
      <p onClick={() => handleMenuAction('詳細を見る', group.id)}>詳細を見る</p>
      <p onClick={() => handleMenuAction('グループを削除', group.id)}>グループを削除</p>
      <p onClick={() => handleMenuAction('その他の操作', group.id)}>その他の操作</p>
    </div>
  )}
</div>

          ))}
        </div>
      )}
<div className="other-member-profile-actions">      
        {/* モーダル表示トリガーボタン */}
        <button className="other-member-profile-button" onClick={() => setShowModal(true)}>
          <FaPlus size={25} />
        </button>
      </div>
                  {/* モーダル表示 */}
                  {showModal && (
        <div className="modal-background" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>どちらを作成しますか？</h2>
            <div>
              <Link to="/create-group" className="group-create-modal-button">
                通常のグループ
              </Link>

            <Link to="/rindoku-group" className="group-create-modal-button">
                輪読に関するグループ
              </Link>
            </div>
            <button className="modal-close-button" onClick={() => setShowModal(false)}>×</button>
          </div>
        </div>
      )}

      <MenuBar />
    </div>
  );
};

export default YourGroup;
