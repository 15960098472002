import React, { useState, useEffect, useRef } from 'react';
import { db, storage } from '../firebaseConfig'; // Firebase configuration
import { collection, addDoc, getDocs, serverTimestamp, query, orderBy } from 'firebase/firestore'; 
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; 
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import '../styles/GroupPage.css'; 
import { Link } from 'react-router-dom'; 

const GroupCreate = () => {
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [groupType, setGroupType] = useState('public');
  const [groupGenre, setGroupGenre] = useState('学問'); // Initial genre value
  const [groupImage, setGroupImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [inviteLimitReached, setInviteLimitReached] = useState(false);
  const [showInviteSection, setShowInviteSection] = useState(false); // 新しい状態を追加

  const [filters, setFilters] = useState({
    gender: 'all',
    minAge: 18,
    maxAge: 70,
    major: 'all',
    location: 'all',
    sexualOrientation: 'all',
  });

  const [hasSearched, setHasSearched] = useState(false);

  const navigate = useNavigate();
  const modalRef = useRef(null);

  const handleCreateGroup = async (e) => {
    e.preventDefault();
  
    // 入力必須チェック
    if (!groupName.trim()) {
      alert('グループ名を入力してください');
      return;
    }
  
    if (!groupDescription.trim()) {
      alert('グループ概要を入力してください');
      return;
    }
  
    if (!groupType) {
      alert('グループタイプを選択してください');
      return;
    }
  
    if (!groupGenre) {
      alert('グループのジャンルを選択してください');
      return;
    }
  
    if (!groupImage) {
      alert('グループの画像をアップロードしてください');
      return;
    }
  
    setLoading(true);
    try {
      let imageUrl = '/images/default-group.png'; // デフォルト画像URL
      if (groupImage) {
        // ファイル名をランダムに生成
        const randomFileName = Math.random().toString(36).substring(2, 15);
        const storageRef = ref(storage, `groupImages/${randomFileName}`);
        const snapshot = await uploadBytes(storageRef, groupImage);
        imageUrl = await getDownloadURL(snapshot.ref);
      }
  
      // 現在のユーザーのUIDを取得
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        alert('ログインしてください');
        return;
      }
      const uid = user.uid;
  
      // Firestore にグループ情報を保存
      const groupRef = collection(db, 'groups');
      await addDoc(groupRef, {
        name: groupName,
        description: groupDescription,
        imageUrl,
        type: groupType,
        genre: groupGenre,
        participants: [], // 参加者の配列を初期化
        createdAt: serverTimestamp(),
        organizer: uid, // 現在のユーザーをオーガナイザーとして追加
        invitedMembers: selectedMembers.map(member => member.uid), // 招待されたユーザーを保存
      });
  
      alert('グループが作成されました');
      setGroupName('');
      setGroupDescription('');
      setGroupImage(null);
      setGroupGenre('');
      setSelectedMembers([]);
      setFilters({
        gender: 'all',
        minAge: 18,
        maxAge: 70,
        major: 'all',
        location: 'all',
        sexualOrientation: 'all',
      });
      setHasSearched(false);
      navigate('/groups');
    } catch (error) {
      console.error('グループ作成時のエラー:', error);
      alert('グループの作成に失敗しました');
    }
    setLoading(false);
  };
  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // サイズをMB単位に変換
  
      if (!['image/png', 'image/jpeg'].includes(fileType)) {
        alert('PNGまたはJPG形式の画像をアップロードしてください');
        return;
      }
  
      if (fileSize > 2) {
        alert('画像サイズは2MB以下にしてください');
        return;
      }
  
      setGroupImage(file);
    }
  };

  const handleSearchChange = (e) => {
    setSearchName(e.target.value.toLowerCase());
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevState => ({ ...prevState, [name]: value }));
  };

  const calculateAge = (birthday) => {
    if (!birthday) return null;
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    return age;
  };

  const applyFilters = async () => {
    try {
      const membersCollection = collection(db, 'members');
      const q = query(membersCollection, orderBy('use_date', 'desc'));
      const querySnapshot = await getDocs(q);
      let fetchedMembers = querySnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() }));

      const filteredMembers = fetchedMembers.filter(member => {
        const matchesName = searchName === '' ? true : (member.name && member.name.toLowerCase().includes(searchName));
        const matchesGender = filters.gender === 'all' || (member.gender && member.gender === filters.gender);
        const age = calculateAge(member.birthday);
        const matchesAge = age !== null && age >= filters.minAge && age <= filters.maxAge;
        const matchesMajor = filters.major === 'all' || (member.major && member.major === filters.major);
        const matchesLocation = filters.location === 'all' || (member.location && member.location === filters.location);
        const matchesSexualOrientation = filters.sexualOrientation === 'all' || (member.sexualOrientation && member.sexualOrientation === filters.sexualOrientation);
        return matchesName && matchesGender && matchesAge && matchesMajor && matchesLocation && matchesSexualOrientation;
      });

      setMembers(filteredMembers);
      setHasSearched(true);
    } catch (error) {
      console.error('Error fetching members:', error);
      alert('メンバーの取得に失敗しました');
    }
  };

  const handleSelectMember = (member) => {
    if (selectedMembers.length >= 10) {
      setInviteLimitReached(true);
      return;
    }
    if (!selectedMembers.some(m => m.uid === member.uid)) {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  const handleRemoveMember = (member) => {
    setSelectedMembers(selectedMembers.filter(m => m.uid !== member.uid));
    setInviteLimitReached(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setShowInviteSection(false);
      }
    };

    if (showInviteSection) {
      document.addEventListener('keydown', handleKeyDown);
      if (modalRef.current) {
        modalRef.current.focus();
      }
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showInviteSection]);

  return (
    <div className="group-page-custom-container">
      <div className="header">
        <Link to="/your-group" className="back-link-cont">←</Link>
        <p className="settings-title-cont">グループを作成</p>
      </div><br></br>

      <h5>※プライバシー設定でプライベートに設定すると、<br></br>グループ作成者が許可した人のみがグループに参加と閲覧可能です。</h5>
      <form className="group-create-custom-form" onSubmit={handleCreateGroup}>
        <div className="group-create-horizontal">
          <h3>1. グループ名：</h3>
          <input
            type="text"
            placeholder="グループ名"
            value={groupName}
            onChange={e => setGroupName(e.target.value)}
            className="group-page-custom-input"
          />
        </div>

        <div className="group-create-horizontal">
          <h3>2. グループ概要：</h3>
          <textarea
            placeholder="グループの概要を入力"
            value={groupDescription}
            onChange={e => setGroupDescription(e.target.value)}
            className="group-page-custom-input"
          />
        </div>

        <div className="group-create-horizontal">
          <h3>4. プライバシー設定：</h3>
          <label>
            <select value={groupType} onChange={e => setGroupType(e.target.value)} className="group-page-custom-select">
              <option value="public">公開</option>
              <option value="private">プライベート</option>
            </select>
          </label>
        </div>

        <div className="group-create-horizontal">
          <h3>5. ジャンル：</h3>
          <label>
            <select value={groupGenre} onChange={e => setGroupGenre(e.target.value)} className="group-page-custom-select">
              <option value="学問">学問</option>
              <option value="趣味">趣味</option>
              <option value="その他">その他</option>
            </select>
          </label>
        </div>

        <div className="group-create-horizontal">
          <h3>6. グループ画像：</h3>
          <label className="group-create-file-upload">
            選択
            <input type="file" onChange={handleImageChange} />
          </label>
          {groupImage && (
            <div className="group-create-image-preview">
              <h5>選択画像</h5>
              <img src={URL.createObjectURL(groupImage)} alt="選択された画像" />
            </div>
          )}
        </div>

        <div className="group-create-horizontal">
          <h3>7. メンバーを招待(任意)：</h3>
          <button type="button" onClick={() => setShowInviteSection(true)} className="group-create-file-upload">
            ＋招待
          </button>
        </div>
        
        {selectedMembers.length > 0 && (
          <>
            <h5>招待したメンバー:</h5>
            <ul className="group-create-modal-selected-members">
              {selectedMembers.map(member => (
                <li key={member.uid} className="group-create-modal-selected-member">
                  {member.name} 
                  <button onClick={() => handleRemoveMember(member)} className="group-create-modal-remove-button">削除</button>
                </li>
              ))}
            </ul>
          </>
        )}

        <div className="button-container">
          <button type="submit" disabled={loading} className="save-button">
            {loading ? '作成中...' : '作成'}
          </button>
        </div>
      </form>

      {showInviteSection && (
        <div className="group-create-modal-overlay" onClick={() => setShowInviteSection(false)}>
          <div
            className="group-create-modal-content"
            onClick={(e) => e.stopPropagation()}
            ref={modalRef}
            tabIndex="-1"
            role="dialog"
            aria-modal="true"
            aria-labelledby="invite-modal-title"
          >
            <button className="group-create-modal-close-button" onClick={() => setShowInviteSection(false)}>×</button>
            
            {selectedMembers.length > 0 && (
              <>
                <h4>招待したメンバー:</h4>
                <ul className="group-create-modal-selected-members">
                  {selectedMembers.map(member => (
                    <li key={member.uid} className="group-create-modal-selected-member">
                      {member.name} 
                      <button onClick={() => handleRemoveMember(member)} className="group-create-modal-remove-button">削除</button>
                    </li>
                  ))}
                </ul>
              </>
            )}
            
            <h3 id="invite-modal-title">メンバーを招待</h3>

            <div className="group-create-modal-filters">
              <label>名前:</label><br></br>
              <input
                type="text"
                placeholder="名前で検索"
                value={searchName}
                onChange={handleSearchChange}
                className="group-create-modal-input"
              />

              <div className="group-create-modal-form-group">
                <label>性別:</label>
                <select name="gender" value={filters.gender} onChange={handleFilterChange} className="group-create-modal-select">
                  <option value="all">すべて</option>
                  <option value="男性">男性</option>
                  <option value="女性">女性</option>
                  <option value="ノンバイナリー">ノンバイナリー</option>
                  <option value="トランスジェンダー">トランスジェンダー</option>
                  <option value="その他">その他</option>
                </select>
              </div>

              <div className="group-create-modal-form-group">
                <label>年齢:</label>
                <div className="group-create-modal-age-group">
                  <select name="minAge" value={filters.minAge} onChange={handleFilterChange} className="group-create-modal-select">
                    {Array.from({ length: 53 }, (_, i) => i + 18).map(age => (
                      <option key={age} value={age}>{age}</option>
                    ))}
                  </select>
                  <span>から</span>
                  <select name="maxAge" value={filters.maxAge} onChange={handleFilterChange} className="group-create-modal-select">
                    {Array.from({ length: 53 }, (_, i) => i + 18).map(age => (
                      <option key={age} value={age}>{age}</option>
                    ))}
                  </select>
                  <span>まで</span>
                </div>
              </div>

              <div className="group-create-modal-form-group">
                <label>居住地:</label>
                <select name="location" value={filters.location} onChange={handleFilterChange} className="group-create-modal-select">
                  <option value="all">すべて</option>
                  {/* その他の地域オプション */}
                </select>
              </div>

              <div className="group-create-modal-form-group">
                <label>専攻:</label>
                <select name="major" value={filters.major} onChange={handleFilterChange} className="group-create-modal-select">
                  <option value="all">すべて</option>
                  {/* その他の専攻オプション */}
                </select>
              </div>

              <button type="button" onClick={applyFilters} className="group-create-modal-apply-filters-button">メンバーを検索</button>
            </div>

            {inviteLimitReached && <p style={{ color: 'red' }}>招待できるのは10人までです。</p>}

            {hasSearched && (
              <div className="group-create-modal-member-list">
                {members.length > 0 ? (
                  members.map(member => (
                    <div key={member.uid} className="group-create-modal-member-card">
                      <img src={member.photoURL || '/images/default.png'} alt={member.name} className="group-create-modal-member-avatar" />
                      <div className="group-create-modal-member-details">
                        <p>{member.name}（{member.location}・{member.major}）</p>
                        <button 
                          onClick={() => handleSelectMember(member)} 
                          disabled={selectedMembers.some(m => m.uid === member.uid) || inviteLimitReached}
                          className="group-create-modal-invite-button"
                        >
                          招待する
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>該当するメンバーが見つかりません。</p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupCreate;
