import React, { useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth } from '../firebaseConfig'; // dbとauthをインポート

const UpdateUseDate = () => {
  const [user] = useAuthState(auth);

  useEffect(() => {
    const updateUseDate = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, 'members', user.uid);
          await updateDoc(userDocRef, {
            use_date: new Date() // 現在の時刻に更新
          });
          console.log('Use date updated successfully.');
        } catch (error) {
          console.error('Error updating use_date:', error);
        }
      }
    };

    updateUseDate();
  }, [user]);

  return null;
};

export default UpdateUseDate;
