import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import '../styles/Settings.css';
import MenuBar from '../components/MenuBar';

const Settings = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    if (window.confirm('ログアウトしますか？')) {
      auth.signOut().then(() => {
        navigate('/login');
      }).catch((error) => {
        console.error('Logout error:', error);
      });
    }
  };

  return (
    <div className="settings-container">
            <div className="header">
      <Link to="/settings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">アカウント設定</p>
      </div>
      <div className="settings-list">
        <Link to="/settingpush" className="settings-item">
          <span className="settings-item-text">プッシュ通知</span>
        </Link>
        <Link to="/settingmail" className="settings-item">
          <span className="settings-item-text">メールアドレス変更</span>
        </Link>
        <Link to="/settingpass" className="settings-item">
          <span className="settings-item-text">パスワード変更</span>
        </Link>
        <div onClick={handleLogout} className="settings-item">
          <span className="settings-item-text">ログアウト</span>
        </div>
        <Link to="/settingaccountdelete" className="settings-item">
          <span className="settings-item-text">アカウント削除</span>
        </Link>
      </div>
      <MenuBar />
    </div>
  );
};

export default Settings;
