import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, getDoc, addDoc, collection } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../styles/OtherMemberReport.css';

const OtherMemberReport = () => {
  const { id } = useParams();
  const [user] = useAuthState(auth);
  const [reportedUser, setReportedUser] = useState(null);
  const [reason, setReason] = useState('');
  const [details, setDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReportedUser = async () => {
      const docRef = doc(db, 'members', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setReportedUser(docSnap.data());
      }
    };

    fetchReportedUser();
  }, [id]);

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleDetailsChange = (event) => {
    setDetails(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!reason) {
      alert('報告理由を選択してください。');
      return;
    }
    if (!details.trim()) {
      alert('詳細を入力してください。');
      return;
    }

    setLoading(true);

    try {
      await addDoc(collection(db, 'reports'), {
        reporter: user.uid,
        reportedUser: id,
        reason,
        details,
        status: 'pending', // ここにstatusを追加
        createdAt: new Date()
      });
      alert('報告が正常に送信されました。');
      navigate(`/other_member_profile/${id}`);
    } catch (error) {
      console.error('Error reporting user:', error.message);
      alert('報告の送信中にエラーが発生しました。');
    } finally {
      setLoading(false);
    }
  };

  if (!reportedUser) return <div>Loading...</div>;

  return (
    <div className="report-container">
      <h2>ユーザーを報告する</h2>
      <div className="reported-user-info">
        <img src={reportedUser.photoURL || '/images/default.png'} alt="Profile" className="reported-user-image" />
        <p className="reported-user-name">{reportedUser.name || '--'}</p>
      </div>
      <form onSubmit={handleSubmit} className="report-form">
        <div className="form-group">
          <label htmlFor="reason">報告理由:</label>
          <select id="reason" value={reason} onChange={handleReasonChange}>
            <option value="">選択してください</option>
            <option value="inappropriate_behavior">不適切な行動</option>
            <option value="fake_profile">偽のプロフィール</option>
            <option value="spam">スパム</option>
            <option value="harassment">嫌がらせ</option>
            <option value="other">その他</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="details">詳細:</label>
          <textarea
            id="details"
            value={details}
            onChange={handleDetailsChange}
            placeholder="報告の詳細を入力してください"
          ></textarea>
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? '送信中...' : '送信'}
        </button>
      </form>
    </div>
  );
};

export default OtherMemberReport;
