import React, { useState, useEffect } from 'react';

const AddToHomeScreen = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIosDevice = /iphone|ipad|ipod/.test(userAgent);

    if (isIosDevice) {
      setIsIos(true);
      setIsVisible(true);
    } else {
      const handler = (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
        setIsVisible(true);
      };

      window.addEventListener('beforeinstallprompt', handler);

      return () => {
        window.removeEventListener('beforeinstallprompt', handler);
      };
    }
  }, []);

  const handleAddToHomeScreen = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
        setIsVisible(false);
      });
    }
  };

  return (
    isVisible && (
      <div className="add-to-home-screen-container">
        {isIos ? (
          <div className="add-to-home-screen-guide">
          </div>
        ) : (
          <button onClick={handleAddToHomeScreen} className="add-to-home-screen-button">
            Add to Home Screen
          </button>
        )}
      </div>
    )
  );
};

export default AddToHomeScreen;
