import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebaseConfig';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import '../../styles/SettingPass.css';

const SettingPass = () => {
  const [user] = useAuthState(auth);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isEmailProvider, setIsEmailProvider] = useState(false);

  useEffect(() => {
    if (user && user.providerData) {
      const emailProvider = user.providerData.some(
        (provider) => provider.providerId === 'password'
      );
      setIsEmailProvider(emailProvider);
    }
  }, [user]);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError('新しいパスワードが一致しません。');
      return;
    }

    if (!user || !user.email) {
      setError('ユーザー情報が取得できません。');
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, newPassword);
      setSuccess('パスワードが更新されました。');
    } catch (error) {
      setError('パスワードの更新に失敗しました: ' + error.message);
    }
  };

  return (
    <div className="setting-pass-container">
            <div className="header">
      <Link to="/accountsettings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">メールアドレス変更</p>
      </div>
      {isEmailProvider ? (
        <form onSubmit={handleChangePassword} className="setting-pass-form">
          <div className="form-group">
            <label>現在のパスワード</label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>新しいパスワード</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>新しいパスワード（確認用）</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submit-button">更新する</button>
        </form>
      ) : (
        <div className="error-message">メールアドレス認証のユーザーのみパスワードを変更できます。</div>
      )}
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
    </div>
  );
};

export default SettingPass;
