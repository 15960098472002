import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, query, orderBy, onSnapshot, getDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../../styles/SettingNotification.css';
import { Link } from 'react-router-dom';

const SettingNotification = () => {
  const [user] = useAuthState(auth);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const q = query(collection(db, `members/${user.uid}/notifications`), orderBy('createdAt', 'desc'));

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const notifData = await Promise.all(
          querySnapshot.docs.map(async (docSnapshot) => {
            const notification = { id: docSnapshot.id, ...docSnapshot.data() };
            const userNotifDocRef = doc(db, `members/${user.uid}/notifications`, docSnapshot.id);
            const userNotifDoc = await getDoc(userNotifDocRef);
            notification.read = userNotifDoc.exists() ? userNotifDoc.data().read : false;
            return notification;
          })
        );
        setNotifications(notifData);
      });

      return () => unsubscribe();
    }
  }, [user]);

  const handleNotificationClick = (id) => {
    navigate(`/notification/${id}`);
  };

  return (
    <div className="notifications-container">
      <div className="header">
        <Link to="/settings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">お知らせ</p>
      </div>
      {notifications.length === 0 ? (
        <p>現在、お知らせはありません。</p>
      ) : (
        <ul className="notification-content-container">
          {notifications.map((notification) => (
            <li key={notification.id} className="notification-item-a" onClick={() => handleNotificationClick(notification.id)}>
              <div className="notification-content">
                <span className="notification-date">
                  {new Date(notification.createdAt.toDate()).toLocaleDateString('ja-JP', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </span>
                <h3>
                  {!notification.read && <div className="unread-tag"></div>}
                  {notification.title}
                </h3>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SettingNotification;
