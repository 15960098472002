import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebaseConfig';
import { collection, addDoc, query, where, getDocs, setDoc, doc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../../styles/AdminNotification.css';

const AdminNotification = () => {
  const [user, loading] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationContent, setNotificationContent] = useState('');
  const [notificationUserId, setNotificationUserId] = useState('');
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.admin) {
          setIsAdmin(true);
          fetchNotifications();
        } else {
          setIsAdmin(false);
          navigate('/'); // 管理者でない場合はリダイレクト
        }
      }
    };

    if (!loading) {
      checkAdmin();
    }
  }, [user, loading, navigate]);

  const fetchNotifications = async () => {
    setLoadingData(true);
    const q = query(collection(db, 'notifications'));
    const querySnapshot = await getDocs(q);
    const notifData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    setNotifications(notifData);
    setLoadingData(false);
  };

  const handleSendNotification = async () => {
    if (notificationTitle.trim() === '' || notificationContent.trim() === '') return;

    const notificationData = {
      title: notificationTitle,
      content: notificationContent,
      createdAt: new Date(),
      read: false
    };

    try {
      if (notificationUserId.trim() !== '') {
        // Specific user notification
        const userNotificationRef = doc(db, `members/${notificationUserId.trim()}/notifications`, `${new Date().getTime()}`);
        await setDoc(userNotificationRef, notificationData);
      } else {
        // Broadcast notification to all users
        const membersQuerySnapshot = await getDocs(collection(db, 'members'));
        membersQuerySnapshot.forEach(async (memberDoc) => {
          const userNotificationRef = doc(db, `members/${memberDoc.id}/notifications`, `${new Date().getTime()}`);
          await setDoc(userNotificationRef, notificationData);
        });
      }

      setNotificationTitle('');
      setNotificationContent('');
      setNotificationUserId('');
      fetchNotifications(); // 更新後に再読み込み
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  if (loading || loadingData) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <div>Access denied. You do not have permission to view this page.</div>;
  }

  return (
    <div className="admin-notification-container">
      <h2>Send Notifications</h2>
      <div className="notification-form">
        <input
          type="text"
          value={notificationTitle}
          onChange={(e) => setNotificationTitle(e.target.value)}
          placeholder="Enter notification title"
        />
        <textarea
          value={notificationContent}
          onChange={(e) => setNotificationContent(e.target.value)}
          placeholder="Enter notification content"
          rows="4"
        />
        <input
          type="text"
          value={notificationUserId}
          onChange={(e) => setNotificationUserId(e.target.value)}
          placeholder="Enter user ID (optional)"
        />
        <button onClick={handleSendNotification}>Send</button>
      </div>
      <h2>Sent Notifications</h2>
      {notifications.length === 0 ? (
        <div>No notifications sent.</div>
      ) : (
        <ul>
          {notifications.map((notification) => (
            <li key={notification.id} className="notification-item">
              <h3>{notification.title}</h3>
              <p style={{ whiteSpace: 'pre-wrap' }}>{notification.content}</p>
              <span className="notification-date">
                {new Date(notification.createdAt.toMillis()).toLocaleString()}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AdminNotification;
