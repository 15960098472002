import React, { useState, useEffect } from 'react';
import { auth, provider, db } from '../firebaseConfig';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import '../styles/Login.css';
import '../styles/LandingPage.css'; // CSSファイルをインポート

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      updateLoginDate(user);
      checkUserProfile(user);
    }
  }, [user]);

  const checkUserProfile = async (user) => {
    const docRef = doc(db, 'members', user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const profile = docSnap.data();
      if (!profile.name || !profile.gender || !profile.location || !profile.birthday || !profile.major) {
        navigate('/complete-profile');
      } else {
        navigate('/member_search');
      }
    } else {
      navigate('/complete-profile');
    }
  };

  const updateLoginDate = async (user) => {
    const docRef = doc(db, 'members', user.uid);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, { login_date: new Date() });
      } else {
        await setDoc(docRef, { login_date: new Date() }, { merge: true });
      }
    } catch (error) {
      console.error('Error updating login date:', error);
    }
  };

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        setUser(user);
      } else {
        setError('メールアドレスの確認が完了していません。メールを確認してください。');
      }
    } catch (error) {
      console.error("Error logging in with email and password", error);
      setError('ログインに失敗しました。メールアドレスまたはパスワードが正しくありません。');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (user) {
        setUser(user);
      }
    } catch (error) {
      console.error("Error logging in with Google", error);
      setError('Googleでのログインに失敗しました。');
    }
  };

  return (
    <div className="login-container">
      <header className="header-top">
        <div className="logo">
          <Link to="/"><img src="/images/default1.png" alt="Logo" /></Link>
          院生マッチ
        </div>
      </header>
      <h2 className="login-title">ログイン</h2>
      {error && <p className="login-error">{error}</p>}
      <input type="email" placeholder="メールアドレス" onChange={(e) => setEmail(e.target.value)} className="login-input" />
      <input type="password" placeholder="パスワード" onChange={(e) => setPassword(e.target.value)} className="login-input" />
      <button onClick={handleLogin} className="login-button">ログイン</button>
      <button onClick={handleGoogleLogin} className="login-google-button">
        <FaGoogle className="google-icon" />
        Googleでログイン
      </button>
      <p className="login-signup-link"><Link to="/signup">会員登録はこちら</Link></p>
      <p className="login-signup-link"><Link to="/reset-password">パスワード忘れた方</Link></p>
    </div>
  );
};

export default Login;
