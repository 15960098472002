import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import '../styles/CompleteProfile.css';

const CompleteProfile = () => {
  const [user] = useAuthState(auth);
  const [profile, setProfile] = useState({
    name: '',
    birthdayYear: '',
    birthdayMonth: '',
    birthdayDay: '',
    gender: '',
    location: '',
    major: ''
  });
  const [error, setError] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const navigate = useNavigate();

  const yearOptions = Array.from({ length: 100 }, (_, i) => ({ value: 2023 - i, label: `${2023 - i}` }));
  const monthOptions = Array.from({ length: 12 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));
  const dayOptions = Array.from({ length: 31 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));
  const genderOptions = [
    { value: '男性', label: '男性' },
    { value: '女性', label: '女性' },
    { value: 'ノンバイナリー', label: 'ノンバイナリー' },
    { value: 'トランスジェンダー', label: 'トランスジェンダー' },
    { value: 'その他', label: 'その他' },
    { value: '回答しない', label: '回答しない' },    
  ];
  const locationOptions = [
    { value: '北海道', label: '北海道' },
    { value: '青森', label: '青森' },
    { value: '岩手', label: '岩手' },
    { value: '宮城', label: '宮城' },
    { value: '秋田', label: '秋田' },
    { value: '山形', label: '山形' },
    { value: '福島', label: '福島' },
    { value: '茨城', label: '茨城' },
    { value: '栃木', label: '栃木' },
    { value: '群馬', label: '群馬' },
    { value: '埼玉', label: '埼玉' },
    { value: '千葉', label: '千葉' },
    { value: '東京', label: '東京' },
    { value: '神奈川', label: '神奈川' },
    { value: '新潟', label: '新潟' },
    { value: '富山', label: '富山' },
    { value: '石川', label: '石川' },
    { value: '福井', label: '福井' },
    { value: '山梨', label: '山梨' },
    { value: '長野', label: '長野' },
    { value: '岐阜', label: '岐阜' },
    { value: '静岡', label: '静岡' },
    { value: '愛知', label: '愛知' },
    { value: '三重', label: '三重' },
    { value: '滋賀', label: '滋賀' },
    { value: '京都', label: '京都' },
    { value: '大阪', label: '大阪' },
    { value: '兵庫', label: '兵庫' },
    { value: '奈良', label: '奈良' },
    { value: '和歌山', label: '和歌山' },
    { value: '鳥取', label: '鳥取' },
    { value: '島根', label: '島根' },
    { value: '岡山', label: '岡山' },
    { value: '広島', label: '広島' },
    { value: '山口', label: '山口' },
    { value: '徳島', label: '徳島' },
    { value: '香川', label: '香川' },
    { value: '愛媛', label: '愛媛' },
    { value: '高知', label: '高知' },
    { value: '福岡', label: '福岡' },
    { value: '佐賀', label: '佐賀' },
    { value: '長崎', label: '長崎' },
    { value: '熊本', label: '熊本' },
    { value: '大分', label: '大分' },
    { value: '宮崎', label: '宮崎' },
    { value: '鹿児島', label: '鹿児島' },
    { value: '沖縄', label: '沖縄' },
    { value: 'その他', label: 'その他' },
  ];
  const majorOptions = [
    { value: '数学', label: '数学' },
    { value: '物理学', label: '物理学' },
    { value: '化学', label: '化学' },
    { value: '生物', label: '生物' },
    { value: '地球・惑星', label: '地球・惑星' },
    { value: '機械', label: '機械' },
    { value: 'システム制御', label: 'システム制御' },
    { value: '電気・電子', label: '電気・電子' },
    { value: '農学', label: '農学' },
    { value: '材料', label: '材料' },
    { value: '応用化学', label: '応用化学' },
    { value: '薬学', label: '薬学' },
    { value: '医療', label: '医療' },
    { value: '情報工学', label: '情報工学' },
    { value: '知能・情報', label: '知能・情報' },
    { value: '建築', label: '建築' },
    { value: '都市・環境学', label: '都市・環境学' },
    { value: '土木工学', label: '土木工学' },
    { value: '商学', label: '商学' },
    { value: '経済学', label: '経済学' },
    { value: '法学', label: '法学' },
    { value: '社会学', label: '社会学' },
    { value: '文学', label: '文学' },
    { value: '外国語', label: '外国語' },
    { value: '心理学', label: '心理学' },
    { value: '教育', label: '教育' },
    { value: '哲学', label: '哲学' },
    { value: '宗教学', label: '宗教学' },
    { value: '政治学', label: '政治学' },
    { value: '歴史学', label: '歴史学' },
    { value: '芸術学', label: '芸術学' },
    { value: 'デザイン', label: 'デザイン' },
    { value: '音楽学', label: '音楽学' },
    { value: '作曲', label: '作曲' },
    { value: '音楽教育', label: '音楽教育' },
    { value: '声楽', label: '声楽' },
    { value: '器楽', label: '器楽' },
    { value: '音響学', label: '音響学' },
    { value: '演劇学', label: '演劇学' },
    { value: '映像学', label: '映像学' },
    { value: '美術', label: '美術' },
    { value: '舞踊学', label: '舞踊学' },
    { value: 'その他', label: 'その他' },
    
    // 他の専攻オプションも追加
  ];

  useEffect(() => {
    if (user) {
      user.reload().then(() => {
        setEmailVerified(user.emailVerified);
      });

      const fetchProfile = async () => {
        try {
          const docRef = doc(db, 'members', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setProfile({
              name: data.name || '',
              birthdayYear: data.birthday ? new Date(data.birthday).getFullYear() : '',
              birthdayMonth: data.birthday ? new Date(data.birthday).getMonth() + 1 : '',
              birthdayDay: data.birthday ? new Date(data.birthday).getDate() : '',
              gender: data.gender || '',
              location: data.location || '',
              major: data.major || ''
            });
          }
        } catch (error) {
          console.error('Error fetching profile:', error.message);
          setError('プロフィールの取得中にエラーが発生しました。');
        }
      };
      fetchProfile();
    }
  }, [user]);

  const handleSave = async () => {
    if (!emailVerified) {
      setError('メールアドレスの認証が完了していません。メールを確認してください。');
      return;
    }

    const { name, birthdayYear, birthdayMonth, birthdayDay, gender, location, major } = profile;

    if (name && birthdayYear && birthdayMonth && birthdayDay && gender && location && major) {
      const birthday = new Date(birthdayYear, birthdayMonth - 1, birthdayDay);
      if (isNaN(birthday.getTime())) {
        setError('生年月日が無効です。');
        return;
      }

      try {
        const userDocRef = doc(db, 'members', user.uid);
        const docSnap = await getDoc(userDocRef);

        const profileData = {
          name,
          birthday: birthday.toISOString(),
          gender,
          location,
          major
        };

        if (docSnap.exists()) {
          await updateDoc(userDocRef, profileData);
        } else {
          await setDoc(userDocRef, profileData);
        }

        console.log('Profile saved successfully:', profileData); // デバッグ用ログ
        navigate('/member_search');
      } catch (error) {
        console.error('Error saving profile:', error.message);
        setError('プロフィールの保存中にエラーが発生しました。');
      }
    } else {
      setError('すべての項目を入力してください。');
    }
  };

  const handleChange = (selectedOption, actionMeta) => {
    setProfile({
      ...profile,
      [actionMeta.name]: selectedOption ? selectedOption.value : ''
    });
  };

  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  return (
    <div className="complete-profile-container">
      <h2>プロフィールを完成させる</h2>
      {error && <p className="error-message">{error}</p>}
      {emailVerified ? (
        <>
         <label className="birthday-label">お名前（７文字まで）：</label>
          <input
            type="text"
            name="name"
            value={profile.name}
            onChange={(e) => setProfile({ ...profile, name: e.target.value })}
            placeholder="名前"
            required
            className="complete-profile-input"
          />
          <label className="birthday-label">生年月日:<br></br>※ユーザーに表示されません</label>
          <div className="birthday-container">
            <Select
              name="birthdayYear"
              value={yearOptions.find(option => option.value === profile.birthdayYear)}
              onChange={handleChange}
              options={yearOptions}
              placeholder="年"
              className="birthday-select"
            />
            <Select
              name="birthdayMonth"
              value={monthOptions.find(option => option.value === profile.birthdayMonth)}
              onChange={handleChange}
              options={monthOptions}
              placeholder="月"
              className="birthday-select"
            />
            <Select
              name="birthdayDay"
              value={dayOptions.find(option => option.value === profile.birthdayDay)}
              onChange={handleChange}
              options={dayOptions}
              placeholder="日"
              className="birthday-select"
            />
          </div>
          <label className="birthday-label">性別：</label>
          <Select
            name="gender"
            value={genderOptions.find(option => option.value === profile.gender)}
            onChange={handleChange}
            options={genderOptions}
            placeholder="性別を選択"
            className="complete-profile-select"
          />
          <label className="birthday-label">居住地：</label>
          <Select
            name="location"
            value={locationOptions.find(option => option.value === profile.location)}
            onChange={handleChange}
            options={locationOptions}
            placeholder="居住地を選択"
            className="complete-profile-select"
          />
          <label className="birthday-label">専攻：</label>
          <Select
            name="major"
            value={majorOptions.find(option => option.value === profile.major)}
            onChange={handleChange}
            options={majorOptions}
            placeholder="専攻を選択"
            className="complete-profile-select"
          />
          <button onClick={handleSave} className="complete-profile-button">保存</button>
        </>
      ) : (
        <p>メールアドレスの認証が完了していません。メールを確認してください。</p>
      )}
    </div>
  );
};

export default CompleteProfile;
