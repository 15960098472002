import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../styles/BookSearchPage.css';
import MenuBar from '../components/MenuBar';
import { IoIosStar } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const MyBooks = () => {  // コンポーネント名をMyBooksに変更
  const [user] = useAuthState(auth);
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();  // useNavigateフックを使ってページ遷移を管理

  useEffect(() => {
    fetchFavoritesFromFirestore();
  }, [user]);

  const fetchFavoritesFromFirestore = async () => {
    if (!user) return;
    setLoading(true);
    try {
      const q = query(collection(db, 'favorites'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const favoriteItems = querySnapshot.docs
        .map((docSnapshot) => ({
          docid: docSnapshot.id,
          ...docSnapshot.data(),
        }))
        .filter((item) => item.participants && item.participants.includes(user.uid)); // 自分のお気に入りのみ表示
      setFavorites(favoriteItems);
    } catch (error) {
      setError('Error fetching favorites');
    } finally {
      setLoading(false);
    }
  };

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  // クリックでcontentページに移動する関数
  const handleItemClick = (docid) => {
    navigate(`/content/${docid}`);  // 選択されたdocidに基づいてページ遷移
  };

  return (
    <div className="custom-search-container">
      <div className="settings-header">
        <p className="settings-title">　学術書・作品を登録</p>
      </div>

      {/* 自分のお気に入りの表示 */}
      {favorites.length > 0 && (
        <div className="custom-results-list">
          <h4>自分のお気に入り</h4>
          <div className="custom-results-list-content">
            {favorites.map((favorite) => {
              const hasValidPoster = favorite.Poster && favorite.Poster !== 'N/A';
              const hasThumbnail = favorite.volumeInfo?.imageLinks?.thumbnail;
              const hasArtwork = favorite.artworkUrl100;

              const hasImage = hasValidPoster || hasThumbnail || hasArtwork;

              return (
                <div 
                  key={favorite.docid} 
                  className="custom-result-item" 
                  onClick={() => handleItemClick(favorite.docid)}  // クリックイベントでページ遷移
                  style={{ cursor: 'pointer' }}  // クリックできることがわかるようにポインタに変更
                >
                  {/* 画像がある場合のみ表示 */}
                  {hasValidPoster && (
                    <img
                      src={favorite.Poster}
                      alt={favorite.Title || favorite.volumeInfo?.title || favorite.trackName || favorite.title}
                    />
                  )}
                  {hasThumbnail && !hasValidPoster && (
                    <img
                      src={favorite.volumeInfo.imageLinks.thumbnail}
                      alt={favorite.Title || favorite.volumeInfo?.title || favorite.trackName || favorite.title}
                    />
                  )}
                  {hasArtwork && !hasValidPoster && !hasThumbnail && (
                    <img
                      src={favorite.artworkUrl100}
                      alt={favorite.Title || favorite.volumeInfo?.title || favorite.trackName || favorite.title}
                    />
                  )}
                  {/* タイトルや作者の表示 */}
                  <h3>{truncateText(favorite.Title || favorite.volumeInfo?.title || favorite.trackName || favorite.title, 19)}</h3>
                  <p>{truncateText(favorite.volumeInfo?.authors?.join(', ') || favorite.artistName || favorite.Director, 25)}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {error && <div className="custom-error">{error}</div>}
      {loading && <div className="custom-loading">Loading...</div>}

      <MenuBar />
    </div>
  );
};

export default MyBooks;
