import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto', textAlign: 'left'}}>
      <h1>プライバシーポリシー</h1>
      <p>院生マッチ（以下、「当社」といいます。）は、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）におけるユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
</p>

      <h2>第１条：お客様に関して収集する情報</h2>
      <p>「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、
        当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先、その他の記述等により特定の個人を識別できる情報、
        容貌、指紋、声紋にかかるデータ、学生証や健康保険証の保険者番号など、当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。</p>

      <h2>第2条：お客様に関する情報の収集方法</h2>
      <p>当社は、ユーザーが利用登録や年齢確認を行う際に、氏名、生年月日、メールアドレス、所属大学院名、専攻などの個人情報をお尋ねすることがあります。</p>

      <h2>第3条：お客様情報の利用方法</h2>
      <p>当社は、お客様情報を主として以下の目的で利用します。当社は、事前のお客様の同意がない限り、以下の目的を超えてお客様情報を利用しません。なお、これらの目的において、後記の提供先にお客様情報を提供することがあります。<br></br><br></br>

- 本サービスの円滑な運営、提供<br></br>
- 本サービスの登録、アカウント管理とカスタマーサポート（アフターサービス、各種お問い合わせ、苦情等への対応）の提供<br></br>
- 本サービスの改善や開発等による品質の向上<br></br>
- メンテナンス、重要なお知らせなど必要に応じたご連絡のため<br></br>
- 学生証や健康保険証による年齢確認の実施および不正利用の防止<br></br>
- 利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため<br></br>
- ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）<br></br>
- 本サービスと他のウェブサイトでお客様の興味に合うよう調整されたコンテンツ及び広告の作成と表示<br></br>
- 契約や法律等に基づく義務の履行や権利の行使（料金請求など）<br></br>
- システムの維持、不具合への対応、その他技術又は運営上必要な事業の管理<br></br>
- その他、当社サービスに付随・関連する業務の遂行のため<br></br></p>
     
<h2>第4条：個人情報の第三者提供</h2>
<p>運営者は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。<br></br><br></br>

1. 法令に基づく場合<br></br>
2. 人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき<br></br>
3. 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき<br></br>
4. 国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき<br></br>
5. 運営者が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合（主に年齢確認、クレジット決済、カスタマーサポート業務において外部委託を行っています）<br></br>
6. 運営者が利用目的の達成に必要な範囲内において協力企業に提供する場合<br></br>
7. 合併その他の事由による事業の継承に伴って個人情報が提供される場合<br></br>
8. Stripeなどの決済サービスプロバイダーに、決済の処理や支払いのために必要な情報を提供する場合<br></br>
9. その他、個人情報保護法その他の法令で認められる場合</p>


      <h2>第５条：本サービスにおけるお客様情報の保有</h2>
      <p>運営者は、お客様情報を正確かつ最新の内容に保つよう努めます。運営者によるお客様情報の保有は、利用目的（第2条に記載の通り）の達成のために必要な期間、または適用法が許す範囲内で行います。お客様が退会された場合でも、法的義務や事業上の必要性に基づき、最大で10年間お客様の情報を保持する可能性があります。なお、規約違反により強制的に退会となった場合は、特定の法的義務や規約違反に対する措置のため、必要に応じて10年間を超えて情報を保持することがあります。
</p>



      <h2>第６条：子供のプライバシー</h2>
      <p>本サービスは一般的に誰もが閲覧可能なウェブサイトですが、18歳以上の方にサービス利用を制限しています。18歳未満の子供の個人情報を故意に収集することはありません。</p>

      <h2>第7条：個人情報の訂正および削除</h2>
      <p>1. ユーザーは、運営者が保有する自己の個人情報が誤った情報である場合、運営者が定める手続きにより、個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。<br></br>
2. 運営者は、ユーザーから前項の請求を受け、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。<br></br>
3. 運営者は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。</p>

      <h2>第8条：個人情報の利用停止等</h2>
      <p>
1. 運営者は、本人から、個人情報が利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。<br></br>
2. 前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。<br></br>
3. 運営者は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なくこれをユーザーに通知します。<br></br>
4. 前2項にかかわらず、利用停止等に多額の費用を要する場合、または利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置を取ることができる場合は、この代替策を講じるものとします。</p>

      <h2>第9条：プライバシーポリシーの変更</h2>
      <p>本ポリシーの内容は、法令その他本ポリシーに別段の定めがある事項を除き、ユーザーに通知することなく変更することができるものとします。また、運営者が別途定める場合を除き、変更後のプライバシーポリシーは、本ウェブサイトに掲載された時点から効力を生じるものとします。
</p>

      <p>[連絡先情報]<br></br>メールアドレス:info@insei-match.net</p>


    </div>
  );
};

export default PrivacyPolicy;
