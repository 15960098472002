import React, { useState, useCallback } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updateDoc, doc } from 'firebase/firestore';
import Cropper from 'react-easy-crop';
import { storage, db } from '../firebaseConfig';
import { getCroppedImg } from '../utils/cropImage';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const ProfileImageUpload = ({ user, imageUrl, setImageUrl, setProfile }) => {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        alert("ファイルサイズは5MB以下にしてください。");
        return;
      }
      const validMimeTypes = ['image/jpeg', 'image/png'];
      if (!validMimeTypes.includes(file.type)) {
        alert("画像形式はJPEGまたはPNGのみ対応しています。");
        return;
      }
      setImage(URL.createObjectURL(file));
      setShowCropper(true);
    }
  };

  const handleSave = async () => {
    if (user && image) {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      const storageRef = ref(storage, `profile_images/${user.uid}`);
      await uploadBytes(storageRef, croppedImage);
      const photoURL = await getDownloadURL(storageRef);

      const updatedProfile = {
        photoURL,
      };
      
      await updateDoc(doc(db, 'members', user.uid), updatedProfile);
      setImageUrl(photoURL);
      setProfile(prevProfile => ({ ...prevProfile, photoURL }));
      setShowCropper(false);
    }
  };

  const handleDeleteProfileImage = async () => {
    setImageUrl('');
    const updatedProfile = {
      photoURL: ''
    };
    setProfile(prevProfile => ({ ...prevProfile, photoURL: '' }));

    if (user) {
      const docRef = doc(db, 'members', user.uid);
      await updateDoc(docRef, updatedProfile);
    }
  };

  return (
    <div className="profile-picture-container">
        <h4>プロフィール画像を設定しましょう</h4>
      <div className="image-container">
        {imageUrl ? (
          <img src={imageUrl} alt="Profile" className="profile-picture" />
        ) : (
          <img src="/images/default.png" alt="Default" className="profile-picture" />
        )}
        {imageUrl && (
          <button className="delete-icon" onClick={handleDeleteProfileImage}>
            &times;
          </button>
        )}
      </div>
      <br />
      <label className="custom-file-upload">
        プロフィール画像を選択
        <input type="file" onChange={handleImageChange} className="file-input" />
      </label>

      {showCropper && (
        <div className="crop-container">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          <div className="crop-controls">
            <button onClick={() => setShowCropper(false)} className="crop-button1 close1">Close</button>
            <button onClick={handleSave} className="crop-button1 ok">OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileImageUpload;
