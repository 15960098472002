import React from 'react';
import '../styles/CallPopup.css';

const CallPopup = ({ callerName, onAnswer, onReject }) => {
  return (
    <div className="call-popup">
      <div className="call-popup-content">
        <p>{callerName}さんから通話です。</p>
        <button onClick={onAnswer}>電話に出る</button>
        <button onClick={onReject}>拒否</button>
      </div>
    </div>
  );
};

export default CallPopup;
