import React, { useEffect, useState, useCallback } from 'react';
import { db, auth, storage } from '../firebaseConfig';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import MenuBar from '../components/MenuBar';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils/cropImage';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import '../styles/ProfileEdit.css';

// ユーザー入力をサニタイズする関数
const sanitizeInput = (input) => {
  const div = document.createElement('div');
  div.textContent = input;
  return div.innerHTML;
};

// 最大文字数の定義
const MAX_NAME_LENGTH = 7;
const MAX_BIO_LENGTH = 2000;
const MAX_HASHTAG_LENGTH = 20;
const MAX_HASHTAGS = 10;
const MAX_SCHOOL_NAME_LENGTH = 100;

// ファイルサイズの制限（バイト単位）
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const ProfileEdit = () => {
  const [user] = useAuthState(auth);
  const [profile, setProfile] = useState({
    name: '',
    bio: '',
    photoURL: '',
    birthdayYear: '',
    birthdayMonth: '',
    birthdayDay: '',
    schoolName: '',
    affiliation: '', // 所属学会名を追加
    location: '',
    height: '',
    major: '',
    gender: '',
    grade: '',
    sexualOrientation: '',  // 追加
    hashtags: [],
    sub_photoURL_1: '',
    sub_photoURL_2: '',
    sub_photoURL_3: ''
  });
  const [image, setImage] = useState(null);
  const [subImages, setSubImages] = useState([null, null, null]);
  const [subImageUrls, setSubImageUrls] = useState(['', '', '']);
  const [subCroppedAreas, setSubCroppedAreas] = useState([null, null, null]);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [showCropper, setShowCropper] = useState(false);
  const [showSubCropper, setShowSubCropper] = useState([false, false, false]);
  const [newHashtag, setNewHashtag] = useState('');
  const navigate = useNavigate();
  const [currentSubIndex, setCurrentSubIndex] = useState(null);
  const [uploading, setUploading] = useState(false);

  const yearOptions = Array.from({ length: 100 }, (_, i) => ({ value: 2023 - i, label: `${2023 - i}` }));
  const monthOptions = Array.from({ length: 12 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));
  const dayOptions = Array.from({ length: 31 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));

  const ageOptions = Array.from({ length: 100 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));
  const locationOptions = [
    { value: '北海道', label: '北海道' },
    { value: '青森', label: '青森' },
    { value: '岩手', label: '岩手' },
    { value: '宮城', label: '宮城' },
    { value: '秋田', label: '秋田' },
    { value: '山形', label: '山形' },
    { value: '福島', label: '福島' },
    { value: '茨城', label: '茨城' },
    { value: '栃木', label: '栃木' },
    { value: '群馬', label: '群馬' },
    { value: '埼玉', label: '埼玉' },
    { value: '千葉', label: '千葉' },
    { value: '東京', label: '東京' },
    { value: '神奈川', label: '神奈川' },
    { value: '新潟', label: '新潟' },
    { value: '富山', label: '富山' },
    { value: '石川', label: '石川' },
    { value: '福井', label: '福井' },
    { value: '山梨', label: '山梨' },
    { value: '長野', label: '長野' },
    { value: '岐阜', label: '岐阜' },
    { value: '静岡', label: '静岡' },
    { value: '愛知', label: '愛知' },
    { value: '三重', label: '三重' },
    { value: '滋賀', label: '滋賀' },
    { value: '京都', label: '京都' },
    { value: '大阪', label: '大阪' },
    { value: '兵庫', label: '兵庫' },
    { value: '奈良', label: '奈良' },
    { value: '和歌山', label: '和歌山' },
    { value: '鳥取', label: '鳥取' },
    { value: '島根', label: '島根' },
    { value: '岡山', label: '岡山' },
    { value: '広島', label: '広島' },
    { value: '山口', label: '山口' },
    { value: '徳島', label: '徳島' },
    { value: '香川', label: '香川' },
    { value: '愛媛', label: '愛媛' },
    { value: '高知', label: '高知' },
    { value: '福岡', label: '福岡' },
    { value: '佐賀', label: '佐賀' },
    { value: '長崎', label: '長崎' },
    { value: '熊本', label: '熊本' },
    { value: '大分', label: '大分' },
    { value: '宮崎', label: '宮崎' },
    { value: '鹿児島', label: '鹿児島' },
    { value: '沖縄', label: '沖縄' },
    { value: 'その他', label: 'その他' },
  ];
  const heightOptions = Array.from({ length: 70 }, (_, i) => ({ value: i + 140, label: `${i + 140} cm` }));
  const majorOptions = [
    { value: '数学', label: '数学' },
    { value: '物理学', label: '物理学' },
    { value: '化学', label: '化学' },
    { value: '生物', label: '生物' },
    { value: '地球・惑星', label: '地球・惑星' },
    { value: '機械', label: '機械' },
    { value: 'システム制御', label: 'システム制御' },
    { value: '電気・電子', label: '電気・電子' },
    { value: '農学', label: '農学' },
    { value: '材料', label: '材料' },
    { value: '応用化学', label: '応用化学' },
    { value: '薬学', label: '薬学' },
    { value: '医療', label: '医療' },
    { value: '情報工学', label: '情報工学' },
    { value: '知能・情報', label: '知能・情報' },
    { value: '建築', label: '建築' },
    { value: '都市・環境学', label: '都市・環境学' },
    { value: '土木工学', label: '土木工学' },
    { value: '商学', label: '商学' },
    { value: '経済学', label: '経済学' },
    { value: '法学', label: '法学' },
    { value: '社会学', label: '社会学' },
    { value: '文学', label: '文学' },
    { value: '外国語', label: '外国語' },
    { value: '心理学', label: '心理学' },
    { value: '教育', label: '教育' },
    { value: '哲学', label: '哲学' },
    { value: '宗教学', label: '宗教学' },
    { value: '政治学', label: '政治学' },
    { value: '歴史学', label: '歴史学' },
    { value: '芸術学', label: '芸術学' },
    { value: 'デザイン', label: 'デザイン' },
    { value: '音楽学', label: '音楽学' },
    { value: '作曲', label: '作曲' },
    { value: '音楽教育', label: '音楽教育' },
    { value: '声楽', label: '声楽' },
    { value: '器楽', label: '器楽' },
    { value: '音響学', label: '音響学' },
    { value: '演劇学', label: '演劇学' },
    { value: '映像学', label: '映像学' },
    { value: '美術', label: '美術' },
    { value: '舞踊学', label: '舞踊学' },
    { value: '文化人類学', label: '文化人類学' },
    { value: 'メディア研究', label: 'メディア研究' },
    { value: 'その他', label: 'その他' },    
  ];
  const genderOptions = [
    { value: '男性', label: '男性' },
    { value: '女性', label: '女性' },
    { value: 'ノンバイナリー', label: 'ノンバイナリー' },
    { value: 'トランスジェンダー', label: 'トランスジェンダー' },
    { value: 'その他', label: 'その他' },
    { value: '回答しない', label: '回答しない' },    
  ];

  const sexualOrientationOptions = [
    { value: '', label: '回答しない' },
    { value: 'ストレート', label: 'ストレート' },
    { value: '同性愛（ゲイ）', label: '同性愛（ゲイ）' },
    { value: '同性愛（レズビアン）', label: '同性愛（レズビアン）' },
    { value: '両性愛', label: '両性愛' },
    { value: 'パンセクシュアル', label: 'パンセクシュアル' },
    { value: 'アセクシュアル', label: 'アセクシュアル' },
    { value: 'デミセクシュアル', label: 'デミセクシュアル' },
    { value: 'グレイセクシュアル', label: 'グレイセクシュアル' },
    { value: 'クィア', label: 'クィア' },
    { value: 'ポリアモリー', label: 'ポリアモリー' },
    { value: 'その他', label: 'その他' },

  ];
  
  const gradeOptions = [
    { value: '修士課程1年生', label: '修士課程1年生' },
    { value: '修士課程2年生', label: '修士課程2年生' },
    { value: '博士課程1年生', label: '博士課程1年生' },
    { value: '博士課程2年生', label: '博士課程2年生' },
    { value: '博士課程3年生', label: '博士課程3年生' },
    { value: '博士課程4年生', label: '博士課程4年生' },
    { value: '博士課程5年生', label: '博士課程5年生' },
    { value: '大学生', label: '大学生' },
    { value: '社会人', label: '社会人' },
    { value: 'その他', label: 'その他' },
  ];

  useEffect(() => {
    const fetchProfile = async () => {
      if (user) {
        const docRef = doc(db, 'members', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          // 生年月日をISO形式から分解して設定
          const birthday = new Date(data.birthday);
          const birthdayYear = birthday.getFullYear();
          const birthdayMonth = birthday.getMonth() + 1; // 月は0から始まるので+1
          const birthdayDay = birthday.getDate();
  
          setProfile({
            name: sanitizeInput(data.name).slice(0, MAX_NAME_LENGTH) || '',
            bio: sanitizeInput(data.bio).slice(0, MAX_BIO_LENGTH) || '',
            photoURL: data.photoURL || '',
            birthdayYear: birthdayYear || '',
            birthdayMonth: birthdayMonth || '',
            birthdayDay: birthdayDay || '',
            schoolName: sanitizeInput(data.schoolName).slice(0, MAX_SCHOOL_NAME_LENGTH) || '',
            affiliation: sanitizeInput(data.affiliation).slice(0, MAX_SCHOOL_NAME_LENGTH) || '',
            location: data.location || '',
            height: data.height || '',
            major: data.major || '',
            gender: data.gender || '',
            grade: data.grade || '',
            sexualOrientation: data.sexualOrientation || '',  // 追加
            hashtags: data.hashtags ? data.hashtags.map(tag => sanitizeInput(tag).slice(0, MAX_HASHTAG_LENGTH)).slice(0, MAX_HASHTAGS) : [],
            sub_photoURL_1: data.sub_photoURL_1 || '',
            sub_photoURL_2: data.sub_photoURL_2 || '',
            sub_photoURL_3: data.sub_photoURL_3 || ''
          });
          setImageUrl(data.photoURL || '');
          setSubImageUrls([
            data.sub_photoURL_1 || '',
            data.sub_photoURL_2 || '',
            data.sub_photoURL_3 || ''
          ]);
        } else {
          await setDoc(docRef, {
            name: '',
            bio: '',
            photoURL: '',
            birthdayYear: '',
            birthdayMonth: '',
            birthdayDay: '',
            schoolName: '',
            affiliation: '',
            location: '',
            height: '',
            major: '',
            gender: '',
            grade: '',
            sexualOrientation: '',  // 初期値に追加
            hashtags: [],
            sub_photoURL_1: '',
            sub_photoURL_2: '',
            sub_photoURL_3: ''
          });
        }
      }
    };
    fetchProfile();
  }, [user]);
  

  const handleSave = async () => {
    if (user) {
      setUploading(true);
      const docRef = doc(db, 'members', user.uid);
  
      const uploadedImages = await Promise.all(
        subImages.map(async (image, index) => {
          if (image) {
            const croppedImage = await getCroppedImg(image, subCroppedAreas[index]);
            const storageRef = ref(storage, `sub_profile_images/${user.uid}/sub${index + 1}`);
            await uploadBytes(storageRef, croppedImage);
            const downloadURL = await getDownloadURL(storageRef);
            const newSubImageUrls = [...subImageUrls];
            newSubImageUrls[index] = downloadURL;
            setSubImageUrls(newSubImageUrls);
            return downloadURL;
          }
          return profile[`sub_photoURL_${index + 1}`];
        })
      );
  
      // 生年月日をISO形式で保存
      const birthday = new Date(profile.birthdayYear, profile.birthdayMonth - 1, profile.birthdayDay).toISOString();
  
      const updatedProfile = {
        ...profile,
        birthday,
        sub_photoURL_1: uploadedImages[0],
        sub_photoURL_2: uploadedImages[1],
        sub_photoURL_3: uploadedImages[2],
        sexualOrientation: profile.sexualOrientation // 性的嗜好を追加
      };
  
      if (image) {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels);
        const storageRef = ref(storage, `profile_images/${user.uid}`);
        await uploadBytes(storageRef, croppedImage);
        const photoURL = await getDownloadURL(storageRef);
        updatedProfile.photoURL = photoURL;
        setImageUrl(photoURL);
      }
  
      await updateDoc(docRef, updatedProfile);
      setProfile(updatedProfile);
      setUploading(false);
    }
  };
  

  const handleImageChange = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        alert("ファイルサイズは5MB以下にしてください。");
        return;
      }
      const validMimeTypes = ['image/jpeg', 'image/png'];
      if (!validMimeTypes.includes(file.type)) {
        alert("画像形式はJPEGまたはPNGのみ対応しています。");
        return;
      }
      setImage(URL.createObjectURL(file));
      setShowCropper(true);
    }
  };

  const handleSubImageChange = (e, index) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        alert("ファイルサイズは5MB以下にしてください。");
        return;
      }
      const validMimeTypes = ['image/jpeg', 'image/png'];
      if (!validMimeTypes.includes(file.type)) {
        alert("画像形式はJPEGまたはPNGのみ対応しています。");
        return;
      }
      const newSubImages = [...subImages];
      newSubImages[index] = URL.createObjectURL(file);
      setSubImages(newSubImages);
      setCurrentSubIndex(index);
      const newShowSubCropper = [false, false, false];
      newShowSubCropper[index] = true;
      setShowSubCropper(newShowSubCropper);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onSubCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    const newSubCroppedAreas = [...subCroppedAreas];
    newSubCroppedAreas[currentSubIndex] = croppedAreaPixels;
    setSubCroppedAreas(newSubCroppedAreas);
  }, [currentSubIndex, subCroppedAreas]);

  const handleAddHashtag = () => {
    if (newHashtag && profile.hashtags.length < MAX_HASHTAGS) {
      setProfile({ ...profile, hashtags: [...profile.hashtags, sanitizeInput(newHashtag).slice(0, MAX_HASHTAG_LENGTH)] });
      setNewHashtag('');
    }
  };

  const handleRemoveHashtag = (index) => {
    const updatedHashtags = profile.hashtags.filter((_, i) => i !== index);
    setProfile({ ...profile, hashtags: updatedHashtags });
  };

  const handleDeleteImage = async (index) => {
    const newSubImageUrls = [...subImageUrls];
    newSubImageUrls[index] = '';
    setSubImageUrls(newSubImageUrls);

    const newSubImages = [...subImages];
    newSubImages[index] = null;
    setSubImages(newSubImages);

    const updatedProfile = {
      ...profile,
      [`sub_photoURL_${index + 1}`]: ''
    };

    setProfile(updatedProfile);

    if (user) {
      const docRef = doc(db, 'members', user.uid);
      await updateDoc(docRef, updatedProfile);
    }
  };

  const handleDeleteProfileImage = async () => {
    setImageUrl('');
    const updatedProfile = {
      ...profile,
      photoURL: ''
    };
    setProfile(updatedProfile);

    if (user) {
      const docRef = doc(db, 'members', user.uid);
      await updateDoc(docRef, updatedProfile);
    }
  };

  return (
    <div className={`profile-edit-container ${uploading ? 'darkened' : ''}`}>
      {uploading && <div className="overlay">保存中...</div>}
      <div className="header">
        <Link to="/settings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">アカウント設定</p>
      </div>
      <div className="profile-picture-container">
        <div className="image-container">
          {imageUrl ? (
            <img src={imageUrl} alt="Profile" className="profile-picture" />
          ) : (
            <img src="/images/default.png" alt="Default" className="profile-picture" />
          )}
          {imageUrl && (
            <button className="delete-icon" onClick={handleDeleteProfileImage}>
              &times;
            </button>
          )}
        </div>
        <br />
        <label className="custom-file-upload">
          プロフィール画像を選択
          <input type="file" onChange={handleImageChange} className="file-input" />
        </label>
      </div>

      <div className="sub-profile-picture-row">
        {[1, 2, 3].map((index) => (
          <div key={index} className="sub-profile-picture-container">
            <div className="image-container">
              {subImageUrls[index - 1] ? (
                <img src={subImageUrls[index - 1]} alt={`Sub ${index}`} className="sub-image" />
              ) : (
                <img src="/images/default.png" alt="Default" className="sub-image" />
              )}
              {subImageUrls[index - 1] && (
                <button className="delete-icon" onClick={() => handleDeleteImage(index - 1)}>
                  &times;
                </button>
              )}
            </div>
            <label className="custom-file-upload">
              サブ画像{index}を選択
              <input type="file" onChange={(e) => handleSubImageChange(e, index - 1)} className="file-input" />
            </label>
          </div>
        ))}
      </div>
      <label>名前:</label>
      <div className="block-label">
        <input
          type="text"
          value={sanitizeInput(profile.name).slice(0, MAX_NAME_LENGTH)}
          onChange={(e) => setProfile({ ...profile, name: sanitizeInput(e.target.value).slice(0, MAX_NAME_LENGTH) })}
          placeholder="お名前（７文字まで）"
          className="profile-input"
        />
      </div>

      <label>自己紹介:</label>
      <div className="block-label">
        <textarea
          value={sanitizeInput(profile.bio).slice(0, MAX_BIO_LENGTH)}
          onChange={(e) => setProfile({ ...profile, bio: sanitizeInput(e.target.value).slice(0, MAX_BIO_LENGTH) })}
          placeholder="自己紹介(２０００文字まで)"
          className="profile-textarea"
        />
      </div>

      <div className="form-group-a">
        <p>研究または<br></br>趣味タグ:</p>
        {profile.hashtags.length < MAX_HASHTAGS && (
          <div>
            <div className="hashtag-input-container">
              <input
                type="text"
                value={newHashtag}
                onChange={(e) => setNewHashtag(sanitizeInput(e.target.value).slice(0, MAX_HASHTAG_LENGTH))}
                placeholder="#タグを追加(10個まで)"
                className="hashtag-input"
              />
              <button type="button" onClick={handleAddHashtag} className="hashtag-add-button">
                追加
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="hashtag-container">
        {(profile.hashtags || []).map((tag, index) => (
          <div key={index} className="hashtag">
            # {sanitizeInput(tag).slice(0, MAX_HASHTAG_LENGTH)}
            <button type="button" onClick={() => handleRemoveHashtag(index)}>
              &times;
            </button>
          </div>
        ))}
      </div>

      <div className="block-label">
        <label>生年月日:<br></br>※相手には表示されません</label>
        <div className="birthday-container">
          <Select
            value={yearOptions.find(option => option.value === profile.birthdayYear)}
            onChange={(selectedOption) => setProfile({ ...profile, birthdayYear: selectedOption.value })}
            options={yearOptions}
            placeholder="年"
            className="birthday-select"
          />
          <Select
            value={monthOptions.find(option => option.value === profile.birthdayMonth)}
            onChange={(selectedOption) => setProfile({ ...profile, birthdayMonth: selectedOption.value })}
            options={monthOptions}
            placeholder="月"
            className="birthday-select"
          />
          <Select
            value={dayOptions.find(option => option.value === profile.birthdayDay)}
            onChange={(selectedOption) => setProfile({ ...profile, birthdayDay: selectedOption.value })}
            options={dayOptions}
            placeholder="日"
            className="birthday-select"
          />
        </div>
      </div>

      <div className="form-group-a">
        <label>所属大学名<br></br>(任意):</label>
        <input
          type="text"
          value={sanitizeInput(profile.schoolName).slice(0, MAX_SCHOOL_NAME_LENGTH)}
          onChange={(e) => setProfile({ ...profile, schoolName: sanitizeInput(e.target.value).slice(0, MAX_SCHOOL_NAME_LENGTH) })}
          placeholder="学校名"
          className="hashtag-input"
        />
      </div>

      <div className="form-group-a">
        <label>所属学会名<br></br>(任意):</label>
        <input
          type="text"
          value={sanitizeInput(profile.affiliation).slice(0, MAX_SCHOOL_NAME_LENGTH)}
          onChange={(e) => setProfile({ ...profile, affiliation: sanitizeInput(e.target.value).slice(0, MAX_SCHOOL_NAME_LENGTH) })}
          placeholder="学会名"
          className="hashtag-input"
        />
      </div>

      <div className="form-group-a">
        <label>居住地:</label>
        <Select
          value={locationOptions.find(option => option.value === profile.location)}
          onChange={(selectedOption) => setProfile({ ...profile, location: selectedOption.value })}
          options={locationOptions}
          placeholder="未選択"
          className="select-box"
        />
      </div>
      <div className="form-group-a">
        <label>身長(任意):</label>
        <Select
          value={heightOptions.find(option => option.value === profile.height)}
          onChange={(selectedOption) => setProfile({ ...profile, height: selectedOption.value })}
          options={heightOptions}
          placeholder="未選択"
          className="select-box"
        />
      </div>
      <div className="form-group-a">
        <label>学年:</label>
        <Select
          value={gradeOptions.find(option => option.value === profile.grade)}
          onChange={(selectedOption) => setProfile({ ...profile, grade: selectedOption.value })}
          options={gradeOptions}
          placeholder="未選択"
          className="select-box"
        />
      </div>
      <div className="form-group-a">
  <label>性的指向(任意):</label>
  <Select
    value={sexualOrientationOptions.find(option => option.value === profile.sexualOrientation)}
    onChange={(selectedOption) => setProfile({ ...profile, sexualOrientation: selectedOption.value })}
    options={sexualOrientationOptions}
    placeholder="未選択"
    className="select-box"
  />
</div>

      <div className="form-group-a">
        <label>専攻:</label>
        <Select
          value={majorOptions.find(option => option.value === profile.major)}
          onChange={(selectedOption) => setProfile({ ...profile, major: selectedOption.value })}
          options={majorOptions}
          placeholder="未選択"
          className="select-box"
        />
      </div>
      <div className="button-container">
        <button onClick={handleSave} className="save-button">保存</button>
      </div>
      {showCropper && (
        <div className="crop-container">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          <div className="crop-controls">
            <button onClick={() => setShowCropper(false)} className="crop-button1 close1">Close</button>
            <button onClick={handleSave} className="crop-button1 ok">OK</button>
          </div>
        </div>
      )}
      {showSubCropper.map((show, index) => (
        show && (
          <div key={index} className="crop-container">
            <Cropper
              image={subImages[index]}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onSubCropComplete}
            />
            <div className="crop-controls">
              <button onClick={() => {
                const newShowSubCropper = [...showSubCropper];
                newShowSubCropper[index] = false;
                setShowSubCropper(newShowSubCropper);
              }} className="crop-button1 close1">Close</button>
              <button onClick={handleSave} className="crop-button1 ok">OK</button>
            </div>
          </div>
        )
      ))}
      <MenuBar />
    </div>
  );
};

export default ProfileEdit;
