import React, { useState, useEffect, useRef } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, setDoc, doc, query, orderBy } from 'firebase/firestore';
import axios from 'axios';
import Select from 'react-select';
import MenuBar from '../components/MenuBar';
import { Link } from 'react-router-dom'; 
import '../styles/GroupPage.css'; 
import '../styles/RindokuCreate.css'; 
import { getAuth } from 'firebase/auth';  // authをインポート

const GOOGLE_BOOKS_API_KEY = 'AIzaSyAvyBo9w15ekMNQcs5S_ksjFw2bXSenjE0';

// ジャンルリスト
const genres = [
  { value: '数学', label: '数学' },
  { value: '物理学', label: '物理学' },
  { value: '化学', label: '化学' },
  { value: '生物', label: '生物' },
  // 省略...
];

const rindoku_style = [
  { value: 'public', label: '公開' },
  { value: 'private', label: 'プライベート' },
];

const RindokuCreate = () => {
  const [groups, setGroups] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [bookSearchQuery, setBookSearchQuery] = useState(''); // 本検索用のクエリ
  const [memberSearchQuery, setMemberSearchQuery] = useState(''); // メンバー検索用のクエリ
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [selectedRindokuStyle, setSelectedRindokuStyle] = useState(null); // 輪読スタイルの選択
  const [selectedMembers, setSelectedMembers] = useState([]); // 招待されたメンバー
  const [members, setMembers] = useState([]);
  const [showInviteSection, setShowInviteSection] = useState(false);
  const [filters, setFilters] = useState({
    gender: 'all',
    minAge: 18,
    maxAge: 70,
    major: 'all',
    location: 'all',
    sexualOrientation: 'all',
  });
  const [inviteLimitReached, setInviteLimitReached] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const modalRef = useRef(null);

  const auth = getAuth();  // Firebaseのauthから現在のユーザーを取得
  const currentUser = auth.currentUser;  // 現在のログインユーザー


  useEffect(() => {
    const fetchGroups = async () => {
      const groupCollection = collection(db, 'groups');
      const groupSnapshot = await getDocs(groupCollection);
      const groupData = groupSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGroups(groupData);
    };
    fetchGroups();
  }, []);

  // Google Books APIから本を検索
  const handleBookSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://www.googleapis.com/books/v1/volumes?q=${bookSearchQuery}&key=${GOOGLE_BOOKS_API_KEY}`);
      setSearchResults(response.data.items);
    } catch (error) {
      console.error('Error fetching books:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateGroup = async () => {
    if (!selectedBook || !selectedGenre || !selectedRindokuStyle) {
      alert('本、ジャンル、スタイルを選択してください');
      return;
    }
  
    const groupType = selectedRindokuStyle.value;
    const groupDescription = groupType === 'public' ? 'A' : 'B';
  
    try {
      const groupRef = doc(collection(db, 'groups'));
      const groupData = {
        name: selectedBook.volumeInfo.title,
        description: groupDescription,
        type: groupType,
        book: {
          title: selectedBook.volumeInfo.title,
          thumbnail: selectedBook.volumeInfo.imageLinks?.thumbnail || '/images/default-book.png',
          genre: selectedGenre.value,
        },
        createdAt: new Date(),
        imageUrl: selectedBook.volumeInfo.imageLinks?.thumbnail || '/images/default-book.png',
        genre: '輪読・ゼミ',
        invitedMembers: selectedMembers.map(member => member.uid),  // 追加部分: selectedMembersをinvitedMembersに保存
      };
  
      // 公開の場合はparticipantsに自分のuidを追加、プライベートの場合はorganizerに自分のuidを追加
      if (groupType === 'public') {
        groupData.participants = [currentUser.uid];  // 公開の場合participantsにuidを追加
      } else if (groupType === 'private') {
        groupData.organizer = currentUser.uid;  // プライベートの場合organizerにuidを追加
      }
  
      await setDoc(groupRef, groupData);
  
      alert('グループが作成されました');
    } catch (error) {
      console.error('Error creating group:', error);
      alert('グループの作成に失敗しました');
    }
  };
  

  // メンバーフィルター
  const applyFilters = async () => {
    try {
      const membersCollection = collection(db, 'members');
      const q = query(membersCollection, orderBy('use_date', 'desc'));
      const querySnapshot = await getDocs(q);
      let fetchedMembers = querySnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() }));

      const filteredMembers = fetchedMembers.filter(member => {
        const matchesName = memberSearchQuery === '' || (member.name && member.name.toLowerCase().includes(memberSearchQuery.toLowerCase()));
        const matchesGender = filters.gender === 'all' || (member.gender && member.gender === filters.gender);
        const age = new Date().getFullYear() - new Date(member.birthday).getFullYear();
        const matchesAge = age >= filters.minAge && age <= filters.maxAge;
        const matchesMajor = filters.major === 'all' || member.major === filters.major;
        const matchesLocation = filters.location === 'all' || member.location === filters.location;
        const matchesSexualOrientation = filters.sexualOrientation === 'all' || member.sexualOrientation === filters.sexualOrientation;
        return matchesName && matchesGender && matchesAge && matchesMajor && matchesLocation && matchesSexualOrientation;
      });

      setMembers(filteredMembers);
      setHasSearched(true);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  const handleSelectMember = (member) => {
    if (selectedMembers.length >= 10) {
      setInviteLimitReached(true);
      return;
    }
    if (!selectedMembers.some(m => m.uid === member.uid)) {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  const handleRemoveMember = (member) => {
    setSelectedMembers(selectedMembers.filter(m => m.uid !== member.uid));
    setInviteLimitReached(false);
  };

  return (
    <div className="group-page-custom-container">
            <div className="header">
      <Link to="/your-group" className="back-link-cont">←</Link>
        <p className="settings-title-cont">グループを作成</p>
      </div>

      <div className="rindoku-create-book-search">
      <h5>※プライバシー設定でプライベートに設定すると、<br></br>グループ作成者が許可した人のみがグループに参加と閲覧可能です。</h5>
      <div className="group-create-horizontal">
  <h3>1. プライバシー設定：</h3>
        <Select
          options={rindoku_style}
          value={selectedRindokuStyle}
          onChange={setSelectedRindokuStyle}
          placeholder="選択"
          className='group-select-form'
        />
        </div>
        <div className="group-create-horizontal">
        <h3>2.本を選択</h3>
        <input type="text" placeholder="本を検索" value={bookSearchQuery} onChange={(e) => setBookSearchQuery(e.target.value)}   className="group-page-custom-input"/>
        <button onClick={handleBookSearch}>検索</button>
</div>
        {loading && <p>検索中...</p>}
        {!loading && searchResults.length > 0 && (
          <div className="rindoku-create-search-results">
  {searchResults
    .filter(book => book.volumeInfo.imageLinks?.thumbnail) // サムネイルがある本だけをフィルタリング
    .map((book) => (
      <div
        key={book.id}
        className={`book-result ${selectedBook?.id === book.id ? 'selected' : ''}`} // 選択された本に 'selected' クラスを適用
        onClick={() => setSelectedBook(book)}
      >
        <img
          src={book.volumeInfo.imageLinks?.thumbnail}
          alt={book.volumeInfo.title}
        />
        <h3>{book.volumeInfo.title}</h3>
        {selectedBook?.id === book.id && <span className="selected-label">選択</span>} {/* 選択された本にのみ表示 */}
      </div>
  ))}
</div>

        )}
        <div className="group-create-horizontal">
        <h3>2.本のジャンル</h3>
        <Select options={genres} value={selectedGenre} onChange={setSelectedGenre} placeholder="ジャンルを選択" />
      </div>
      
<div className="group-create-horizontal">
        <h3>5. メンバーを招待(任意)：</h3>
        <button type="button" onClick={() => setShowInviteSection(true)} className="group-create-file-upload">
          ＋招待
        </button></div>
        {selectedMembers.length > 0 && (
              <>
                <h4>招待するメンバー:</h4>
                <ul className="group-create-modal-selected-members">
                  {selectedMembers.map(member => (
                    <li key={member.uid} className="group-create-modal-selected-member">
                      {member.name} 
                      <button onClick={() => handleRemoveMember(member)} className="group-create-modal-remove-button">削除</button>
                    </li>
                  ))}
                </ul>
              </>
            )}
        {showInviteSection && (
  <div className="group-create-modal-overlay" onClick={() => setShowInviteSection(false)}>
    <div className="group-create-modal-content" onClick={(e) => e.stopPropagation()} ref={modalRef}>
      <button onClick={() => setShowInviteSection(false)} className="group-create-modal-close-button" >×</button>
      <h4>招待に選択されたユーザー:</h4>
      <ul>
      {selectedMembers.length > 0 && (
              <>
                <h5>招待したメンバー:</h5>
                <ul className="group-create-modal-selected-members">
                  {selectedMembers.map(member => (
                    <li key={member.uid} className="group-create-modal-selected-member">
                      {member.name} 
                      <button onClick={() => handleRemoveMember(member)} className="group-create-modal-remove-button">削除</button>
                    </li>
                  ))}
                </ul>
              </>
            )}
      </ul>
      <h3>メンバーを招待</h3>
  

      {/* フィルターセクション */}
      <div className="group-create-modal-filters">
        {/* 性別フィルタ */}
        <div className="group-create-modal-form-group">
        <label>名前:</label>
        <input type="text" placeholder="名前で検索" value={memberSearchQuery} onChange={(e) => setMemberSearchQuery(e.target.value)} className="group-create-modal-input"/>
          <label>性別:</label>
          <select
            name="gender"
            value={filters.gender}
            onChange={(e) => setFilters({ ...filters, gender: e.target.value })}
            className="group-create-modal-select"
          >
            <option value="all">すべて</option>
            <option value="男性">男性</option>
            <option value="女性">女性</option>
            <option value="ノンバイナリー">ノンバイナリー</option>
            <option value="トランスジェンダー">トランスジェンダー</option>
            <option value="その他">その他</option>
          </select>
        </div>

        {/* 年齢フィルタ */}
        <div className="group-create-modal-form-group">
          <label>年齢:</label>
          <div className="group-create-modal-age-group">
            <input
              type="number"
              placeholder="最小年齢"
              value={filters.minAge}
              onChange={(e) => setFilters({ ...filters, minAge: e.target.value })}
              className="group-create-modal-select"
            />
            <span>から</span>
            <input
              type="number"
              placeholder="最大年齢"
              value={filters.maxAge}
              onChange={(e) => setFilters({ ...filters, maxAge: e.target.value })}
              className="group-create-modal-select"
            />
          </div>
        </div>

        {/* 専攻フィルタ */}
        <div className="group-create-modal-form-group">
          <label>専攻:</label>
          <select
            name="major"
            value={filters.major}
            onChange={(e) => setFilters({ ...filters, major: e.target.value })}
            className="group-create-modal-select"
          >
            <option value="all">すべて</option>
            <option value="数学">数学</option>
            <option value="物理学">物理学</option>
            <option value="化学">化学</option>
            {/* 他の専攻も追加... */}
          </select>
        </div>

        {/* 居住地フィルタ */}
        <div className="group-create-modal-form-group">
          <label>居住地:</label>
          <select
            name="location"
            value={filters.location}
            onChange={(e) => setFilters({ ...filters, location: e.target.value })}
            className="group-create-modal-select"
          >
            <option value="all">すべて</option>
            <option value="東京">東京</option>
            <option value="大阪">大阪</option>
            {/* 他の居住地も追加... */}
          </select>
        </div>
      </div>

      <button onClick={applyFilters} className="group-create-modal-apply-filters-button">メンバーを検索</button>

      {hasSearched && (
              <div className="group-create-modal-member-list">
                {members.length > 0 ? (
                  members.map(member => (
                    <div key={member.uid} className="group-create-modal-member-card">
                      <img src={member.photoURL || '/images/default.png'} alt={member.name} className="group-create-modal-member-avatar" />
                      <div className="group-create-modal-member-details">
                        <p>{member.name}（{member.location}・{member.major}）</p>
                        <button 
                          onClick={() => handleSelectMember(member)} 
                          disabled={selectedMembers.some(m => m.uid === member.uid) || inviteLimitReached}
                          className="group-create-modal-invite-button"
                        >
                          招待する
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>該当するメンバーが見つかりません。</p>
                )}<br></br><br></br><br></br>
              </div>
            )}
    </div>
  </div>
)}




      </div>
      <div className="button-container">
      <button onClick={handleCreateGroup} className="save-button">作成</button>
      </div>
      <MenuBar />
    </div>
  );
};

export default RindokuCreate;
